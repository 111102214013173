import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IoMdClose } from 'react-icons/io';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { FormValidator } from '@syncfusion/ej2-react-inputs';
import { APPLICATIONS, ENVIRONMENTS } from '../../../Constants/constants';
import {
  CONTEXT_ADD_URL,
  CONTEXT_ADD_MANY_URL,
} from '../../../GraphQL/ManageUrls/TRFX/Mutations';
import Papa from 'papaparse';
import { isEmpty, isString } from '../../../Utils/helpers';
import '../../TestResults/TestResults.css';
import { isAuthRol } from '../../../Utils/helpers';

let formObject;
function AddUrlForm() {
  const [env, setEnvironment] = useState('dev');
  const [application, setApplication] = useState('TRFX');
  const [client, setClient] = useState('');
  const [url, setUrl] = useState('');
  const [response, setResponse] = useState({});
  const [failedUrl, setFailedUrl] = useState('');
  const [successUrl, setSuccessUrl] = useState('');
  const [divHeight, setDivHeight] = useState('0px');
  const [enabledLoading, setEnabledLoading] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);

  let user = sessionStorage.getItem('user');

  try {
    user = isString(user) ? JSON.parse(user) : user;
  } catch (e) {
    console.error('Saved user data is invalid', e);
    user = { _id: '61097bfa97ff9f0009fe3954' };
  }
  const role = isAuthRol(user.roles);

  //State to store the values
  const [values, setValues] = useState([]);

  useEffect(() => {
    let rules = {
      client: {
        required: [true, '*Please enter a client'],
        regex: ['^[a-zA-Z0-9-_/]+$', '*Please match the requested format'],
      },
      env: {
        required: [true, '*Please select an environment'],
      },
      app: {
        required: [true, '*Please select an application'],
      },
      url: {
        required: [true, '*Please enter an url'],
        regex: [
          '(?:https?):\\/\\/(\\w+:?\\w*)?(\\S+)(:\\d+)?(\\/|\\/([\\w#!:.?+=&%!\\-\\/]))?',
          '*Please match the requested format',
        ],
      },
    };
    const options = {
      rules: rules,
    };
    formObject = new FormValidator('#addUrlForm', options);
    if (!isEmpty(response)) {
      setDivHeight('200px');
      setEnabledLoading(false);
      setDisableAdd(false);
    }
  }, [response]);
  const [runAddManyUrlsMutation] = useMutation(CONTEXT_ADD_MANY_URL, {
    onCompleted: (data) => {
      const response = data.contextAddManyUrlsToContext;
      setResponse(response);
      // Reset state
      window.hideMessage();
      setClient('');
      setUrl('');
      setValues([]);
      window.flash(
        `The action is in progress, this could take few minutes. Please check the tree of URLs later. In progress urls: ${response.success}`,
        'success'
      );
    },
    onError: (error) => {
      window.hideMessage();
      window.flash(
        `The action could not be completed. Please review carefully the information provided or try later.`,
        'error'
      );
    },
  });
  const [runMutation] = useMutation(CONTEXT_ADD_URL, {
    onCompleted: () => {
      // Reset state
      window.hideMessage();
      setClient('');
      setUrl('');
      window.flash('Url was added successfully.', 'success');
    },
    onError: (error) => {
      window.hideMessage();
      window.flash(
        `The action could not be completed. Please review carefully the information provided or try later.`,
        'error'
      );
    },
  });

  const onSubmit = (event) => {
    event.preventDefault();
    // Form validation
    if (!isEmpty(values)) {
      setEnabledLoading(true);
      setDisableAdd(true);
      setFailedUrl('');
      setSuccessUrl('');
      runAddManyUrlsMutation({
        variables: {
          env,
          client,
          application,
          urls: values,
          user: user._id,
        },
      });
      return;
    }
    if (!formObject.validate()) {
      window.hideMessage();
      return;
    }
    window.showMessage('Loading', 'loading');
    formObject.element.reset();

    runMutation({
      variables: {
        env,
        client,
        url,
        application,
      },
    });
  };

  const handleCsvFileChange = (event) => {
    Papa.parse(event.target.files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          valuesArray.push(Object.values(d).toString());
        });
        // Filtered Values
        setValues(valuesArray);
      },
    });
  };

  return (
    <div className='d-flex justify-content-center'>
      <div className='e-card' style={{ width: '75%' }}>
        <div className='ml-auto'>
          <a
            href='/trfx-run-tests'
            style={{ textDecoration: 'none', color: 'gray' }}
          >
            <IoMdClose size='20' className='mt-2 mr-2' />
          </a>
        </div>
        <div className='e-card-header mt-2' style={{ width: '100%' }}>
          <div className='e-card-header-caption'>
            <div className='e-card-header-title  d-flex justify-content-center'>
              <h5>Add url to context</h5>
            </div>
          </div>
        </div>
        <div className='e-card-content'>
          <form
            noValidate
            id='addUrlForm'
            onSubmit={(event) => onSubmit(event)}
          >
            <div className='d-flex' style={{ columnGap: '18px' }}>
              <div className='form-group'>
                <div className='e-float-input'>
                  <DropDownListComponent
                    name='env'
                    placeholder='Select environment'
                    value={env}
                    dataSource={ENVIRONMENTS}
                    fields={{ text: 'text', value: 'value' }}
                    onChange={(event) => setEnvironment(event.target.value)}
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='e-float-input'>
                  <DropDownListComponent
                    value={application}
                    dataSource={APPLICATIONS}
                    onChange={(event) => setApplication(event.target.value)}
                    placeholder='Select applications'
                    name='app'
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='e-float-input'>
                  <input
                    type='text'
                    placeholder='Client'
                    value={client}
                    onChange={(event) => {
                      setClient(event.target.value);
                      formObject.validate('client');
                    }}
                    required
                    name='client'
                  />
                </div>
              </div>
            </div>

            <div className='form-group'>
              <div className='e-float-input'>
                <input
                  type='text'
                  placeholder='Url'
                  value={decodeURIComponent(url)}
                  onChange={(event) => {
                    setUrl(event.target.value);
                    if (isEmpty(values)) {
                      formObject.validate('url');
                    }
                  }}
                  required
                  name='url'
                />
              </div>
            </div>

            <div>
              <label htmlFor='csvInput' style={{ display: 'block' }}>
                Enter CSV File
              </label>
              <input
                onChange={handleCsvFileChange}
                id='csvInput'
                name='file'
                type='File'
                accept='text/csv'
                disabled={role === 'user'}
              />
            </div>

            <div className='d-flex justify-content-center mt-5'>
              <ButtonComponent
                onClick={() => (window.location.href = '/trfx-run-tests')}
                className='e-control e-btn e-lib e-outline mr-1'
                iconCss='e-icons e-close'
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                type='submit'
                className='e-control e-btn e-lib e-custom btn-primary'
                iconCss='e-icons e-check'
                disabled={disableAdd}
              >
                Accept
              </ButtonComponent>
            </div>
            {/*<div className='row mt-5'>*/}
            {/*  {enabledLoading && (*/}
            {/*    <div className='row'>*/}
            {/*      <div className='in-progress-te-message col-md-12 col-sm-12'>*/}
            {/*        Load urls in progress...*/}
            {/*      </div>*/}
            {/*      <TestResultsSpinner />*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*  <div className='col-md-6 col-sm-12' style={{ height: divHeight, overflow: "auto" }}>*/}
            {/*    {failedUrl && (*/}
            {/*        <h6>*/}
            {/*          <span>Failed Urls</span><br/>*/}
            {/*          <span>{failedUrl}</span>*/}
            {/*        </h6>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*  <div className='col-md-6 col-sm-12' style={{ height: divHeight, overflow: "auto" }}>*/}
            {/*    {successUrl && (*/}
            {/*        <h6>*/}
            {/*          <span>Success Urls</span><br/>*/}
            {/*          <span>{successUrl}</span>*/}
            {/*        </h6>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddUrlForm;
