import React from 'react';
import { get, isEmpty, isFunction } from '../../Utils/helpers';
import { TestResultsAccordionHeader } from './TestResultsAccordionHeader';
import { CopyToClipboard } from '../UI/CopyToClipboard/CopyToClipboard';

const customOperationsByType = {
  UrlTestSuite: {
    getTitle: (tr) => {
      const app = get(tr, 'context.application');
      const client = get(tr, 'context.client');
      const t = get(tr, 'context.template');
      const se = get(tr, 'context.siteEdition');
      const url = get(tr, 'url');
      return [`(${app || ''}) ${client}`, t, se, url].join(' > ');
    },
    getExtraActions: (tr) => (
      <div className='d-flex' style={{ padding: '0' }}>
        <CopyToClipboard text={tr.name} />
        <span
          className='e-icons e-open-link mt-2'
          onClick={(e) => window.open(tr.name, '_blank', 'noopener,noreferrer')}
        />
      </div>
    ),
    getIT: (tr) => get(tr, 'it', get(tr, 'flattenedResults')),
    getStatus: (tr) => get(tr, 'status', get(tr, 'testResults.status')),
    getTypeText: () => 'Urls',
    getTransformedData: (tr) => {
      const f = {
        ...tr,
        it: tr.flattenedResults,
        name: tr.url,
      };

      delete f.flattenedResults;

      return f;
    },
    getExtraData: (tr) => ({
      context: tr.context,
      url: tr.url,
    }),
    getName: (tr) => get(tr, 'url'),
    getContentMessage: (tr) =>
      get(tr, 'flattenedResults.extraInfo.error.message'),
  },
  aTCFeaturesSuite: {
    getTitle: (tr) => {
      const app = get(tr, 'feature.application');
      const name = get(tr, 'feature.name');
      const menuPath = get(tr, 'feature.menuPath');
      return [`(${app || ''}) ${menuPath}`, name].join(' > ');
    },
    getExtraActions: (tr) => null,
    getIT: (tr) => {
      const a = get(get(tr, 'results'), 'it');
      return a;
    },
    getStatus: (tr) => get(tr, 'status', get(tr, 'results.status')),
    getApplication: (tr) => get(tr, 'feature.application'),
    getTypeText: () => 'Features',
    getTransformedData: (tr) => {
      const f = {
        ...tr,
        it: tr.flattenedResults,
        name: tr.url,
      };

      delete f.flattenedResults;

      return f;
    },
    getExtraData: (tr) => ({
      context: tr.context,
      url: tr.url,
    }),
    getName: (tr) => get(tr, 'url'),
    getContentMessage: (tr) =>
      get(tr, 'flattenedResults.extraInfo.error.message'),
  },
  WCTestSuite: {
    getTypeText: () => 'CMP',
  },
  TestCase: {
    getTypeText: () => 'TC',
  },
  TestCaseStep: {
    getTypeText: () => 'Steps',
  },
  default: {
    getTitle: (tr) => tr.name,
    getContentMessage: (tr) => get(tr, 'extraInfo.error.message'),
    getField: (tr, field) => get(tr, field),
    getExtraActions: (tr) => [],
    getIT: (tr) => get(tr, 'it'),
    getStatus: (tr) => get(tr, 'status'),
    getTransformedData: (tr) => tr,
    getName: (tr) => get(tr, 'name'),
    getExtraData: (tr) => ({}),
  },
};

export const getData = (currentTestResult, fn, pType) => {
  const customFn = get(
    customOperationsByType,
    `${pType}.${fn}`,
    get(customOperationsByType, `default.${fn}`)
  );
  if (isFunction(customFn)) {
    return customFn(currentTestResult);
  }
};

function transformTestResultsForTreeView(
  tr,
  trIndex = 0,
  parentsIds = [],
  typeName = 'UrlTestSuite'
) {
  const type = get(tr, 'type', typeName);
  const it = getData(tr, 'getIT', type);
  const transformed = {
    status: getData(tr, 'getStatus', type),
    text: getData(tr, 'getTitle', type),
    type,
    name: getData(tr, 'getName', type),
    screenshots: get(tr, 'screenshots'),
    extraFiles: get(tr, 'extraFiles'),
    extraInfo: get(tr, 'extraInfo'),
    extraActions: getData(tr, 'getExtraActions', type),
    application: getData(tr, 'getApplication', type),
    ...getData(tr, 'getExtraData', type),
  };
  const transformedIT = (it || []).map((i, index) =>
    transformTestResultsForTreeView(
      i,
      index,
      [...parentsIds, trIndex],
      typeName
    )
  );
  const idSeparator = '|';
  const id = [...parentsIds, trIndex].join(idSeparator);

  const d = {
    id,
    ...transformed,
  };

  if (isEmpty(transformedIT)) {
    const contentMessage = getData(tr, 'getContentMessage', type);
    if (isEmpty(contentMessage)) {
      d.isLeaf = true;
      d.needsHeader = true;
    } else {
      d.it = [
        {
          id: [id, 0].join(idSeparator),
          text: contentMessage,
          isLeaf: true,
          needsHeader: false,
        },
      ];
    }
  } else {
    d.it = transformedIT;
  }

  return d;
}

export function getTestResultsForTreeView(
  testResults = [],
  trIndex = 0,
  typeName = 'UrlTestSuite'
) {
  return testResults.map((tr, index) =>
    transformTestResultsForTreeView(tr, index + trIndex, [], typeName)
  );
}

export function getElementForTreeNode({
  currentTestResult,
  openScreenshotModal,
  closeScreenshotModal,
}) {
  const {
    it = [],
    screenshots = [],
    extraFiles = [],
    status,
    text,
    type,
    isLeaf = false,
    needsHeader = true,
  } = currentTestResult || {};
  return isLeaf && !needsHeader ? (
    !isEmpty(text) && <div className='content-message'>{text}</div>
  ) : (
    <TestResultsAccordionHeader
      screenshots={screenshots}
      name={text}
      it={it}
      extraActions={getData(currentTestResult, 'getExtraActions', type)}
      extraFiles={extraFiles}
      status={status}
      type={getData(currentTestResult, 'getTypeText', get(it, '0.type'))}
      openScreenshotModal={openScreenshotModal}
      closeScreenshotModal={closeScreenshotModal}
      url={getData(currentTestResult, 'getUrl', type)}
    />
  );
}
