import React from 'react';
import ScreenshotsAndExtraFiles from '../UI/ScreenshotsAndExtraFiles/ScreenshotsAndExtraFiles';
import { getTests } from '../../Utils/TestResultsMethods';
import PassedAndFailed from '../UI/PassedAndFailed';
import { isEmpty } from '../../Utils/helpers';
import './TestResultsAccordionHeader.css';

export function TestResultsAccordionHeader({
  name = '',
  status = 'PASSED',
  screenshots = [],
  extraFiles = [],
  type = '',
  it = [],
  onScreenshotsClick,
  extraActions = [],
  openScreenshotModal,
  closeScreenshotModal,
}) {
  let decodedName;
  try {
    decodedName = decodeURIComponent(name);
  } catch (e) {
    decodedName = name;
  }
  return (
    <div
      className={[
        status,
        'inline-flex-container',
        'test-result-header-container',
      ].join(' ')}
    >
      <span
        className='test-results-header-text'
        name='test-results-header-text'
        lang='ar'
      >
        {decodedName}
      </span>
      {!isEmpty(extraActions) && (
        <div className='inline-flex-container extra-actions-container'>
          {extraActions}
        </div>
      )}
      {(!isEmpty(screenshots) || !isEmpty(extraFiles)) && (
        <ScreenshotsAndExtraFiles
          screenshots={screenshots}
          extraFiles={extraFiles}
          onClickScreenshots={openScreenshotModal}
        />
      )}
      {!isEmpty(it) && (
        <PassedAndFailed
          title={type}
          passed={getTests('PASSED', it)}
          failed={getTests('FAILED', it)}
        />
      )}
    </div>
  );
}
