function getMenuItemsRole(role = 'user') {
  return [
    {
      text: 'TRFX',
      items: [
        {
          text: 'Run Test',
          iconCss: 'e-icons e-play',
          url: '/trfx-run-tests',
        },
        {
          text: 'Test Executions',
          iconCss: 'e-icons e-data-validation',
          url: '/trfx-test-executions',
        },
        {
          text: 'All Executions',
          iconCss: 'e-icons e-description',
          url: '/trfx-all-executions',
        },
        // { separator: true },
        {
          text: 'Manage Urls',
          iconCss: 'e-icons e-link-2',
          items: [
            {
              text: 'Add Url',
              iconCss: 'e-icons e-hyperlink-open',
              url: '/add-url',
            },
            {
              text: 'Edit Url',
              iconCss: 'e-icons e-hyperlink-edit',
              url: '/edit-url',
            },
            {
              text: 'Delete Url',
              iconCss: 'e-icons e-hyperlink-remove',
              url: '/delete-url',
            },
          ],
        },
        role !== 'user' && {
          text: 'Manage Scopes',
          iconCss: 'e-icons e-named-set',
          items: [
            {
              text: 'View Custom Scope',
              url: '/view-custom-scopes',
            },
            {
              text: 'Associate Customer',
              url: '/associate-component',
            },
          ],
        },
      ],
    },
    // {
    //   text: 'aTC',
    //   items: [
    //     {
    //       text: 'Run Test',
    //       iconCss: 'e-icons e-play',
    //       url: '/atc-run-tests',
    //     },
    //     {
    //       text: 'Test Executions',
    //       iconCss: 'e-icons e-data-validation',
    //       url: '/atc-test-executions',
    //     },
    //     {
    //       text: 'Manage Features',
    //       iconCss: 'e-icons e-link-2',
    //       items: [
    //         {
    //           text: 'Add Feature',
    //           iconCss: 'e-icons e-hyperlink-open',
    //           url: '/add-feature',
    //         },
    //         {
    //           text: 'Delete Feature',
    //           iconCss: 'e-icons e-hyperlink-remove',
    //           url: '/delete-feature',
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];
}

export default getMenuItemsRole;
