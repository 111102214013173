import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import ContextsTreeFilterDataHandler from './TreeFilterDataHandler';
import { get, isEmpty } from '../../../Utils/helpers';
import TestResultsFilterDataHandler from './TestResultsFilterDataHandler';
import { cmpNamesSeparator } from '../../../Utils/treeProcessing';
import TreeFilterDataHandler from './TreeFilterDataHandler';

export default class FilterDataHandlerFactory {
  static create({ dsProviderId, field, originalDS, currentDS }) {
    // get some function given dsProviderId and the relatedField
    switch (dsProviderId) {
      case 'Features': {
        const fieldMapping = {
          application: 'application',
          name: 'name',
          menuPath: 'menuPath',
        };
        return new TreeFilterDataHandler({
          field,
          getDSFromExternalDS(ds) {
            const f = fieldMapping[this.field];
            const values = new DataManager(ds).executeLocal(
              new Query().select(f)
            );
            return Array.from(
              new Set(values.map((v) => v && v[f]).filter(Boolean))
            );
          },
          getPredicate(values) {
            if (isEmpty(values)) {
              return undefined;
            }
            const f = fieldMapping[this.field];

            const predicate = (Array.isArray(values) ? values : [values])
              .filter(Boolean)
              .reduce((acc, v) => {
                return acc
                  ? acc.or(f, 'equal', v)
                  : new Predicate(f, 'equal', v);
              }, undefined);

            return predicate.and('il', 'equal', true);
          },
          originalDS,
          currentDS,
        });
      }
      case 'Contexts': {
        const fieldMapping = {
          application: 'a',
          client: 'c',
          template: 't',
          siteEdition: 'se',
          url: 'url',
          components: 'cmps',
        };
        switch (field) {
          case 'components': {
            return new TreeFilterDataHandler({
              field,
              getDSFromExternalDS(ds, extraData) {
                const f = fieldMapping[this.field];
                const values = new DataManager(ds).executeLocal(
                  new Query().select(f)
                );

                const { toInclude } = extraData || {};

                return Array.from(
                  new Set(
                    values
                      .map((v) => v[f])
                      .reduce((acc, v) => {
                        return [
                          ...acc,
                          ...(v || []).map((vv) => vv && vv.name),
                        ];
                      }, [])
                      .filter(
                        (vv) =>
                          !Array.isArray(toInclude) ||
                          isEmpty(toInclude) ||
                          (toInclude &&
                            toInclude.find((e) => {
                              return e.toString().endsWith('*')
                                ? new RegExp(
                                    e
                                      .toString()
                                      .substring(0, e.toString().length - 1),
                                    'ig'
                                  ).test(vv)
                                : vv === e;
                            }))
                      )
                      .filter(Boolean)
                  )
                );
              },
              getPredicate(values) {
                if (isEmpty(values)) {
                  return undefined;
                }
                const f = 'cNames';

                const predicate = (Array.isArray(values) ? values : [values])
                  .filter(Boolean)
                  .reduce((acc, v) => {
                    const vWithSeparator = `${cmpNamesSeparator}${v}${cmpNamesSeparator}`;
                    return acc
                      ? acc.or(f, 'contains', vWithSeparator)
                      : new Predicate(f, 'contains', vWithSeparator);
                  }, undefined);

                return predicate.and('il', 'equal', true);
              },
              originalDS,
              currentDS,
            });
          }
          case 'url': {
            return new TreeFilterDataHandler({
              field,
              getDSFromExternalDS(ds) {
                const f = fieldMapping[this.field];
                const values = new DataManager(ds).executeLocal(
                  new Query().select(f)
                );
                return Array.from(
                  new Set(values.map((v) => v && v[f]).filter(Boolean))
                );
              },
              getPredicate(values) {
                if (isEmpty(values)) {
                  return undefined;
                }
                const f = fieldMapping[this.field];
                const predicate = (Array.isArray(values) ? values : [values])
                  .filter(Boolean)
                  .reduce((acc, v) => {
                    return acc
                      ? acc.or(f, 'contains', v)
                      : new Predicate(f, 'contains', v);
                  }, undefined);

                return predicate.and('il', 'equal', true);
              },
              originalDS,
              currentDS,
            });
          }
          default: {
            return new ContextsTreeFilterDataHandler({
              field,
              getDSFromExternalDS(ds) {
                const f = fieldMapping[this.field];
                const values = new DataManager(ds).executeLocal(
                  new Query().select(f)
                );
                return Array.from(
                  new Set(values.map((v) => v && v[f]).filter(Boolean))
                );
              },
              getPredicate(values) {
                if (isEmpty(values)) {
                  return undefined;
                }
                const f = fieldMapping[this.field];

                const predicate = (Array.isArray(values) ? values : [values])
                  .filter(Boolean)
                  .reduce((acc, v) => {
                    return acc
                      ? acc.or(f, 'equal', v)
                      : new Predicate(f, 'equal', v);
                  }, undefined);

                return predicate.and('il', 'equal', true);
              },
              originalDS,
              currentDS,
            });
          }
        }
      }
      case 'aTCTestResults': {
        return new TestResultsFilterDataHandler({
          field,
          getDSFromExternalDS(ds) {
            const values = new DataManager(ds).executeLocal(
              new Query().select(this.field)
            );
            const fv = values
              .map((v) =>
                // this.field.toString().indexOf(".") !== -1
                //   ? get(v, this.field)
                //   : v
                get(v, this.field)
              )
              .filter(Boolean);
            return Array.from(new Set(fv));
          },
          filterFn({ values, current }) {
            if (isEmpty(values)) {
              return undefined;
            }
            const f = this.field;
            return (values || []).includes(get(current, f));
          },
          originalDS,
          currentDS,
        });
      }
      default:
      case 'TestResults': {
        switch (field) {
          case 'components': {
            return new TestResultsFilterDataHandler({
              field,
              getDSFromExternalDS(ds, extraData) {
                const values = ds.reduce((acc, o) => {
                  return [...acc, ...get(o, 'it', []).map((r) => r.name)];
                }, []);

                return Array.from(new Set(values.filter(Boolean)));
              },
              customFilterDS: (ds, values) => {
                const results = (ds || []).reduce((acc, urlResults) => {
                  const components = get(urlResults, 'it', []).filter((c) =>
                    (values || []).includes(c.name)
                  );

                  if (!isEmpty(components)) {
                    acc.push({
                      ...urlResults,
                      flattenedResults: components,
                    });
                  }

                  return acc;
                }, []);

                return results;
              },
              originalDS,
              currentDS,
            });
          }
          case 'tracking': {
            const checkboxHandler = new TestResultsFilterDataHandler({
              field,
              getDSFromExternalDS(ds, extraData) {
                return [false];
              },
              customFilterDS: (ds, values) => {
                let results = originalDS;
                if (!isEmpty(values) && values[0] === true) {
                  results = (ds || []).reduce((acc, urlResults) => {
                    let res = Object.fromEntries(
                      Object.entries(urlResults).filter((e) => e[0] !== 'it')
                    );
                    const currentTestSuiteList = get(urlResults, 'it', []);
                    let filteredTestCases = currentTestSuiteList.reduce(
                      (acc2, tTestSuite) => {
                        let res2 = Object.fromEntries(
                          Object.entries(tTestSuite).filter(
                            (e) => e[0] !== 'it'
                          )
                        );
                        const testCases = get(tTestSuite, 'it', []).reduce(
                          (acc3, step) => {
                            const name = get(
                              step,
                              'name',
                              get(step, 'text', '')
                            );
                            if (name.includes('TRACKING')) {
                              acc3.push(step);
                            }
                            return acc3;
                          },
                          []
                        );
                        if (!isEmpty(testCases)) {
                          res2.it = testCases;
                          acc2.push(res2);
                        }
                        return acc2;
                      },
                      []
                    );
                    res.it = filteredTestCases;
                    acc.push(res);
                    return acc;
                  }, []);
                }

                return results;
              },
              originalDS,
              currentDS,
            });
            return checkboxHandler;
          }
          default: {
            return new TestResultsFilterDataHandler({
              field,
              getDSFromExternalDS(ds) {
                const values = new DataManager(ds).executeLocal(
                  new Query().select(this.field)
                );
                const fv = values
                  .map((v) =>
                    // this.field.toString().indexOf(".") !== -1
                    //   ? get(v, this.field)
                    //   : v
                    get(v, this.field)
                  )
                  .filter(Boolean);
                return Array.from(new Set(fv));
              },
              filterFn({ values, current }) {
                if (isEmpty(values)) {
                  return undefined;
                }
                const f = this.field;
                return (values || []).includes(get(current, f));
              },
              originalDS,
              currentDS,
            });
          }
        }
      }
    }
  }
}
