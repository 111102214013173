import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { useContext } from 'react';
import './App.css';
import ErrorPage from './Components/Error/Error';
import TRFXTestResults from './Components/TestResults/TRFX/DataCollector';

import TRFXRunTests from './Components/RunTests/TRFX/DataCollector';

// import ATCRunTests from './Components/RunTests/aTC/DataCollector';
// import ATCTestExecutions from './Components/TestExecutions/aTC/DataCollector';

import AddUrlForm from './Components/Manage/TRFX/AddUrl';
import DeleteUrl from './Components/Manage/TRFX/DeleteUrl';
// import AddFeature from './Components/Manage/aTC/AddFeature';
// import DeleteFeature from './Components/Manage/aTC/DeleteFeature';
import Bus from './Utils/Bus';
import Flash from './Components/UI/FlashMessage/Flash';
import Message from './Components/UI/Message/Message';
// import ATCTestResults from './Components/TestResults/aTC/DataCollector';
import ViewCustomScopes from './Components/CustomScopes/ViewCustomScopes';
import ScheduleForm from './Components/UI/ScheduleForm/Schedule';
import ClientComponentForm from './Components/UI/ClientComponentForm/Form';
import ProcessingQueue from './Components/CustomScopes/ProcessingQueue/ProcessingQueue';
import EditUrlForm from './Components/Manage/TRFX/EditUrl';
import Login from './Components/Home/Home';
import { AppContext } from './Components/UI/contexts';
import { isAuthRol } from './Utils/helpers';
import TRFXTestExecutionsPage from './Components/TestExecutions/TRFX/TRFXTestExecutionsPage';
import ChildExecutions from './Components/TestExecutions/TRFX/ChildExecutions';
import AllExecutions from './Components/TestExecutions/TRFX/AllExecutions';

function App() {
  window.flash = (message, type = 'success') =>
    Bus.emit('flash', { message, type });
  window.showMessage = (message, type = 'success') =>
    Bus.emit('show', { message, type });
  window.hideMessage = () => Bus.emit('hide');
  window.hideBreadcrumb = () => {
    const breadcrumb = document.getElementById('att-breadcrumb');
    if (breadcrumb) breadcrumb.style.display = 'none';
  };

  window.showBreadcrumb = () => {
    const breadcrumb = document.getElementById('att-breadcrumb');
    if (breadcrumb) breadcrumb.style.display = 'inherit';
  };

  const { user } = useContext(AppContext);
  const { roles } = user || {};
  const role = isAuthRol(roles);

  return (
    <div className='App'>
      <Flash />
      <Message />
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route path='/trfx-run-tests' element={<TRFXRunTests />} />
        {/* <Route path='/atc-run-tests' element={<ATCRunTests />} /> */}
        <Route path='/add-url' element={<AddUrlForm />} />
        <Route path='/edit-url' element={<EditUrlForm />} />
        <Route path='/delete-url' element={<DeleteUrl />} />
        {/* <Route path='/add-feature' element={<AddFeature />} /> */}
        {/* <Route path='/delete-feature' element={<DeleteFeature />} /> */}
        <Route
          path='/trfx-show-test-results/:id'
          element={<TRFXTestResults />}
        />
        {/* <Route path='/atc-show-test-results/:id' element={<ATCTestResults />} /> */}
        <Route
          path='/trfx-test-executions'
          element={<TRFXTestExecutionsPage />}
        />
        <Route path='/trfx-all-executions' element={<AllExecutions />} />
        <Route
          path='/trfx-child-executions/:testExecutionId'
          element={<ChildExecutions />}
        />
        <Route
          path='/trfx-run-tests-edit/:testExecutionId'
          element={<TRFXRunTests />}
        />
        <Route
          path='/trfx-run-tests-duplicate/:testExecutionId'
          element={<TRFXRunTests />}
        />
        {/* <Route
          path='/atc-run-tests-edit/:testExecutionId'
          element={<ATCRunTests />}
        /> */}
        {/* <Route path='/atc-test-executions' element={<ATCTestExecutions />} /> */}
        {role !== 'user' && (
          <Route path='/view-custom-scopes' element={<ViewCustomScopes />} />
        )}
        {role !== 'user' && (
          <Route
            path='/custom-scopes-processing-queue'
            element={<ProcessingQueue />}
          />
        )}
        <Route path='/error' element={<ErrorPage />} />
        <Route path='/schedule' element={<ScheduleForm />} />
        <Route path='/associate-component' element={<ClientComponentForm />} />
      </Routes>
    </div>
  );
}

export default App;
