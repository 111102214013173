import { gql } from '@apollo/client';

export const DELETE_TEST_EXECUTIONS_BY_ID = gql`
  mutation DeleteTestExecutionsById($idList: [ID]) {
    testsExecutionDeleteWithAssociatedUrlTests(idList: $idList)
  }
`;

export const EDIT_TEST_EXECUTION_NAME = gql`
  mutation EditTestExecutionName(
    $id: MongoID!
    $record: UpdateByIdTestsExecutionInput!
  ) {
    testsExecutionUpdateById(_id: $id, record: $record) {
      record {
        name
      }
    }
  }
`;

export const EDIT_SCHEDULED_TEST_EXECUTION = gql`
  mutation TestsExecutionUpdateById(
    $id: MongoID!
    $record: UpdateByIdTestsExecutionInput!
  ) {
    testsExecutionUpdateById(_id: $id, record: $record) {
      error {
        message
      }
      recordId
      record {
        email
        lastExecution
        isScheduled
        notificationType
        scheduleStartDate
        region
        cronExpression
        frequency
      }
    }
  }
`;

export const ADD_FAVORITE = gql`
  mutation AddFavorite($testId: ID, $uid: ID) {
    userAddFavourite(userId: $uid, testId: $testId) {
      email
      favouriteIds
    }
  }
`;
export const REMOVE_FAVORITE = gql`
  mutation RemoveFavorite($testId: ID, $uid: ID) {
    userRemoveFavourite(userId: $uid, testId: $testId) {
      email
      favouriteIds
    }
  }
`;

export const CANCEL_EXECUTION = gql`
  mutation CancelExecution($ids: [ID!]!) {
    testsExecutionCancelTestsExecution(idList: $ids)
  }
`;

export const SAVE_TEST_EXECUTION_SCHEDULE = gql`
  mutation SaveTestExecutionSchedule(
    $id: MongoID!
    $record: RecordValuesInput!
  ) {
    testsExecutionUpdateScheduleCreateBridge(id: $id, record: $record)
  }
`;

export const UPDATE_IMPLEMENTED_WC_CLIENTS = gql`
  mutation UpdateImplementedWcClients(
    $component: String!
    $application: String!
    $publisher: String!
    $clients: [String!]
  ) {
    implementedWCUpdateOneImplementedClients(
      component: $component
      application: $application
      publisher: $publisher
      clients: $clients
    )
  }
`;

export const RUN_EDITED_TESTS_EXECUTION = gql`
  mutation RunEditedTestsExecution($teId: ID!) {
    testsExecutionRunEditedTestExecution(teId: $teId) {
      _id
    }
  }
`;

export const STOP_RESUME_SCHEDULED_EXECUTION = gql`
  mutation TestsExecutionStopResumeScheduledTestExecution(
    $listId: [ID!]!
    $pausing: Boolean!
  ) {
    testsExecutionStopResumeScheduledTestExecution(
      listId: $listId
      pausing: $pausing
    )
  }
`;
