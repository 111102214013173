import React, { useContext } from 'react';
import {
  MenuComponent,
  SidebarComponent,
} from '@syncfusion/ej2-react-navigations';
import getMenuItemsRole from './MenuItems';
import './MainMenu.css';
import { AppContext } from '../../UI/contexts';
import { isAuthRol } from '../../../Utils/helpers';

export function MainMenu({
  target = '.layout-content',
  openType = 'Auto',
  reference,
  closeOnDocumentClick = true,
  width = 180,
  visible = false,
  position = 'Right',
  onCloseBtnClick,
}) {
  const sidebarRef = reference;
  const { user } = useContext(AppContext);
  const { roles } = user || {};
  const role = isAuthRol(roles);
  const menuItems = getMenuItemsRole(role);

  return (
    <SidebarComponent
      className='sidebar'
      target={target}
      type={openType}
      isOpen={visible}
      // enablePersistence={true}
      ref={(r) => (sidebarRef.current = r)}
      position={position}
      width={width}
      closeOnDocumentClick={closeOnDocumentClick}
      style={{ visibility: visible ? 'visible' : 'hidden' }}
      created={(e) => {
        if (sidebarRef) {
          sidebarRef.current.element.style.visibility = visible
            ? 'visible'
            : '';
        }
      }}
    >
      <div className='main-menu'>
        {/* <div className='main-menu-header'>
          <div className='menu-text' style={{ display: 'inline-block' }}></div>
          <span
            className='e-icons e-close close-sidebar-btn'
            onClick={onCloseBtnClick}
          />
        </div> */}

        <MenuComponent
          enablePersistence
          items={menuItems}
          orientation='Vertical'
          cssClass='sidebar-menu'
          hamburgerMode
          showItemOnClick
          // animationSettings={{
          //   duration: 800,
          //   effect: "FadeIn",
          // }}
        />
      </div>
    </SidebarComponent>
  );
}
