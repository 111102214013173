import React, {useState} from 'react';
import * as cronParser from 'cron-parser';
import {ButtonComponent} from '@syncfusion/ej2-react-buttons';
import {AccordionComponent, AccordionItemDirective, AccordionItemsDirective,} from '@syncfusion/ej2-react-navigations';
import {useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import {GET_TEST_EXECUTIONS_BY_ID} from '../../../GraphQL/TestExecutions/TRFX/Queries';
import {EDIT_SCHEDULED_TEST_EXECUTION} from '../../../GraphQL/TestExecutions/TRFX/Mutations';
import {CheckBoxSelection, Inject, MultiSelectComponent,} from '@syncfusion/ej2-react-dropdowns';
import './Schedule.css';
import {get} from "../../../Utils/helpers";

function ScheduleForm() {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const teId = params.get('te');
  const navigate = useNavigate();
  // const [runMutation] = useMutation(SAVE_TEST_EXECUTION_SCHEDULE);
  const [runMutation] = useMutation(EDIT_SCHEDULED_TEST_EXECUTION);
  const [isScheduleParam, setIsScheduleParam] = useState(false);
  const [iconCss, setIconCss] = useState('fa fa-play');
  const [frequency, setFrequency] = useState('');
  const [content, setContent] = useState('Start schedule');
  const [formTitle, setFormTitle] = useState('Form schedule');
  const [email, setEmail] = useState('');
  const [saveMode, setSaveMode] = useState(false);
  const [showEmailList, setShowEmailList] = useState(false);
  const [notificationType, setNotificationType] = useState([]);

  const checkFields = {text: 'name', value: 'code'};
  const selectOptions = [
    {name: 'Opsgenie', code: 'opsgenie'},
    {name: 'Email', code: 'email'},
  ];
  let mulObj = MultiSelectComponent;

  const validateCronExpression = (cronExpression) => {
    try {
      cronParser.parseExpression(cronExpression);
      return { ok: true };
    } catch (e) {
      return {
        ok: false,
        e: e.message
      };
    }
  }

  useQuery(GET_TEST_EXECUTIONS_BY_ID, {
    variables: {id: teId},
    onCompleted: (data) => {
      const res = get(data, 'testsExecutionFindById');
      if (res) {
        setIsScheduleParam(res.isScheduled);
        setFrequency(res.frequency);
        setFormTitle(`Schedule - ${res.name}`);
        setEmail((res.email || []).toString());
        if (res.notificationType === 'both') {
          setNotificationType(['opsgenie', 'email']);
          setShowEmailList(true);
        } else {
          setNotificationType([res.notificationType]);
          setShowEmailList(
            res.notificationType === 'email'
          );
        }
        if (res.isScheduled) {
          setIconCss('fa fa-stop');
          setContent('Stop schedule');
        }
      } else {
        errorHandler('Invalid tests execution.');
        navigate('/trfx-test-executions');
      }
    },
    onError: (error) => errorHandler(error.message),
  });

  const buttonSave = (event) => {
    event.preventDefault();
    const reviewMailStates = ['both', 'email'];
    setSaveMode(false);
    let notificationTypeVar = 'opsgenie';
    if (notificationType.length === 2) {
      notificationTypeVar = 'both';
    } else if (
      notificationType.length === 1 &&
      notificationType[0] === 'email'
    ) {
      notificationTypeVar = 'email';
    }

    // The validation of frequency will be performed by GQL
    // if (validateFrequency(frequency) === false) {
    //   return errorHandler('Wrong format or empty value for frequency.');
    // }

    let inputMails = email.replace(/ /g, '');
    inputMails = inputMails.toLowerCase();
    inputMails = inputMails.split(',');
    if (reviewMailStates.includes(notificationTypeVar)) {
      for (const mail of inputMails) {
        const isValid = EmailValidator.validate(mail);
        if (!isValid) {
          return errorHandler(`Wrong format for email ${mail}`);
        }
      }
    }

    const cronExpression = frequency.toString().trim();

    const isValidCronExpression = validateCronExpression(cronExpression);


    if (teId && isValidCronExpression.ok) {
      runMutation({
        variables: {
          id: teId,
          record: {
            isScheduled: true,
            scheduleStartDate: new Date(),
            frequency: cronExpression,
            cronExpression,
            email: inputMails,
            notificationType: notificationTypeVar,
          },
        },
      })
        .then((r) => {
          const response = get(r, 'data.testsExecutionUpdateScheduleCreateBridge');
          if (get(response, 'error')) {
            window.flash(`Test execution could not be updated. Error: ${get(response, 'error.message')}`, 'error');
            return;
          }
          window.hideMessage();
          window.flash('Tests execution successfully scheduled!', 'success');
          setContent('Stop schedule');
          setIconCss('fa fa-stop');
        })
        .catch((error) => {
          window.hideMessage();
          window.flash(
            'An error occurred. Tests execution could not be updated',
            'error'
          );
        });
    } else {
      window.showMessage(`Cron expression is invalid. Error: ${isValidCronExpression.e}`, 'error');
    }
  };
  const buttonSetSchedule = (isSchedule) => {
    // The validation of frequency will be performed by GQL
    // if (validateFrequency(frequency) === false) {
    //   return errorHandler('Frequency is required.');
    // }

    // setIsScheduleParam(isSchedule);
    // setIconCss(isSchedule === true ? 'fa fa-stop' : 'fa fa-play');
    // setContent(isSchedule === true ? 'Stop schedule' : 'Start schedule');
    runMutation({
      variables: {
        id: teId,
        record: {
          isScheduled: isSchedule,
          scheduleStartDate: new Date(),
        },
      },
    })
      .then((r) => {
        const response = get(r, 'data.testsExecutionUpdateScheduleCreateBridge');
        if (get(response, 'error')) {
          window.flash(`Test execution could not be updated. Error: ${get(response, 'error.message')}`, 'error');
          return;
        }
        setIsScheduleParam(isSchedule);
        setIconCss(isSchedule === true ? 'fa fa-stop' : 'fa fa-play');
        setContent(isSchedule === true ? 'Stop schedule' : 'Start schedule');
        window.hideMessage();
        window.flash('Tests execution successfully updated!', 'success');
      })
      .catch((error) => {
        window.hideMessage();
        window.flash(
          `An error occurred. Tests execution could not be updated. Error: ${error.message}`,
          'error'
        );
      });
  };

  const selectOptionsOnchange = (event) => {
    const values = event.value;
    setNotificationType(values);
    setSaveMode(true);
    if (values.includes('email')) {
      setShowEmailList(true);
    } else setShowEmailList(false);
  };
  const helpContent = () => {
    return (
      <table className='table table-striped'>
        <thead>
        <tr>
          <th>
            <b>Field</b>
          </th>
          <th>
            <b>Values</b>
          </th>
          <th>
            <b>Examples</b>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <p>Minutes</p>
          </td>
          <td>
            <p>0-59</p>
          </td>
          <td>
            <ul>
              <li>Run every 10 minutes ( */10 * * * * )</li>
              <li>Run at the minute '5' each hour ( 5 * * * * )</li>
              <li>Run every minute (every minute 3, 4, 5, 6, 7) between 3 and 7 each hour ( 3-7 * * * * )</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>Hours</p>
          </td>
          <td>
            <p>0-23</p>
          </td>
          <td>
            <ul>
              <li>Run at the minute 6 every 2 hours ( 6 */2 * * * )</li>
              <li>Run at the minutes 0 and 30 of the 2PM ( 0,30 14 * * * )</li>
              <li>Run every minute of the hours 3AM, 5AM, 7PM ( * 3,5,19 * * * )</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>Day-of-month</p>
          </td>
          <td>
            <p>1-31, L</p>
          </td>
          <td>
            <ul>
              <li>Run at the minute 0 of the hours 12AM, 12M every 2 days ( 0 0,12 */2 * * )</li>
              <li>Run at 1PM on the day 10 and the last day of each month ( 0 13 10,L * * )</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>Month</p>
          </td>
          <td>
            <p>1-12 or JAN-DEC</p>
          </td>
          <td>
            <ul>
              <li>Run on 3PM every last day of JAN, FEB, MAR and APR (0 15 L JAN,FEB,3,APR *)</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>Day-of-week</p>
          </td>
          <td>
            <p>1-7 or SUN-SAT</p>
          </td>
          <td>
            <p></p>
          </td>
        </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div className='d-flex justify-content-center'>
      <div className='e-card' style={{width: '70%'}}>
        <div className='e-card-header mt-2' style={{width: '100%'}}>
          <div className='e-card-header-caption'>
            <div>
              {/*<ButtonComponent*/}
              {/*  cssClass='e-flat'*/}
              {/*  iconCss={iconCss}*/}
              {/*  content={content}*/}
              {/*  isToggle*/}
              {/*  style={{ float: 'right', borderColor: '#f0f0f0' }}*/}
              {/*  onClick={() => buttonSetSchedule(!isScheduleParam)}*/}
              {/*/>*/}
            </div>
            <div className='e-card-header-title  d-flex justify-content-center'>
              <h5>{formTitle}</h5>
            </div>
          </div>
        </div>
        <form className='mr-3 ml-3'>
          <div className='mb-3 e-float-input form-group'>
            <input
              type='text'
              style={{width: '20%'}}
              placeholder='Set frequency format'
              onChange={(event) => {
                setFrequency(event.target.value);
                setSaveMode(true);
              }}
              value={frequency}
            />
          </div>
          <div className='mb-3 e-float-input form-group notification--checkbox'>
            <MultiSelectComponent
              id='checkbox'
              ref={(scope) => {
                mulObj = scope;
              }}
              dataSource={selectOptions}
              fields={checkFields}
              placeholder='Select options'
              mode='CheckBox'
              showSelectAll={true}
              showDropDownIcon={true}
              onChange={selectOptionsOnchange}
              filterBarPlaceholder='Select options'
              popupHeight='350px'
              value={notificationType}
            >
              <Inject services={[CheckBoxSelection]}/>
            </MultiSelectComponent>
          </div>
          {showEmailList && (
            <div className='mb-3 e-float-input form-group'>
              <input
                type='text'
                style={{width: '50%'}}
                placeholder='Notification e-mail'
                onChange={(event) => {
                  setEmail(event.target.value);
                  setSaveMode(true);
                }}
                value={email}
              />
            </div>
          )}

          <div className='form-group'>
            <AccordionComponent
              expandMode='Single'
              className='accordion-schedule'
            >
              <AccordionItemsDirective>
                <AccordionItemDirective header='Help' content={helpContent}/>
              </AccordionItemsDirective>
            </AccordionComponent>
          </div>
          <div className='d-flex justify-content-center mt-5 form-group'>
            <ButtonComponent
              type='button'
              onClick={() => (window.location.href = '/trfx-test-executions')}
              className='e-control e-btn e-lib e-outline mr-1'
              iconCss='e-icons e-close'
            >
              Close
            </ButtonComponent>
            {saveMode && (
              <ButtonComponent
                onClick={(event) => buttonSave(event)}
                className='e-control e-btn e-lib e-custom btn-primary'
                iconCss='e-icons e-save'
                type='button'
              >
                Save
              </ButtonComponent>
            )}
            {!saveMode && (
              <ButtonComponent
                onClick={() => buttonSetSchedule(!isScheduleParam)}
                className='e-control e-btn e-lib e-custom btn-primary'
                iconCss={
                  (isScheduleParam && 'e-icons e-pause') || 'e-icons e-play'
                }
                type='button'
              >
                {content}
              </ButtonComponent>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ScheduleForm;
