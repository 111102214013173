// import LoginButton from "./LoginButton"
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import React, { Fragment, useContext } from 'react';
import SampleCard from '../UI/HomeCard/HomeCard';
import './Home.css';
import { AppContext } from '../UI/contexts';
import { isAuthRol } from '../../Utils/helpers';

function Login() {
  const onclickCard = (href) => {
    window.location.href = href;
  };
  //Get auth context
  const { user } = useContext(AppContext);
  const { email, roles } = user || {};
  const role = isAuthRol(roles);

  return (
    <>
      <div className='d-flex flex-column justify-content-center'>
        <div className='d-flex justify-content-center'>
          <h2
            className='d-flex'
            style={{
              fontWeight: 'bold',
              fontStyle: 'italic',
              color: 'darkgray',
            }}
          >
            Welcome to air
            <span style={{ color: '#124069' }}>TestTool</span>
          </h2>
        </div>
        {/* TODO: Remove this */}
        <div className='d-flex justify-content-center'>
          <h6>
            <i>Logged as {email}</i>
          </h6>
        </div>
      </div>
      <div
        className='d-flex justify-content-center mt-5'
        style={{ columnGap: '20px' }}
      >
        <SampleCard header='TRFX' imgSrc='/EM_airTRFX_logo.png'>
          <ButtonComponent onClick={() => onclickCard('/trfx-run-tests')}>
            Run tests
          </ButtonComponent>
          <ButtonComponent onClick={() => onclickCard('/trfx-test-executions')}>
            Test Executions
          </ButtonComponent>
          <ButtonComponent onClick={() => onclickCard('/add-url')}>
            Add Url
          </ButtonComponent>
          <ButtonComponent onClick={() => onclickCard('/delete-url')}>
            Delete Url
          </ButtonComponent>
          {role !== 'user' && (
            <ButtonComponent onClick={() => onclickCard('/view-custom-scopes')}>
              View Custom Scopes
            </ButtonComponent>
          )}
        </SampleCard>
        {/* <SampleCard header='aTC' imgSrc='/em_portal_logo.png'>
          <ButtonComponent onClick={() => onclickCard('/atc-run-tests')}>
            Run tests
          </ButtonComponent>
          <ButtonComponent onClick={() => onclickCard('/atc-test-executions')}>
            Test Executions
          </ButtonComponent>
          <ButtonComponent onClick={() => onclickCard('/add-feature')}>
            Add Feature
          </ButtonComponent>
          <ButtonComponent onClick={() => onclickCard('/delete-feature')}>
            Delete Feature
          </ButtonComponent>
        </SampleCard> */}
      </div>
    </>
  );
}

export default Login;
