import Layout from './Layout';
import { useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { get, isString } from '../../Utils/helpers';
import { AppContext } from '../UI/contexts';
import { useLazyQuery } from '@apollo/client';
import LoadingSpinner from '../../Components/UI/Spinner/LoadingSpinner';
import { ATT_ENV, IS_LOCAL, LOCAL_USER } from '../../Constants/constants';
import { GET_USER_FROM_CF_TOKEN } from '../../GraphQL/Auth/Queries';
import React from 'react';

function Application(props) {
  // read cloudflare cookie
  const cloudflareToken = Cookies.get('CF_Authorization');

  let user = sessionStorage.getItem('user');

  try {
    user = isString(user) ? JSON.parse(user) : user;
  } catch (e) {
    console.error('Saved user data is invalid', e);
    user = null;
  }
  const [authenticatedUser, setAuthenticatedUser] = useState(user);

  const [getUserData] = useLazyQuery(GET_USER_FROM_CF_TOKEN);

  const saveUser = (u) => {
    sessionStorage.setItem('user', JSON.stringify(u));
    setAuthenticatedUser(u);
  };

  useEffect(() => {
    if (!user) {
      getUserData({
        variables: {
          cfToken: cloudflareToken,
        },
      })
        .then((r) => {
          const u = get(r, 'data.userGetUserFromCFToken');
          if (u !== null) {
            saveUser(u);
          } else if (IS_LOCAL) {
            saveUser({
              email: LOCAL_USER,
              roles: [{ name: 'admin' }],
              _id: '61097bfa97ff9f0009fe3954',
            });
          } else if (
            window.location.href.includes('att-develop.pages.dev') ||
            window.location.href.includes('att-gui-prod.pages.dev')
          ) {
            saveUser({
              email: 'invalidUser',
              roles: [{ name: 'invalidUser' }],
              _id: 'invalidUser',
            });
          }
        })
        .catch((e) => {
          if (IS_LOCAL) {
            saveUser({
              email: LOCAL_USER,
              roles: [{ name: 'admin' }],
              _id: '61097bfa97ff9f0009fe3954',
            });
          }
        });
    }
  }, [user]);

  const appData = useMemo(
    () => ({ user: authenticatedUser }),
    [authenticatedUser]
  );

  return authenticatedUser ? (
    <AppContext.Provider value={appData}>
      <Layout>{props.children}</Layout>)
    </AppContext.Provider>
  ) : (
    <>
      <div id='container' />
      <LoadingSpinner />
      <div className='authenticating-user'>Authenticating user...</div>
    </>
  );
}

export default Application;
