import React from 'react';

function Logo() {
  return (
    <div>
      <span style={{ color: 'darkgray', marginBottom: '0' }}>air</span>
      <span style={{ color: '#124069', marginBottom: '0' }}>TestTool</span>
    </div>
  );
}

export default Logo;
