import React from 'react';
import Spinner from './Spinner/Spinner';

function GraphQLWrapper({ component, loading, error }) {
  if (error)
    return (
      <div className='d-flex flex-column'>
        <div style={{ width: '80%', backgroundColor: 'lightgray' }}>
          <p>{error}</p>
        </div>
      </div>
    );

  if (loading) {
    return <Spinner />;
  }
  return (
    <div
      className='d-flex flex-column '
      style={{ width: '100%', height: '100%' }}
    >
      {component}
    </div>
  );
}

export default GraphQLWrapper;
