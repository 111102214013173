import React from 'react';
import { IoMdPlay } from 'react-icons/io';
import {
  FINISHED,
  PASSED,
  FAILED,
  CANCELLED,
  EDITED,
} from '../../../Constants/constants';
import '../../TestExecutions/TestExecutions.css';

export default function Rerun({
  testId,
  testStatus,
  testName,
  rerunSingleClickHandler,
}) {
  const enable = [FINISHED, PASSED, FAILED, CANCELLED, EDITED].includes(
    testStatus
  );

  return enable ? (
    <IoMdPlay
      title='rerun test execution'
      className='actions-icon'
      onClick={(event) => {
        rerunSingleClickHandler(event, testId, testStatus, testName);
      }}
    />
  ) : (
    <IoMdPlay className='actions-icon-disabled' />
  );
}
