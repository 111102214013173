import { gql } from '@apollo/client';

export const CONTEXT_ADD_URL = gql`
  mutation AddUrlToContext(
    $env: String!
    $client: String!
    $url: String!
    $application: String!
  ) {
    contextAddUrlToContext(
      env: $env
      client: $client
      url: $url
      application: $application
    ) {
      env
      client
      template
      siteEdition
      urls
      application
    }
  }
`;

export const CONTEXT_ADD_MANY_URL = gql`
  mutation AddManyUrlsToContext(
    $env: String!
    $client: String!
    $urls: [String]!
    $application: String!
    $user: ID!
  ) {
    contextAddManyUrlsToContext(
      env: $env
      client: $client
      urls: $urls
      application: $application
      user: $user
    )
  }
`;

export const DELETE_URL_FROM_CONTEXT = gql`
  mutation DeleteUrlFromContext(
    $env: String!
    $client: String!
    $template: String!
    $siteEdition: String!
    $url: String!
  ) {
    contextDeleteUrlFromContext(
      env: $env
      client: $client
      template: $template
      siteEdition: $siteEdition
      url: $url
    ) {
      env
      client
      template
      siteEdition
      urls
    }
  }
`;

export const EDIT_URL_FROM_CONTEXT = gql`
  mutation EditUrlFromContext(
    $contextId: ID!
    $toDeleteUrl: String!
    $toUpdateUrl: String!
  ) {
    contextSaveEditedUrl(
      contextId: $contextId
      toDeleteUrl: $toDeleteUrl
      toUpdateUrl: $toUpdateUrl
    ) {
      _id
    }
  }
`;
