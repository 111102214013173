import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { DialogComponent, DialogUtility } from '@syncfusion/ej2-react-popups';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import GraphQLWrapper from '../UI/GraphQLWrapper';

import CustomProgressBar from '../UI/CustomProgressBar/CustomProgressBar';
import ElapsedTime from '../UI/ElapsedTIme';
import TextName from '../UI/TextName';
import TestResultsSpinner from '../UI/Spinner/TestResultsSpinner';
import {
  CANCELLED,
  FINISHED,
  TEST_RESULTS_REQUEST_LIMIT,
  REGIONS_DICTIONARY,
  ATT_ENV,
} from '../../Constants/constants';
import { isEmpty } from '../../Utils/helpers';
import { getElementForTreeNode, getTestResultsForTreeView } from './helpers';
import { getDate, getTime } from '../../Utils/helpers';
import './TestResults.css';

import FilterFactory from '../UI/Filters/FilterFactory';
import ErrorFallback from '../Error/ErrorFallback';
import { AppContext } from '../UI/contexts';

// Show a test result given a valid Id
function TestResults({
  id,
  queryGetResultsById,
  queryGetDuration,
  queryGetRegionAndName,
  queryGetUrlTestsIds,
  queryGetFailedExecutions,
  onCancelRedirectUrl,
  cancelMutation,
  getTestResultsQueryName,
  getNameAndRegionQueryName,
  getDurationQueryName,
  dsProviderId,
  filterConfigs,
  getTestResultsQueryDataName,
  typeName,
}) {
  const { user } = useContext(AppContext);
  const [userOwnsTest, setUserOwnsTest] = useState(false);

  const [seconds, setSeconds] = useState(0);
  const [testStatus, setTestStatus] = useState();

  const pendingUrlTests = useRef([]);
  const maxDuration = useRef(0);
  const start = useRef(null);
  const queryFinished = useRef(false);
  const pollInterval = useRef(15000);
  const urlsLength = useRef(0);
  const name = useRef(null);
  const region = useRef(null);
  const fullTestResults = useRef([]);

  const [testResults, setTestResults] = useState(null);
  const [allTestArrived, setAllTestArrived] = useState(false);
  const [stopCount, setStopCount] = useState(false);
  const [consecutiveFails, setConsecutiveFails] = useState(0);

  const ssDialog = useRef();

  const filters = useRef([]);

  const treeView = useRef();
  const [onCancelTest] = useMutation(cancelMutation);
  const [cancelling, setCancelling] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [testExecutionExists, setTestExecutionExists] = useState(true);

  const createFilters = (pTestResults) => {
    filters.current = filterConfigs.map((fc) => {
      return FilterFactory.create({
        filterConfig: {
          ...fc,
          onChange: onFilterChange,
        },
        originalDS: pTestResults,
        currentDS: pTestResults,
        dsProviderId,
      });
    });
  };

  const updateFilters = (newDS, filterChanged, event, extraData) => {
    // eslint-disable-next-line
    const removing = event && event.name === 'removed';
    filters.current.forEach((f) => {
      if (!filterChanged) {
        f.updateDataSource(newDS, extraData);
      }
    });
  };

  const onFilterChange = (filter, event) => {
    const ds = fullTestResults.current;
    const filteredResults = (filters.current || [])
      .sort((a, b) => {
        if (a.priority > b.priority) {
          return -1;
        }
        if (a.priority < b.priority) {
          return 1;
        }
        return 0;
      })
      .reduce((acc, f) => {
        return f.filterDS(acc);
      }, ds);

    updateFilters(filteredResults, filter, event);
    setTestResults(filteredResults);
  };

  const clearFilters = () => {
    filters.current.forEach((f) => {
      f.clear();
    });
    updateFilters(fullTestResults.current);
    setTestResults(fullTestResults.current);
  };

  const { refetch } = useQuery(queryGetDuration, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      queryFinished.current = true;
      if (data[getDurationQueryName] === null) {
        setTestExecutionExists(false);
        return;
      }
      setUserOwnsTest(data[getDurationQueryName].userId === user._id);
      urlsLength.current = data[getDurationQueryName].total;
      start.current = new Date(data[getDurationQueryName].start);
      const finalStatus = data[getDurationQueryName].status;
      setTestStatus(finalStatus);
      if (finalStatus === FINISHED || finalStatus === CANCELLED) {
        setStopCount(true);
        maxDuration.current = data[getDurationQueryName].duration;
        pollInterval.current = 5000;
      }
    },
    onError: (error) => {
      setTestExecutionExists(false);
      queryFinished.current = true;
    },
  });

  useQuery(queryGetUrlTestsIds, {
    variables: {
      executionId: id,
      limit: 1000,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      pendingUrlTests.current = data[getTestResultsQueryName];
      if (pendingUrlTests.current.length === 0) {
        window.flash('There are no results related to execution', 'info');
        setTimeout(() => (window.location = onCancelRedirectUrl), 4000);
      }
    },
    onError: (error) => {},
  });

  useQuery(queryGetRegionAndName, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      name.current = data[getNameAndRegionQueryName].name ?? null;
      region.current =
        REGIONS_DICTIONARY[data[getNameAndRegionQueryName].region] ?? null;
      if (name.current.length - 25 > 0)
        getConsecutiveFailedCount({
          variables: {
            filters: {
              attEnv: ATT_ENV,
              nameRegEx: name.current.slice(0, name.current.length - 25),
              nameChild: name.current,
            },
          },
        });
    },
    onError: (_) => {},
  });

  const [getConsecutiveFailedCount] = useLazyQuery(queryGetFailedExecutions, {
    onCompleted: (data) => {
      setConsecutiveFails(data.testsExecutionGetConsecutiveFailedCount.count);
    },
    onError: (_) => {},
  });

  const { error, stopPolling, startPolling } = useQuery(queryGetResultsById, {
    variables: {
      executionId: id,
      idsToInclude: (
        pendingUrlTests.current?.slice(0, TEST_RESULTS_REQUEST_LIMIT) || []
      ).map((r) => r._id),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const results = (data[getTestResultsQueryName] || []).filter((r) => {
        return r[getTestResultsQueryDataName] !== null;
      });

      if (isEmpty(results)) {
        return;
      }
      const receivedIds = results.map((r) => r._id);
      const resultsForTreeview = getTestResultsForTreeView(
        results,
        (testResults || []).length,
        typeName
      );
      const updatedResults = [
        ...(testResults || []),
        ...resultsForTreeview.filter(
          (r) => !testResults?.map((tr) => tr.text).includes(r.text)
        ),
      ];

      // remove received tests from list of pending
      pendingUrlTests.current = pendingUrlTests.current.filter(
        ({ _id }) => !receivedIds.includes(_id)
      );

      if (pendingUrlTests.current.length === 0) {
        stopPolling();
        setStopCount(true);
        refetch();

        fullTestResults.current = updatedResults;

        if (isEmpty(filters.current)) {
          createFilters(updatedResults);
        }
        setAllTestArrived(true);
      }
      if (results.length > 0) {
        setTestResults(updatedResults);
      }
    },
    onError: (error) => {
      // console.log(
      //   `Failed operation: GetUrlTests. Error: ${error && error.message}`,
      //   'error'
      // );
    },
  });

  useEffect(() => {
    startPolling(pollInterval.current);
  }, [startPolling, pollInterval]); //react-hooks/exhaustive-deps

  useEffect(() => {
    if (stopCount) return;
    setTimeout(() => {
      setSeconds((Date.now() - start.current) / 1000);
    }, 1000);
  });

  if (queryFinished.current && !testExecutionExists) {
    return (
      <ErrorFallback error={{ message: 'Test execution does not exist' }} />
    );
  }

  const onCancelCompleted = () => {
    window.location.href = onCancelRedirectUrl;
  };
  const onCancelError = (error) => {
    window.flash(
      'An unexpected error ocurred. Test Execution could not be cancelled',
      'error'
    );
  };

  function collapseHandler() {
    if (treeView.current) {
      treeView.current.collapseAll();
    }
  }

  function expandHandler() {
    if (treeView.current) {
      // search failed items
      const targetLevel = 1;
      const getFailedNodes = (nodes, level, levelToStop) => {
        if (level >= targetLevel) {
          return [];
        }

        const failedNodes = nodes.filter((n) => n.status === 'FAILED');
        const failedChildren = failedNodes.reduce((acc, fn) => {
          const { it = [] } = fn || {};
          return acc.concat(...getFailedNodes(it, level + 1, levelToStop));
        }, []);
        return [...failedNodes.map((n) => n.id), ...failedChildren];
      };

      const nodesToExpand = getFailedNodes(testResults, 0, targetLevel);
      // const nodesToExpand = getFailedNodes(treeViewDS.current, 0)
      treeView.current.expandedNodes = nodesToExpand;
    }
  }

  const getUrlTestResultsByStatus = (status, pTestResults) => {
    return (pTestResults || []).filter((tr) => tr.status === status);
  };

  // ?: Add the canceled status
  const progressBar = (
    <CustomProgressBar
      passed={getUrlTestResultsByStatus('PASSED', testResults).length}
      failed={getUrlTestResultsByStatus('FAILED', testResults).length}
      notExecuted={
        getUrlTestResultsByStatus('NOT_EXECUTED', testResults).length
      }
      total={urlsLength.current}
      consecutiveFails={consecutiveFails}
      current={(testResults || []).length}
    />
  );

  const openScreenshotModal = (screenshotSrc, e) => {
    if (ssDialog.current) {
      const c = document.createElement('div');
      c.setAttribute('class', 'inline-center-flex-container');
      const img = document.createElement('img');
      img.setAttribute('src', screenshotSrc);
      c.appendChild(img);
      ssDialog.current.content = c;

      const ww = window.innerWidth;
      const wh = window.innerHeight;

      const cy = e.view.pageYOffset;

      const dw = (ww * 7) / 10;

      ssDialog.current.position = {
        X: ww / 2 - dw / 2,
        Y: cy + wh / 2 - (wh * 8) / 20,
      };

      ssDialog.current.show();
    }
  };

  const closeScreenshotModal = () => {
    // setScreenshotSrc(null)
    if (ssDialog.current) {
      ssDialog.current.content = null;
      ssDialog.current.hide();
    }
  };

  const treeViewNodeTemplate = (nodeData) => {
    return getElementForTreeNode({
      currentTestResult: nodeData,
      openScreenshotModal,
      closeScreenshotModal,
    });
  };

  const treeViewPanel = isEmpty(testResults) ? (
    <div
      className='d-flex justify-content-center mt-2'
      style={{ color: 'gray' }}
    >
      No results found. Please check your filters.
    </div>
  ) : (
    <div className='accordion-container'>
      <TreeViewComponent
        ref={(tv) => (treeView.current = tv)}
        enablePersistence
        fullRowSelect
        expandOn='Click'
        animation={{
          expand: { effect: 'SlideDown', duration: 200, easing: 'linear' },
          collapse: { effect: 'SlideUp', duration: 200, easing: 'linear' },
        }}
        fields={{
          dataSource: testResults,
          id: 'id',
          text: 'text',
          child: 'it',
        }}
        nodeTemplate={treeViewNodeTemplate}
        loadOnDemand
      />
    </div>
  );

  const label =
    queryFinished.current && ((stopCount && 'Duration') || 'Elapsed Time');
  const showSeconds =
    (queryFinished.current &&
      ((stopCount && maxDuration.current) || seconds)) ||
    null;

  const dateAndTime =
    start.current !== null
      ? `${getDate(start.current)}-${getTime(start.current)}`
      : '';

  const showCancelButton = queryFinished.current && label !== 'Duration';

  const filtersPanel = showFilters && (
    <div className='d-flex justify-content-center'>
      <div className='filters-panel'>
        <div className='e-card'>
          <div className='e-card-header'>
            <div className='e-card-header-caption'>
              <ButtonComponent
                iconCss='e-icons e-filter-clear-2'
                onClick={clearFilters}
                cssClass='e-custom filters-reset-btn'
              >
                Reset
              </ButtonComponent>
            </div>
            <div
              className='e-card-header-image e-icons e-close filters-close-btn'
              onClick={(e) => {
                setShowFilters(false);
              }}
            />
          </div>
          <div className='e-card-content'>
            <div className='row'>
              {filters.current.map((f) => {
                return f.render();
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const view = (
    <div id='test-results-content'>
      <div className='d-flex flex-column align-items-center'>
        {showCancelButton && (
          <div
            className='d-flex justify-content-center'
            style={{ width: '100%' }}
          >
            <div
              className='d-flex justify-content-end'
              style={{ width: '100%' }}
            >
              {userOwnsTest && (
                <ButtonComponent
                  style={{ backgroundColor: '#124069', color: 'white' }}
                  onClick={() => {
                    DialogUtility.confirm({
                      animationSettings: { effect: 'Zoom' },
                      closeOnEscape: false,
                      content:
                        'Are you sure you want to cancel this execution?',
                      cancelButton: {
                        text: 'Cancel',
                      },
                      okButton: {
                        text: 'OK',
                        click: () => {
                          onCancelTest({ variables: { ids: [id] } })
                            .then((r) => {
                              onCancelCompleted();
                            })
                            .catch((e) => {
                              // TODO: Improve this
                              alert(
                                `Cancel test execution failed. Error: ${e.message}`
                              );
                              onCancelError(e);
                              setCancelling(false);
                            });
                          setCancelling(true);
                        },
                      },

                      showCloseIcon: false,
                      title: 'Cancel test execution?',
                    });
                  }}
                >
                  Cancel Test Execution
                </ButtonComponent>
              )}
            </div>
          </div>
        )}
        <div className='row justify-content-center' style={{ width: '100%' }}>
          <ElapsedTime className='col-sm' seconds={showSeconds} label={label} />
        </div>
        <div className='row justify-content-center pb-4'>
          <TextName className='col-sm' label='Name:' text={name.current} />
          {region.current !== null && (
            <TextName
              className='col-sm'
              label='Region:'
              text={region.current}
            />
          )}
          <TextName className='col-sm' label='Start Date:' text={dateAndTime} />
        </div>
        {progressBar}
      </div>

      {testResults && allTestArrived ? (
        <>
          {allTestArrived && (
            <div className='d-flex justify-content-center'>
              <div className='test-results-actions-panel'>
                <ButtonComponent
                  iconCss='e-icons e-filter-3'
                  cssClass='e-custom'
                  isToggle
                  onClick={() => {
                    filters.current.map((f) => f.updateDefaultValues());
                    setShowFilters(!showFilters);
                  }}
                >
                  Filters
                </ButtonComponent>
                <ButtonComponent
                  iconCss='e-icons e-expand'
                  onClick={expandHandler}
                >
                  Expand Failed
                </ButtonComponent>
                <ButtonComponent
                  iconCss='e-icons e-collapse-2'
                  onClick={collapseHandler}
                >
                  Collapse All
                </ButtonComponent>
              </div>
            </div>
          )}

          {filtersPanel}

          {testStatus === CANCELLED && (
            <div className='d-flex justify-content-center'>
              <div
                style={{ color: '#124069', fontStyle: 'italic', width: '85%' }}
              >
                Test Execution was cancelled by user
              </div>
            </div>
          )}

          {treeViewPanel}

          <DialogComponent
            open={false}
            position={{ X: 'center', Y: 'center' }}
            ref={ssDialog}
            showCloseIcon
            cssClass='modal'
            visible={false}
            closeOnEscape
            enableResize
            overlayClick={(e) => {
              ssDialog.current.hide();
            }}
          />
        </>
      ) : (
        <div>
          <div className='in-progress-te-message'>
            {cancelling
              ? 'Cancelling test execution...'
              : 'Tests in progress. Please wait...'}
          </div>
          <TestResultsSpinner />
        </div>
      )}
    </div>
  );

  return (
    <GraphQLWrapper
      loading={!queryFinished.current}
      error={error}
      component={view}
    />
  );
}

export default TestResults;
