import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import FilterDataHandler from './FilterDataHandler';
import { isEmpty } from '../../../Utils/helpers';

export default class TreeFilterDataHandler extends FilterDataHandler {
  filterDS(ds, values) {
    if (isEmpty(values)) {
      return ds;
    }
    const predicate = this.getPredicate(values);
    const query = new Query().where(predicate);
    const dm = new DataManager(ds || []);
    const leaves = dm.executeLocal(query);
    const parentsIds = leaves.reduce((acc, ld) => {
      (ld.parentIds || []).forEach((id) => acc.add(id));
      return acc;
    }, new Set());

    const predicateForParents = (Array.from(parentsIds) || []).reduce(
      (acc, v) => {
        return acc ? acc.or('id', 'equal', v) : new Predicate('id', 'equal', v);
      },
      undefined
    );
    const queryForParents = new Query().where(predicateForParents);
    const result = dm.executeLocal(queryForParents);
    return [...result, ...leaves];
  }
}
