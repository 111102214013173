import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CANCEL_EXECUTION,
  DELETE_TEST_EXECUTIONS_BY_ID,
} from '../../../GraphQL/TestExecutions/TRFX/Mutations';
import { GET_TEST_EXECUTIONS_PAGINATED } from '../../../GraphQL/TestExecutions/TRFX/Queries';

import TestExecutions from '../TestExecutions';
import { TEST_EXECUTION_RERUN_TESTS } from '../../../GraphQL/TestResults/TRFX/Mutations';
import { AppContext } from '../../UI/contexts';

function DataCollector({ variables, isScheduled }) {
  const { user } = useContext(AppContext);

  const [data, setData] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [itemCount, setItemCount] = useState(1);

  const errorHandler = (error) => {
    window.flash(
      'An unexpected error occurred, please try again later.',
      'error'
    );
  };

  const { refetch } = useQuery(GET_TEST_EXECUTIONS_PAGINATED, {
    variables: variables,
    pollInterval: 15000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setData(data.testsExecutionPagination.items);
      setPageCount(data.testsExecutionPagination.pageInfo.pageCount);
      setItemCount(data.testsExecutionPagination.pageInfo.itemCount);
      window.hideMessage();
    },
    onError: (error) => errorHandler(error.message),
  });

  const [deleteTests] = useMutation(DELETE_TEST_EXECUTIONS_BY_ID);
  const [cancelExecution] = useMutation(CANCEL_EXECUTION);

  const getTestResultsUrl = (id) => {
    return `/trfx-show-test-results/${id}`;
  };

  const getRerunTestExecutionVariables = (testId, newName) => {
    return {
      variables: {
        teId: testId,
        name: newName,
        userId: user._id,
      },
    };
  };

  return (
    <TestExecutions
      data={data}
      isScheduled={isScheduled}
      refetch={refetch}
      perPage={variables.perPage}
      itemCount={itemCount}
      deleteTests={deleteTests}
      cancelExecution={cancelExecution}
      getTestResultsUrl={getTestResultsUrl}
      type='TRFX'
      editRoute='trfx-run-tests-edit'
      rerunTestExecutionMutation={TEST_EXECUTION_RERUN_TESTS}
      getRerunTestExecutionVariables={getRerunTestExecutionVariables}
      filterVariables={variables.filter}
    />
  );
}
export default DataCollector;
