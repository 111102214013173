// import { ProgressBar } from "react-bootstrap"
import '../../TestResults/TestResults.css';
import React from 'react';
import './CustomProgressBar.css';
import { ProgressBarComponent } from '@syncfusion/ej2-react-progressbar';

function CustomProgressBar({
  passed = 0,
  failed = 0,
  inProgress = 0,
  notExecuted = 0,
  total = 0,
  current = 0,
  consecutiveFails = 0,
}) {
  inProgress = total - (passed + failed + notExecuted);
  return (
    <div className='custom-progress-bar-container'>
      <ProgressBarComponent
        trackThickness='25px'
        progressThickness='25px'
        showProgressValue
        value={total > 0 ? Math.floor((current * 100) / total) : 0}
        progressColor='#124069'
        labelStyle={{
          fontWeight: 'bold',
          size: '20px',
          fontFamily: 'Arial',
          textAlignment: 'Center',
        }}
      />
      <div className='custom-progress-bar-info-panel'>
        <div className='PASSED e-badge'>
          <span className='status-value'>{passed}</span>
          <span className='status-text'>Passed</span>
        </div>
        <div className='FAILED e-badge'>
          <span className='status-value'>{failed}</span>
          <span className='status-text'>Failed</span>
        </div>
        <div className='IN_PROGRESS e-badge'>
          <span className='status-value'>{inProgress}</span>
          <span className='status-text'>In Progress</span>
        </div>
        <div className='NOT_EXECUTED e-badge'>
          <span className='status-value'>{notExecuted}</span>
          <span className='status-text'>Not Executed</span>
        </div>
        <div className='e-badge e-badge-dark'>
          <span className='status-value'>{total}</span>
          <span className='status-text'>Total</span>
        </div>
        <div className='e-badge consecutive-fails'>
          <span className='status-value'>{consecutiveFails}</span>
          <span className='status-text'>Previous Fails</span>
        </div>
      </div>
    </div>
  );
}
export default CustomProgressBar;
