import React from 'react';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import BaseFilter from './BaseFilter';

export default class MultiSelectFilter extends BaseFilter {
  constructor(props) {
    super(props);

    this.values = this.defaultValues || [];
  }

  selectValue(v) {
    this.values.push(v);
    // this.updateValues()
  }

  updateValues() {
    this.values = super.getValues();
  }

  getValues() {
    return this.values;
  }

  clear() {
    this.values = [];
    super.clear();
  }

  render() {
    // eslint-disable-next-line
    const ds = this.getDataSource();

    const onFiltering = function (args) {
      const currentDS = this.getDataSource();
      let query = new Query();
      // frame the query based on search string with filter type.
      const vToFilter = args.text;
      query =
        // vToFilter !== "" ? query.search(vToFilter, [], "contains", true) : query
        vToFilter !== ''
          ? query.where('value', 'contains', vToFilter, true)
          : query;
      // args.text !== "" ? query.where("value", "contains", args.text, true) : query
      // pass the filter data source, filter query to updateData method.

      args.updateData(currentDS, query);
      // args.updateData(ds, query)
    };

    const cmp = (
      <MultiSelectComponent
        className={this.className}
        dataSource={this.getDataSource()}
        value={this.defaultValues}
        enablePersistence={this.enablePersistence}
        sortOrder='Ascending'
        readonly={this.readonly}
        enabled={this.enabled}
        ref={(f) => {
          this.ref = f;
        }}
        allowFiltering
        closePopupOnSelect={this.closePopupOnSelect || false}
        filtering={onFiltering.bind(this)}
        placeholder={this.placeholder}
        showDropDownIcon
        showClearButton
        select={function (e) {
          this.selectValue(e.itemData.value);
          this.onChange(this, e);
        }.bind(this)}
        removed={function (e) {
          this.updateValues();
          this.onChange(this, e);
        }.bind(this)}
        // selectedAll={function (d) {
        //   this.updateValues()
        //   console.log("select all", this.getValues())
        //   this.onChange(this)
        // }.bind(this)}
      />
    );
    return this.hasContainer ? (
      <div className={this.containerClassName} key={this.id}>
        {cmp}
      </div>
    ) : (
      cmp
    );
  }
}
