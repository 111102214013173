import { PagerComponent, Resize, Selection } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent, Inject } from '@syncfusion/ej2-react-dropdowns';
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Filter,
  GridComponent,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import React, { Fragment, useContext, useMemo, useRef } from 'react';
import { IoMdInformationCircleOutline, IoMdMenu } from 'react-icons/io';
import { IoDuplicateSharp } from 'react-icons/io5';
import {
  FINISHED,
  URL_STATUS,
  ENVIRONMENTS,
  REGIONS_DATASOURCE_UNDERSCORE,
  TEST_EXECUTIONS_PAGE_SIZE,
  CANCELLED,
  PASSED,
  FAILED,
  EDITED,
} from '../../Constants/constants';
import { isAuthRol } from '../../Utils/helpers';
import './TestExecutions.css';
import Schedule from '../UI/TestExecutionsActions/Schedule';
import Rerun from '../UI/TestExecutionsActions/Rerun';
import Delete from '../UI/TestExecutionsActions/Delete';
import See from '../UI/TestExecutionsActions/See';
import Favorites from '../UI/TestExecutionsActions/Favorites';
import Cancel from '../UI/TestExecutionsActions/Cancel';
import Edit from '../UI/TestExecutionsActions/Edit';
import { AppContext } from '../UI/contexts';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import ChildExecutions from '../UI/TestExecutionsActions/ChildExecutions';
function TestExecutionsTable({
  data,
  isScheduled,
  perPage,
  itemCount,
  isATCTable,
  editRoute,
  seeTestResultsHandler,
  addToFavoritesHandler,
  removeFromFavoritesHandler,
  cancelExecutionHandler,
  deleteSingleClickHandler,
  rerunSingleClickHandler,
  scheduleSingleClickHandler,
  refetch,
  filterVariables,
  setSelectedToSchedule,
}) {
  const grid = useRef();
  const filters = useRef(filterVariables);

  const { user } = useContext(AppContext);
  const role = isAuthRol(user.roles || []);
  const isAdmin = role === 'admin';

  // Column templates
  // function statusTemplate(props) {
  //   if (props.status === FINISHED) {
  //     if (props.passed) {
  //       return (
  //         <div className='PASSED justify-content-center d-flex'>
  //           {props.status}
  //         </div>
  //       );
  //     }
  //     return (
  //       <div className='FAILED justify-content-center d-flex'>
  //         {props.status}
  //       </div>
  //     );
  //   }
  //   return <div className='justify-content-center d-flex'>{props.status}</div>;
  // }

  function testResultsTemplate(props) {
    if (props.status === FINISHED) {
      return (
        <div
          className={`${props.executionStatus} justify-content-center d-flex`}
        >
          {props.executionStatus}
        </div>
      );
    }
    return <div className='justify-content-center d-flex'>---</div>;
  }

  function nameTemplate(props) {
    return (
      <div className='d-flex'>
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {props.name}
        </div>
        <TooltipComponent
          className='ml-auto'
          opensOn='Focus'
          target={'.att-description'}
          content={() => {
            return <div>{props.description || 'No description available'}</div>;
          }}
        >
          <button className='att-description'>
            <IoMdInformationCircleOutline
              id={props.id}
              size={15}
              className=''
              color='var(--att-primary)'
            />
          </button>
        </TooltipComponent>
      </div>
    );
  }
  const threeDotsTemplate = (data) => {
    return (
      <div
        onClick={() => {
          if (data.enabled) return data.onClick();
        }}
      >
        <span>
          {data.icon} {data.text}
        </span>
      </div>
    );
  };
  const actions = (props) => {
    const userOwnsTest = isAdmin || props.userOwnsTest;
    return (
      <div className='d-flex'>
        <div>
          {props.isScheduled && <ChildExecutions id={props.id} />}
          <See
            testId={props.id}
            testStatus={props.status}
            seeTestResultsHandler={seeTestResultsHandler}
          />
          <Favorites
            testId={props.id}
            isFavorite={props.isFavourite}
            userOwnsTest={isAdmin || props.userOwnsTest}
            addToFavoritesHandler={addToFavoritesHandler}
            removeFromFavoritesHandler={removeFromFavoritesHandler}
          />
          <Cancel
            testId={props.id}
            testStatus={props.status}
            userOwnsTest={isAdmin || props.userOwnsTest}
            cancelExecutionHandler={cancelExecutionHandler}
          />

          <Rerun
            testId={props.id}
            testStatus={props.status}
            testName={props.name}
            rerunSingleClickHandler={rerunSingleClickHandler}
          />
        </div>
        <TooltipComponent
          opensOn='Focus'
          target={'.att-threedots'}
          content={() => {
            return (
              <ListViewComponent
                template={threeDotsTemplate}
                fields={{ text: 'Name' }}
                style={{
                  padding: '0px !important',
                }}
                dataSource={[
                  {
                    icon: (
                      <Edit
                        testId={props.id}
                        testStatus={props.status}
                        userOwnsTest={isAdmin || props.userOwnsTest}
                        editRoute={editRoute}
                        isATCTable={isATCTable}
                      />
                    ),
                    text: 'Edit',
                    enabled:
                      userOwnsTest &&
                      !isATCTable &&
                      [CANCELLED, FINISHED, PASSED, FAILED, EDITED].includes(
                        props.status
                      ),
                    onClick: () => {
                      window.location.href = `${editRoute}/${props.id}`;
                    },
                  },
                  {
                    icon: (
                      <Delete
                        testId={props.id}
                        testStatus={props.status}
                        userOwnsTest={isAdmin || props.userOwnsTest}
                        deleteSingleClickHandler={deleteSingleClickHandler}
                      />
                    ),
                    text: 'Delete',
                    enabled:
                      userOwnsTest &&
                      [CANCELLED, FINISHED, PASSED, FAILED, EDITED].includes(
                        props.status
                      ),
                    onClick: (event) => {
                      return deleteSingleClickHandler(event, props.id);
                    },
                  },
                  {
                    icon: (
                      <Schedule
                        testId={props.id}
                        testStatus={props.status}
                        userOwnsTest={isAdmin || props.userOwnsTest}
                        isATCTable={isATCTable}
                        role={role}
                        isScheduled={props.isScheduled}
                        scheduleSingleClickHandler={scheduleSingleClickHandler}
                      />
                    ),
                    text: 'Schedule',
                    enabled:
                      [FINISHED, PASSED, FAILED, CANCELLED].includes(
                        props.status
                      ) &&
                      !isATCTable &&
                      role === 'admin',
                    onClick: (event) => {
                      return scheduleSingleClickHandler(event, props.id);
                    },
                  },
                  {
                    icon: <IoDuplicateSharp className='actions-icon' />,
                    text: 'Duplicate',
                    enabled: !isATCTable,
                    onClick: (event) => {
                      window.location.href = `/trfx-run-tests-duplicate/${props.id}`;
                    },
                  },
                ]}
              ></ListViewComponent>
            );
          }}
        >
          <button className={'att-threedots ml-auto'}>
            <IoMdMenu size={20} color='#124069' className='actions-icon' />
          </button>
        </TooltipComponent>
      </div>
    );
  };

  function columnFilterHandler(event, columnId) {
    if (event.value) {
      if (columnId === 'favs_scheduled') {
        if (!isAdmin) filters.current['userId'] = user._id;
        delete filters.current['isFavourite'];
      }

      switch (event.value) {
        case 'Favorites':
          filters.current['isFavourite'] = true;
          break;
        default:
          filters.current[columnId] = event.value;
          break;
      }
    } else {
      if (columnId === 'favs_scheduled') {
        delete filters.current['userId'];
        delete filters.current['isFavourite'];
      } else delete filters.current[columnId];
    }
    refetch({
      filter: filters.current,
      page: 1,
      perPage: perPage,
    });
  }

  //Filter templates
  const envFilterTemplate = () => {
    return (
      <DropDownListComponent
        placeholder='Filter by env'
        dataSource={ENVIRONMENTS}
        fields={{ text: 'text', value: 'value' }}
        showClearButton={true}
        onChange={(event) => columnFilterHandler(event, 'env')}
      />
    );
  };
  const nameFilterTemplate = () => {
    return (
      <input
        placeholder='Filter by name'
        className='e-input e-float-input'
        onChange={(event) =>
          columnFilterHandler({ value: event.target.value }, 'nameRegEx')
        }
      />
    );
  };

  const statusFilterTemplate = () => {
    return (
      <DropDownListComponent
        placeholder='Filter by status'
        dataSource={URL_STATUS}
        showClearButton={true}
        onChange={(event) => columnFilterHandler(event, 'status')}
        popupWidth='150px'
      />
    );
  };
  const testResultsFilterTemplate = () => {
    return (
      <DropDownListComponent
        placeholder='Filter by test results'
        dataSource={['PASSED', 'FAILED']}
        showClearButton={true}
        onChange={(event) => columnFilterHandler(event, 'executionStatus')}
        popupWidth='150px'
      />
    );
  };
  const regionTemplate = () => {
    return (
      <DropDownListComponent
        placeholder='Filter by region'
        dataSource={REGIONS_DATASOURCE_UNDERSCORE}
        showClearButton={true}
        fields={{ text: 'text', value: 'value' }}
        onChange={(event) => columnFilterHandler(event, 'region')}
        onClick={(event) => columnFilterHandler(event, 'region')}
        popupWidth='200px'
      />
    );
  };

  const actionsFilterTemplate = () => {
    return (
      <DropDownListComponent
        placeholder={'Filter favorites executions'}
        dataSource={['Favorites']}
        showClearButton={true}
        onChange={(event) => {
          columnFilterHandler(event, 'favs_scheduled');
        }}
        onClick={(event) => {
          columnFilterHandler(event, 'favs_scheduled');
        }}
      />
    );
  };

  // Custom settings
  function actionBegin(args) {
    grid.current.showSpinner();
    if (args.requestType === 'sorting' && args.columnName === 'date') {
      refetch({
        sort: args.direction === 'Ascending' ? '_ID_ASC' : '_ID_DESC',
      });
    }
  }
  function sortComparer(reference, comparer) {
    const ref_date = new Date(reference);
    const comparer_date = new Date(comparer);
    if (ref_date < comparer_date) {
      return -1;
    }
    if (ref_date > comparer_date) {
      return 1;
    }
    return 0;
  }

  const filterSettings = {
    mode: 'Immediate',
    showFilterBarStatus: false,
  };

  const pageSettings = {
    pageSize: TEST_EXECUTIONS_PAGE_SIZE,
  };

  const onChangePage = (args) => {
    grid.current.showSpinner();
    refetch({
      page: args.currentPage,
      perPage: perPage,
    });
  };

  let _actionsTemplate = actions;

  const columnsDirective = useMemo(() => {
    return (
      <ColumnsDirective>
        <ColumnDirective
          field='id'
          width='100'
          headerText='Id'
          isPrimaryKey
          visible={false}
        />
        {isScheduled && (
          <ColumnDirective field='checkbox' type='checkbox' width='50' />
        )}
        <ColumnDirective
          field='executionStatus'
          width='100'
          headerText='Test Result'
          template={testResultsTemplate}
          filterTemplate={testResultsFilterTemplate}
        />
        <ColumnDirective
          field='email'
          width='100'
          headerText='User'
          allowFiltering={false}
          allowSorting={false}
        />
        <ColumnDirective
          field='env'
          width='80'
          headerText='Env'
          filterTemplate={envFilterTemplate}
          allowSorting={false}
        />
        {!isATCTable && (
          <ColumnDirective
            field='region'
            width='150'
            headerText='Region'
            filterTemplate={regionTemplate}
            allowSorting={false}
          />
        )}
        <ColumnDirective
          field='date'
          width='150'
          headerText='Date'
          allowFiltering={false}
          allowSorting={true}
          sortComparer={sortComparer}
        />
        <ColumnDirective
          field='name'
          width='150'
          headerText='Test Name'
          template={nameTemplate}
          filterTemplate={nameFilterTemplate}
          allowSorting={false}
        />
        <ColumnDirective
          field='status'
          width='100'
          headerText='Status'
          allowFiltering
          allowSorting={false}
          filterTemplate={statusFilterTemplate}
        />
        <ColumnDirective
          field='failed_total'
          width='80'
          headerText='Failed/Total'
          allowFiltering={false}
          allowSorting={false}
        />
        <ColumnDirective
          field='duration'
          width='80'
          headerText='Duration'
          allowFiltering={false}
          allowSorting={false}
        />
        <ColumnDirective
          width='200'
          minWidth='200'
          headerText='Actions'
          allowSorting={false}
          allowFiltering={false}
          template={_actionsTemplate}
          filterTemplate={actionsFilterTemplate}
        />
      </ColumnsDirective>
    );
  }, []);

  const load = (args) => {
    args.requireTemplateRef = false;
  };
  const onRowSelected = (e) => {
    if (e.data === undefined) return;

    let idList = [];
    if (Array.isArray(e.data)) {
      for (const row of e.data) {
        if (row !== undefined) idList.push(row.id);
      }
    } else {
      idList.push(e.data.id);
    }
    setSelectedToSchedule((prev) => {
      const concatenatedArray = prev.concat(idList);
      const uniqueSet = new Set(concatenatedArray);
      return [...uniqueSet];
    });
  };
  const onRowDeselected = (e) => {
    if (e.data === undefined) return;
    let idList = [];
    if (Array.isArray(e.data)) {
      for (const row of e.data) {
        if (row !== undefined) idList.push(row.id);
      }
    } else {
      idList.push(e.data.id);
    }
    setSelectedToSchedule((prev) => prev.filter((id) => !idList.includes(id)));
  };

  return (
    <Fragment>
      <GridComponent
        ref={grid}
        load={load}
        dataSource={data}
        allowSorting={true}
        allowResizing={true}
        allowPaging={true}
        pageSettings={pageSettings}
        filterSettings={filterSettings}
        allowFiltering={true}
        allowSelection={true}
        selectionSettings={{
          checkboxOnly: true,
          persistSelection: true,
        }}
        rowSelected={onRowSelected}
        rowDeselected={onRowDeselected}
        actionBegin={(args) => actionBegin(args)}
        loadingIndicator={{ indicatorType: 'Shimmer' }}
      >
        {columnsDirective}
        <Inject services={[Filter, Sort, CommandColumn, Resize, Selection]} />
      </GridComponent>
      {itemCount > 0 && (
        <PagerComponent
          click={(args) => onChangePage(args)}
          pageSize={perPage}
          totalRecordsCount={itemCount}
          pageCount={10}
        />
      )}
    </Fragment>
  );
}
export default TestExecutionsTable;
