import React from 'react';
import { MdCancel } from 'react-icons/md';
import {
  RUNNING,
  PENDING,
  ON_HOLD,
  PASSED,
  FAILED,
} from '../../../Constants/constants';
import '../../TestExecutions/TestExecutions.css';

export default function Cancel({
  testId,
  testStatus,
  userOwnsTest,
  cancelExecutionHandler,
}) {
  const enable =
    userOwnsTest &&
    [RUNNING, PENDING, ON_HOLD, PASSED, FAILED].includes(testStatus);
  return enable ? (
    <MdCancel
      title='cancel test execution'
      className='actions-icon'
      onClick={() => cancelExecutionHandler(testId)}
    />
  ) : (
    <MdCancel className='actions-icon-disabled' />
  );
}
