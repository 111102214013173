export const getOptions = (values) => {
  return values.map((el) => {
    return { value: el, label: el };
  });
};

export const getTests = (status, array) => {
  let count = 0;
  array.forEach((element) => {
    if (element.status === status) count += 1;
  });
  return count;
};

export const getImgSrc = (screenshots) => {
  return screenshots && screenshots.length > 0 ? screenshots[0].url : null;
};
function pad(val) {
  return val > 9 ? val : `0${val}`;
}

export const getFormattedDuration = (seconds) => {
  const hours = pad(Math.floor(seconds / 3600));
  const minutes = pad(Math.floor((seconds / 60) % 60));
  const _seconds = pad(Math.floor(seconds % 60));

  return `${hours}:${minutes}:${_seconds}`;
};

const fitSingleFilter = (filter, value) => {
  return !filter || filter.length === 0 || filter.includes(value);
};

export const fitFilters = (filters, context, status, components) => {
  if (context === null) return false;

  if (!fitSingleFilter(filters.application, context.application)) return false;

  if (!fitSingleFilter(filters.client, context.client)) return false;

  if (!fitSingleFilter(filters.template, context.template)) return false;

  if (!fitSingleFilter(filters.status, status)) return false;

  return UrlShouldRender(components, filters);
};

const UrlShouldRender = (components, filters) => {
  if (!filters.component || filters.component.length === 0) return true;

  for (const comp of components) {
    if (componentShouldRender(comp, filters)) return true;
  }
  return false;
};

export const componentShouldRender = (component, filters) => {
  return fitSingleFilter(filters.component, component.name);

  // if (fitSingleFilter(filters.status, component.status))
  //   return true

  // for (const testCase of component.it) {
  //   if (testCaseShouldRender(testCase, filters))
  //     return true
  // }
  // return false
};

export const testCaseShouldRender = (testCase, filters) => {
  if (fitSingleFilter(filters.status, testCase.status)) return true;

  for (const step of testCase.it) {
    if (testStepShouldRender(step, filters)) return true;
  }
  return false;
};

export const testStepShouldRender = (step, filters) => {
  return fitSingleFilter(filters.status, step.status);
};

export const getFilterOptions = (testResults) => {
  const components = [];
  const clients = [];
  const templates = [];
  testResults?.forEach((t) => {
    if (!clients.includes(t.context?.client)) clients.push(t.context?.client);

    if (!templates.includes(t.context?.template))
      templates.push(t.context?.template);

    t.flattenedResults.forEach((component) => {
      if (!components.includes(component.name)) components.push(component.name);
    });
  });

  return { components, clients, templates };
};
