import React, { useEffect, useRef, useState } from 'react';
import './Flash.css';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import Bus from '../../../Utils/Bus';

function Flash() {
  const [visibility, setVisibility] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  const toastInstance = useRef(null);

  function toastCreated() {
    toastInstance.current.show();
  }

  useEffect(() => {
    Bus.addListener('flash', ({ message, type }) => {
      setVisibility(true);
      setMessage(message);
      setType(type);
    });
  }, []);

  const typeClass = {
    success: 'e-toast-success',
    error: 'e-toast-error',
    info: 'e-toast-info',
  };

  const toast = (
    <div className='d-flex justify-content-center'>
      <ToastComponent
        className='d-flex justify-content-center'
        ref={toastInstance}
        created={toastCreated}
        timeOut={5000}
        position={{ X: 'Center', Y: 'Top' }}
        beforeClose={() => setVisibility(false)}
        cssClass={typeClass[type]}
        style={{ textAlign: 'center' }}
      >
        {message}
      </ToastComponent>
    </div>
  );
  return visibility && toast;
}
export default Flash;
