import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { FormValidator } from '@syncfusion/ej2-react-inputs';
import { useQuery, useMutation } from '@apollo/client';
import { Query } from '@syncfusion/ej2-data';
import { GET_URLS_DATA } from '../../../GraphQL/ManageUrls/TRFX/Queries';
import { EDIT_URL_FROM_CONTEXT } from '../../../GraphQL/ManageUrls/TRFX/Mutations';

function EditUrlForm() {
  const errorHandler = (error) => {
    window.flash(error, 'error');
  };
  const navigate = useNavigate();
  const [newUrl, setNewUrl] = useState('');
  const [ddlObj, setDdlObj] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');
  const [urlContextId, setUrlContextId] = useState('');
  const [urlDataSource, setUrlDataSource] = useState([]);
  const [runMutation] = useMutation(EDIT_URL_FROM_CONTEXT);

  useQuery(GET_URLS_DATA, {
    onCompleted: (data) => {
      if (data.contextGetAllUrls !== null) {
        setUrlDataSource(data.contextGetAllUrls);
      } else {
        errorHandler('No urls defined');
      }
    },
    onError: (error) => errorHandler(error.message),
  });

  const options = {
    rules: {
      newUrlInput: {
        required: [true, '*This field is required'],
      },
      urlDropdownList: {
        required: [true, ''],
      },
    },
  };
  const formObject = new FormValidator('#editUrlForm', options);

  const urlFields = { text: 'name', value: 'id' };
  const onSubmit = (event) => {
    event.preventDefault();
    if (!formObject.validate()) {
      ddlObj.inputWrapper.container.classList.add('e-error');
      return;
    }
    ddlObj.inputWrapper.container.classList.remove('e-error');
    runMutation({
      variables: {
        contextId: urlContextId,
        toDeleteUrl: currentUrl,
        toUpdateUrl: newUrl,
      },
    })
      .then((r) => {
        const response = r.data.contextSaveEditedUrl;
        if (response === null) {
          window.hideMessage();
          window.flash('Context not found. Url cant be updated', 'error');
        } else {
          window.hideMessage();
          window.flash('Url successfully updated.', 'success');
          formObject.element.reset();
          navigate('/edit-url');
        }
      })
      .catch((error) => {
        window.hideMessage();
        window.flash('An error occurred. Url cant be updated', 'error');
      });
  };

  const urlsDropDownOnchange = (event) => {
    ddlObj.inputWrapper.container.classList.remove('e-error');
    setCurrentUrl(event.target.text);
    setUrlContextId(event.target.value);
  };

  const onFiltering = function (args) {
    const currentDS = urlDataSource;
    let query = new Query();
    // frame the query based on search string with filter type.
    const vToFilter = args.text;
    query =
      // vToFilter !== "" ? query.search(vToFilter, [], "contains", true) : query
      vToFilter !== ''
        ? query.where('name', 'contains', vToFilter, true)
        : query;
    // pass the filter data source, filter query to updateData method.

    args.updateData(currentDS, query);
  };

  return (
    <div className='d-flex justify-content-center'>
      <div className='e-card' style={{ width: '70%' }}>
        <div className='e-card-header mt-2' style={{ width: '100%' }}>
          <div className='e-card-header-caption' />
        </div>
        <form
          onSubmit={(event) => onSubmit(event)}
          className='mr-3 ml-3'
          id='editUrlForm'
        >
          <div className='form-group'>
            <DropDownListComponent
              id='url-dropdown'
              name='urlDropdownList'
              width='50%'
              dataSource={urlDataSource}
              placeholder='Urls'
              fields={urlFields}
              onChange={urlsDropDownOnchange}
              ref={(scope) => {
                setDdlObj(scope);
              }}
              allowFiltering
              filtering={onFiltering.bind(this)}
            />
          </div>
          <div className='mb-3 mt-3 e-float-input form-group'>
            <input
              type='text'
              name='newUrlInput'
              style={{ width: '50%' }}
              placeholder='Set new url'
              onChange={(event) => setNewUrl(event.target.value)}
            />
          </div>
          <div className='d-flex justify-content-center mt-5 form-group'>
            <ButtonComponent
              onClick={() => (window.location.href = '/trfx-test-executions')}
              className='e-control e-btn e-lib e-outline mr-1'
              iconCss='e-icons e-close'
              type='button'
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              type='submit'
              className='e-control e-btn e-lib e-custom btn-primary'
              iconCss='e-icons e-check'
            >
              Accept
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditUrlForm;
