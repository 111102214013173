import { gql } from '@apollo/client';

export const GET_ALL_URLS = gql`
  query GetAllUrls(
    $env: EnumContextEnv
    $limit: Int
    $application: EnumContextApplication
  ) {
    contextFindMany(
      filter: { env: $env, application: $application }
      limit: $limit
    ) {
      _id
      client
      template
      siteEdition
      application
      components {
        name
        version
      }
      urls
      customerName
    }
  }
`;

export const GET_ALL_COMPONENTS = gql`
  query GetComponents {
    contextGetAllComponents
  }
`;

export const GET_IMPLEMENTED_COMPONENTS = gql`
  query GetComponents {
    implementedWCFindMany {
      name
      implementedClients
      application
      publisher
    }
  }
`;

export const GET_ALL_CONTEXTS = gql`
  query GetContexts {
    getAllContexts(env: "dev") {
      _id
      env
      client
      template
      siteEdition
      components {
        name
        version
      }
      urls
    }
  }
`;

export const GET_CONTEXT_ID = gql`
  query GetContextId(
    $env: EnumContextEnv
    $client: String!
    $template: String!
    $siteEdition: String!
  ) {
    contextFindOne(
      filter: {
        env: $env
        client: $client
        template: $template
        siteEdition: $siteEdition
      }
    ) {
      env
      client
      template
      siteEdition
      urls
      _id
    }
  }
`;

export const GET_ALL_TEMPLATES = gql`
  query getAllTemplates {
    contextGetAllTemplates
  }
`;

export const GET_CONTEXTS = gql`
  query ContextFindMany(
    $env: EnumContextEnv
    $client: String
    $template: String
    $siteEdition: String
  ) {
    contextFindMany(
      filter: {
        env: $env
        client: $client
        template: $template
        siteEdition: $siteEdition
      }
      limit: 1000
    ) {
      env
      client
      template
      siteEdition
      urls
    }
  }
`;

export const GET_URLS_DATA = gql`
  query getAllUrls {
    contextGetAllUrls
  }
`;

export const DELETE_URL_FROM_ALL_CONTEXTS = gql`
  query Query($env: String!, $url: String!) {
    contextDeleteUrlFromAllContexts(env: $env, url: $url)
  }
`;
