import React from 'react';
import { MdEdit } from 'react-icons/md';
import {
  CANCELLED,
  EDITED,
  FAILED,
  FINISHED,
  PASSED,
} from '../../../Constants/constants';
import '../../TestExecutions/TestExecutions.css';

export default function Edit({
  testId,
  testStatus,
  userOwnsTest,
  isATCTable,
  editRoute,
}) {
  const enable =
    userOwnsTest &&
    !isATCTable &&
    [CANCELLED, FINISHED, PASSED, FAILED, EDITED].includes(testStatus);

  return enable ? (
    <a href={`/${editRoute}/${testId}`}>
      <MdEdit className='actions-icon' title='edit test execution' />
    </a>
  ) : (
    <MdEdit className='actions-icon-disabled' />
  );
}
