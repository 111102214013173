import React from 'react';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
// import { Query } from '@syncfusion/ej2-data';
import BaseFilter from './BaseFilter';

export default class CheckboxFilter extends BaseFilter {
  updateValues(event) {
    this.values = [event.target.checked];
  }

  getValues() {
    return this.values;
  }

  render() {
    const cmp = (
      <CheckBoxComponent
        label='Tracking'
        onChange={function (e) {
          this.updateValues(e);
          this.onChange(this, e);
        }.bind(this)}
      />
    );
    return this.hasContainer ? (
      <div key={this.id} className={this.containerClassName}>
        {cmp}
      </div>
    ) : (
      cmp
    );
  }
}
