import React from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import './ScreenshotsAndExtraFiles.css';
import { isEmpty } from '../../../Utils/helpers';

function ScreenshotsAndExtraFiles({
  screenshots,
  extraFiles,
  onClickScreenshots,
}) {
  screenshots = screenshots || [];
  extraFiles = extraFiles || [];

  const popover = () => {
    return (
      <div className='extra-files-popover'>
        <span className='extra-files-popover-title'>Extra Files</span>
        {extraFiles.map((ef, index) => (
          <a key={ef.file.url} href={ef.file.url} download>
            {ef.file.fileName}
          </a>
        ))}
      </div>
    );
  };

  return (
    <div className='inline-flex-container extra-files-container'>
      {!isEmpty(screenshots) && (
        <span
          className='e-icons e-image'
          onClick={(e) => {
            return onClickScreenshots(screenshots[0].url, e);
          }}
        />
      )}
      {!isEmpty(extraFiles) && (
        <TooltipComponent
          opensOn='Click'
          content={popover}
          isSticky
          target='.extra-files-icon'
        >
          <span className='e-icons e-folder extra-files-icon' />
        </TooltipComponent>
      )}
    </div>
  );
}
export default ScreenshotsAndExtraFiles;
