import FilterDataHandler from './FilterDataHandler';
import { isEmpty, isFunction } from '../../../Utils/helpers';

export default class TestResultsFilterDataHandler extends FilterDataHandler {
  filterDS(ds, values) {
    if (isEmpty(values)) {
      return ds;
    }

    if (isFunction(this.customFilterDS)) {
      return this.customFilterDS(ds, values);
    }

    let results = [];

    if (isFunction(this.filterFn)) {
      results = (ds || []).filter((v) => this.filterFn({ values, current: v }));
    }

    if (isFunction(this.project)) {
      results = this.project(results);
    }

    if (isFunction(this.sort)) {
      results = this.sort(results);
    }

    return results;
  }
}
