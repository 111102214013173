import { gql } from '@apollo/client';

export const GET_COMPONENTS = gql`
  query GetComponents {
    implementedWCFindMany {
      name
      _id
    }
  }
`;

export const GET_BASE_SCOPES_PARAMETERS = gql`
  query GetBaseScopesParameters($component: String!) {
    testDefinitionFindOne(
      filter: { isBaseScope: true, component: $component }
    ) {
      _id
      component
      scope
      parameters {
        name
        value
      }
    }
  }
`;

export const GET_CUSTOM_SCOPES = gql`
  query GetCustomScopes($component: String!) {
    testDefinitionFindMany(
      filter: { isBaseScope: false, component: $component }
      limit: 10000
    ) {
      _id
      name
    }
  }
`;

export const GET_CUSTOM_SCOPES_PARAMETERS = gql`
  query GetCustomScopesParameters($component: String!, $customScope: MongoID!) {
    testDefinitionFindOne(
      filter: { isBaseScope: false, component: $component, _id: $customScope }
    ) {
      _id
      component
      scope
      parameters {
        name
        value
      }
    }
  }
`;

export const GET_PROCESSING_SCOPES = gql`
  query TrfxCodelessOperationFindMany {
    trfxCodelessOperationFindMany {
      _id
      toProcess
      status
      operation
      implementedWC {
        name
      }
      name
      testDefinition {
        name
      }
    }
  }
`;
