import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import React from 'react';
import BaseFilter from './BaseFilter';

export default class DropdownFilter extends BaseFilter {
  render() {
    // console.log("dropdown", this.defaultValues)
    const cmp = (
      <DropDownListComponent
        className={this.className}
        dataSource={this.getDataSource()}
        value={
          Array.isArray(this.defaultValues)
            ? this.defaultValues[0]
            : this.defaultValues
        }
        sortOrder='Ascending'
        readonly={this.readonly}
        enabled={this.enabled}
        ref={(f) => {
          this.ref = f;
        }}
        placeholder={this.placeholder}
        onChange={function () {
          this.onChange(this);
        }.bind(this)}
      />
    );
    return this.hasContainer ? (
      <div key={this.id} className={this.containerClassName}>
        {cmp}
      </div>
    ) : (
      cmp
    );
  }
}
