import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useLazyQuery, useQuery } from '@apollo/client';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { FormValidator } from '@syncfusion/ej2-react-inputs';
import { ENVIRONMENTS } from '../../../Constants/constants';
import {
  GET_CONTEXTS,
  DELETE_URL_FROM_ALL_CONTEXTS,
} from '../../../GraphQL/ManageUrls/TRFX/Queries';

let formObject;
function DeleteUrl() {
  const [env, setEnvironment] = useState('dev');
  const [url, setUrl] = useState('');

  const [urls, setUrls] = useState([]);

  useEffect(() => {
    const options = {
      rules: {
        env: {
          required: [true, '*Please select an environment'],
        },
        url: {
          required: [true, '*Please select an url'],
          regex: ['.', '*Please match the requested format'],
        },
      },
    };
    formObject = new FormValidator('#deleteUrlForm', options);
  }, []);

  const { refetch } = useQuery(GET_CONTEXTS, {
    notifyOnNetworkStatusChange: true,
    variables: { env },
    onCompleted: (data) => {
      const rawUrls = data.contextFindMany.reduce((acc, c) => {
        const temp = [...acc];
        for (const u of c.urls) {
          if (!acc.includes(u)) temp.push(u);
        }
        return temp;
      }, []);

      setUrls(rawUrls.sort());
    },
  });

  useEffect(() => {
    refetch();
  }, [env]);

  const [deleteUrl] = useLazyQuery(DELETE_URL_FROM_ALL_CONTEXTS, {
    onCompleted: () => {
      // Reset state
      window.hideMessage();
      setUrl('');
      window.flash('Url was deleted successfully.', 'success');
    },
    onError: (error) => {
      window.hideMessage();
      window.flash(`Delete url failed. Error: ${error.message}`, 'error');
    },
  });

  const onSubmit = (event) => {
    event.preventDefault();

    // Form validation
    if (!formObject.validate()) {
      window.hideMessage();
      return;
    }
    window.showMessage('Loading', 'loading');
    formObject.element.reset();

    deleteUrl({
      variables: {
        env,
        url,
      },
    });
  };

  return (
    <div className='d-flex justify-content-center'>
      <div className='e-card' style={{ width: '50%' }}>
        <div className='ml-auto'>
          <a
            href='/trfx-run-tests'
            style={{ textDecoration: 'none', color: 'gray' }}
          >
            <IoMdClose size='20' className='mt-2 mr-2' />
          </a>
        </div>
        <div className='e-card-header mt-2' style={{ width: '100%' }}>
          <div className='e-card-header-caption'>
            <div className='e-card-header-title  d-flex justify-content-center'>
              <h5>Delete url from context</h5>
            </div>
          </div>
        </div>
        <div className='e-card-content'>
          <form
            noValidate
            id='deleteUrlForm'
            onSubmit={(event) => onSubmit(event)}
          >
            <div className='d-flex' style={{ columnGap: '18px' }}>
              <div className='form-group' style={{ width: '50%' }}>
                <div className='e-float-input'>
                  <DropDownListComponent
                    name='env'
                    dataSource={ENVIRONMENTS}
                    fields={{ text: 'text', value: 'value' }}
                    onChange={(event) => setEnvironment(event.target.value)}
                    placeholder='Select environment'
                    value={env}
                  />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='e-float-input'>
                <DropDownListComponent
                  dataSource={urls}
                  allowFiltering
                  filterType='contains'
                  placeholder='Url'
                  value={url}
                  onChange={(event) => setUrl(event.target.value)}
                  required
                  name='url'
                />
              </div>
            </div>
            <div className='d-flex justify-content-center mt-5'>
              <ButtonComponent
                onClick={() => (window.location.href = '/trfx-run-tests')}
                className='e-control e-btn e-lib e-outline mr-1'
                iconCss='e-icons e-close'
                type='button'
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                type='submit'
                className='e-control e-btn e-lib e-custom btn-primary'
                iconCss='e-icons e-check'
              >
                Accept
              </ButtonComponent>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DeleteUrl;
