import React, { useState } from 'react';
import {
  MultiSelectComponent,
  DropDownListComponent,
} from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CUSTOMERS_DATA } from '../../../GraphQL/TestExecutions/TRFX/Queries';
import { GET_IMPLEMENTED_COMPONENTS } from '../../../GraphQL/ManageUrls/TRFX/Queries';
import { UPDATE_IMPLEMENTED_WC_CLIENTS } from '../../../GraphQL/TestExecutions/TRFX/Mutations';

function ClientComponentForm() {
  const errorHandler = (error) => {
    window.flash(error, 'error');
  };
  const [runMutation] = useMutation(UPDATE_IMPLEMENTED_WC_CLIENTS);
  const [customerIds, setCustomerIds] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState('');
  const [selectedPublisher, setSelectedPublisher] = useState('');
  const [components, setComponents] = useState([]);
  const [implementedCmpCustomers, SetImplementedCmpCustomers] = useState({});
  const [options, setOptions] = useState([]);
  const [immutableOptions, setImmutableOptionsOptions] = useState([]);
  const implementedWcApplication = ['TRFX'];
  const [selectedApplication, setSelectedApplication] = useState('TRFX');
  const implementedWcPublisher = [
    { key: 'vg', name: 'VG' },
    { key: 'airmodules', name: 'AIRMODULES' },
    { key: 'trfx', name: 'TRFX' },
  ];
  const publisherFields = { text: 'name', value: 'key' };
  useQuery(GET_CUSTOMERS_DATA, {
    onCompleted: (data) => {
      if (data.customerFindMany !== null) {
        const customerFindMany = data.customerFindMany.reduce(function (
          acc,
          customer
        ) {
          acc.push({ key: customer.code, name: customer.name });
          return acc;
        },
          []);
        setOptions(customerFindMany);
        setImmutableOptionsOptions(customerFindMany);
      } else {
        errorHandler('No customers defined');
      }
    },
    onError: (error) => errorHandler(error.message),
  });
  useQuery(GET_IMPLEMENTED_COMPONENTS, {
    onCompleted: (data) => {
      if (data.implementedWCFindMany !== null) {
        setComponents((data.implementedWCFindMany || []).map((d) => d.name));
        SetImplementedCmpCustomers(
          data.implementedWCFindMany.reduce(function (acc, implementedWc) {
            acc[
              `${implementedWc?.name}${implementedWc?.application}${implementedWc?.publisher}`
            ] = implementedWc.implementedClients;
            return acc;
          }, {})
        );
      } else {
        errorHandler('No customers defined');
      }
    },
    onError: (error) => errorHandler(error.message),
  });
  const fields = { text: 'name', value: 'key' };
  const onSubmit = (event) => {
    event.preventDefault();
    // alert(
    //   `${customerIds}, ${selectedComponent}, ${selectedApplication}, ${selectedPublisher}`
    // );
    if (
      !customerIds ||
      !selectedComponent ||
      !selectedApplication ||
      !selectedPublisher
    ) {
      errorHandler('Complete all fields!!!');
      return;
    }
    runMutation({
      variables: {
        component: selectedComponent,
        application: selectedApplication,
        publisher: selectedPublisher,
        clients: customerIds,
      },
    })
      .then((r) => {
        const response = r.data.implementedWCUpdateOneImplementedClients;
        if (response === null) {
          window.hideMessage();
          window.flash('Component not found. Customers cant be added', 'error');
        } else {
          window.hideMessage();
          window.flash(
            `Customers successfully added to ${selectedComponent}`,
            'success'
          );
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      })
      .catch((error) => {
        window.hideMessage();
        window.flash('An error occurred. Customers cant be added', 'error');
      });
  };
  const selectOnchange = (event) => {
    setCustomerIds(event.value);
  };
  const onchangeComponent = (event) => {
    setSelectedComponent(event.value);
    if (selectedApplication && selectedPublisher) {
      const cmpCustomers =
        implementedCmpCustomers[
        `${event.value}${selectedApplication}${selectedPublisher}`
        ];
      if (cmpCustomers !== undefined) {
        setOptions(
          options.reduce(function (acc, customer) {
            if (!cmpCustomers.includes(customer.key)) {
              acc.push({ key: customer.key, name: customer.name });
            }
            return acc;
          }, [])
        );
      } else setOptions(immutableOptions);
    } else setOptions(immutableOptions);
  };
  const onchangeApplication = (event) => {
    setSelectedApplication(event.value);
  };
  const onchangePublisher = (event) => {
    setSelectedPublisher(event.value);
    if (selectedApplication && selectedComponent) {
      const cmpCustomers =
        implementedCmpCustomers[
        `${selectedComponent}${selectedApplication}${event.value}`
        ];
      if (cmpCustomers !== undefined) {
        setOptions(
          options.reduce(function (acc, customer) {
            if (!cmpCustomers.includes(customer.key)) {
              acc.push({ key: customer.key, name: customer.name });
            }
            return acc;
          }, [])
        );
      } else setOptions(immutableOptions);
    } else setOptions(immutableOptions);
  };
  return (
    <div className='d-flex justify-content-center'>
      <div className='e-card' style={{ width: '70%' }}>
        <div className='e-card-header mt-2' style={{ width: '100%' }}>
          <div className='e-card-header-caption' />
        </div>
        <form onSubmit={(event) => onSubmit(event)} className='mr-3 ml-3'>
          <div className='mb-3 form-group'>
            <DropDownListComponent
              id='ss-component'
              width='25%'
              dataSource={components}
              placeholder='Component'
              onChange={onchangeComponent}
            />
          </div>
          <div className='form-group'>
            <DropDownListComponent
              id='ss-application'
              width='25%'
              dataSource={implementedWcApplication}
              placeholder='Application'
              onChange={onchangeApplication}
              value='TRFX'
            />
            {'   '}
            <DropDownListComponent
              id='ss-publisher'
              width='25%'
              dataSource={implementedWcPublisher}
              placeholder='Publisher'
              fields={publisherFields}
              onChange={onchangePublisher}
            />
          </div>
          <div className='form-group'>
            <MultiSelectComponent
              id='ms-customer'
              dataSource={options}
              placeholder='Select customers'
              fields={fields}
              changeOnBlur={false}
              onChange={selectOnchange}
              width='50%'
              allowFiltering
            />
          </div>
          <div className='d-flex justify-content-center mt-5 form-group'>
            <ButtonComponent
              onClick={() => (window.location.href = '/trfx-test-executions')}
              className='e-control e-btn e-lib e-outline mr-1'
              iconCss='e-icons e-close'
              type='button'
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              type='submit'
              className='e-control e-btn e-lib e-custom btn-primary'
              iconCss='e-icons e-check'
            >
              Accept
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ClientComponentForm;
