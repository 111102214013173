export default class BaseFilter {
  constructor({ filterConfig, dataHandler }) {
    // console.log({ filterConfig })
    this.priority = filterConfig.priority;
    this.className = filterConfig.className;
    this.onChange = filterConfig.onChange;
    this.placeholder = filterConfig.placeholder || '';
    this.defaultValues = filterConfig.defaultValues || [];
    this.styles = filterConfig.styles;
    this.ref = undefined;
    this.readOnly = filterConfig.readOnly || false;
    this.enabled = filterConfig.enabled || true;
    this.hasContainer = filterConfig.hasContainer || true;
    this.containerClassName = filterConfig.containerClassName || 'col-md4';
    this.enablePersistence = filterConfig.enablePersistence || true;
    this.closePopupOnSelect = filterConfig.closePopupOnSelect || false;

    this.layout = filterConfig.layout;

    this.dataHandler = dataHandler;
    this.field = this.dataHandler.field;
    this.id = filterConfig.id || `${this.field}-${Math.random() * 1000}`;
  }

  getId() {
    return `${
      this.dataHandler.field || (Math.random() * 1000).toString()
    }-filter`;
  }

  getValues() {
    if (this.ref) {
      return this.ref.value;
    }
  }

  saveExtraDataForFiltering(extraData) {
    this.extraData = extraData;
  }

  updateDataSource(ds, extraData = {}) {
    this.dataHandler.updateDataSource(ds, this.extraData || extraData);
  }

  updateDefaultValues(values) {
    this.defaultValues = values || this.getValues();
  }

  getDataSource() {
    return this.dataHandler.dataSource;
  }

  clear() {
    this.defaultValues = [];
    if (this.ref) {
      this.ref.value = [];
    }
  }

  filterDS(ds) {
    const values = this.getValues();
    return this.dataHandler.filterDS(ds, values);
  }

  render() {
    throw new Error('Not implemented method');
  }
}
