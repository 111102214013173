import { gql } from '@apollo/client';

export const ATC_EXECUTE_TEST = gql`
  mutation atcExecuteTest(
    $name: String!
    $description: String
    $featureIds: [ID!]!
    $attEnv: String!
    $env: String
    $userId: ID!
  ) {
    atcTestExecutionExecuteTest(
      name: $name
      description: $description
      featureIds: $featureIds
      userId: $userId
      attEnv: $attEnv
      env: $env
    ) {
      name
      attEnv
      env
      status
    }
  }
`;

export const EDIT_TEST_EXECUTION = gql`
  mutation SaveEditExecution(
    $teId: ID!
    $contexts: [ExecuteTestsContextsInput]!
    $filters: JSON
    $name: String
    $description: String
    $env: String!
    $smokeTest: Boolean!
    $region: String!
    $includeTrackingValidations: Boolean
  ) {
    testsExecutionUpdateSavedTestsExecution(
      teId: $teId
      values: {
        contexts: $contexts
        name: $name
        description: $description
        env: $env
        smokeTest: $smokeTest
        region: $region
        filters: $filters
        includeTrackingValidations: $includeTrackingValidations
      }
    ) {
      duration
      start
      status
      executionStatus
      name
      description
      attEnv
      _id
    }
  }
`;
