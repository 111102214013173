import { useParams } from 'react-router-dom';

import React from 'react';
import TestResults from '../TestResults';
import { CANCEL_EXECUTION } from '../../../GraphQL/TestExecutions/TRFX/Mutations';
import {
  GET_DURATION,
  GET_RESULTS_BY_ID,
  GET_URL_TESTS_IDS,
  GET_REGION_AND_NAME,
  GET_CONSECUTIVE_FAILED_EXECUTIONS,
} from '../../../GraphQL/TestResults/TRFX/Queries';

function DataCollector() {
  const { id } = useParams();

  const filterConfigs = [
    {
      type: 'multiSelect',
      id: 'application-filter',
      containerClassName: 'col-md-4 col-sm-12',
      placeholder: 'Select an application',
      field: 'context.application',
      priority: -1,
      closePopupOnSelect: true,
    },
    {
      type: 'multiSelect',
      id: 'client-filter',
      containerClassName: 'col-md-8 col-sm-12',
      placeholder: 'Select clients',
      field: 'context.client',
      priority: -2,
      closePopupOnSelect: true,
    },
    {
      type: 'multiSelect',
      id: 'template-filter',
      placeholder: 'Select templates',
      containerClassName: 'col-md-8 col-sm-12',
      field: 'context.template',
      priority: -3,
      closePopupOnSelect: true,
    },
    {
      type: 'multiSelect',
      id: 'se-filter',
      placeholder: 'Select siteEditions',
      containerClassName: 'col-md-4 col-sm-12',
      field: 'context.siteEdition',
      priority: -4,
      closePopupOnSelect: true,
    },
    {
      type: 'multiSelect',
      id: 'components-filter',
      containerClassName: 'col-md-8 col-sm-12',
      placeholder: 'Select components',
      field: 'components',
      priority: -5,
      closePopupOnSelect: true,
    },
    {
      type: 'multiSelect',
      id: 'url-status-filter',
      containerClassName: 'col-md-4 col-sm-12',
      placeholder: 'Select url status',
      field: 'status',
      priority: -6,
      closePopupOnSelect: true,
    },
    {
      type: 'checkbox',
      id: 'tracking-checkbox-filter',
      containerClassName: 'col-md-4 col-sm-12',
      field: 'tracking',
      priority: -7,
    },
  ];

  return (
    <TestResults
      id={id}
      queryGetResultsById={GET_RESULTS_BY_ID}
      queryGetDuration={GET_DURATION}
      queryGetUrlTestsIds={GET_URL_TESTS_IDS}
      queryGetRegionAndName={GET_REGION_AND_NAME}
      queryGetFailedExecutions={GET_CONSECUTIVE_FAILED_EXECUTIONS}
      onCancelRedirectUrl='/trfx-test-executions'
      cancelMutation={CANCEL_EXECUTION}
      getTestResultsQueryName='urlTestsFindMany'
      getDurationQueryName='testsExecutionFindById'
      getNameAndRegionQueryName='testsExecutionFindById'
      dsProviderId='TestResults'
      filterConfigs={filterConfigs}
      typeName='UrlTestSuite'
    />
  );
}

export default DataCollector;
