export const isFunction = (v) => typeof v === 'function';

export const isEmpty = (v) => {
  if (v === undefined || v === null) {
    return true;
  }

  if (Array.isArray(v)) {
    return v.length === 0;
  }
  if (typeof v === 'string') {
    return v === '';
  }
  if (typeof v === 'object') {
    return Object.entries(v).length === 0;
  }

  return true;
};

export const isString = (v) => typeof v === 'string';

export const get = (obj, path, defValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value
  const result = pathArray.reduce((prevObj, key) => {
    return prevObj && prevObj[key];
  }, obj);
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
};

export function isSameData(data1, data2) {
  if (!data1 && !data2) return true;

  if (!data1 || !data2) return false;

  if (data1.length !== data2.length) return false;

  data1.sort((a, b) => a.id - b.id);
  data2.sort((a, b) => a.id - b.id);
  for (let i = 0; i < data1.length; i++) {
    if (data1[i].id !== data2[i].id) return false;

    const prevFavStatus = data1[i].isFavourite;
    const nextFavStatus = data2[i].isFavourite;

    if (prevFavStatus !== nextFavStatus) return false;

    const prevStatus = data1[i].status;
    const nextStatus = data2[i].status;

    if (prevStatus !== nextStatus) return false;
  }
  return true;
}

export function isAuthRol(roles) {
  let isUser = roles.filter((val) => val.name === 'user');
  let isAdmin = roles.filter((val) => val.name === 'admin');
  let isDeveloper = roles.filter((val) => val.name === 'developer');
  if (isUser.length > 0) {
    return 'user';
  } else if (isAdmin.length > 0) {
    return 'admin';
  } else if (isDeveloper.length > 0) {
    return 'admin';
  } else {
    return 'user';
  }
}

export function isATC(type) {
  return type === 'ATC' ? true : false;
}

export function getDate(date) {
  return (
    (date.getDate() < 10 ? '0' : '') +
    date.getDate() +
    '/' +
    (date.getMonth() + 1 < 10 ? '0' : '') +
    (date.getMonth() + 1) +
    '/' +
    date.getFullYear()
  );
}
export function getTime(date) {
  return (
    (date.getHours() < 10 ? '0' : '') +
    date.getHours() +
    ':' +
    (date.getMinutes() < 10 ? '0' : '') +
    date.getMinutes() +
    ':' +
    (date.getSeconds() < 10 ? '0' : '') +
    date.getSeconds()
  );
}
