// import React from "react"
import './Spinner.css';

import { createSpinner, showSpinner } from '@syncfusion/ej2-popups';
import React from 'react';

export default class LoadingSpinner extends React.Component {
  componentDidMount() {
    // createSpinner() method is used to create spinner
    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById('container'),
    });

    // showSpinner() will make the spinner visible
    showSpinner(document.getElementById('container'));
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className='control-pane'>
          <div
            id='container'
            className='control-section col-lg-12 spinner-target'
          />
        </div>
      </div>
    );
  }
}
