import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormValidator, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject,
} from '@syncfusion/ej2-react-dropdowns';
import { useNavigate, useParams } from 'react-router-dom';
import GraphQLWrapper from '../UI/GraphQLWrapper';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import FilterFactory from '../UI/Filters/FilterFactory';
import { isEmpty } from '../../Utils/helpers';
import {
  ATT_ENV,
  ENVIRONMENTS,
  REGIONS_DATASOURCE,
  REGIONS_VALUES_DICTIONARY,
} from '../../Constants/constants';
import SelectedData from './SelectedData';
import CustomModal from '../UI/Modal/Modal';
import { useLimitUrlsForEnv } from './useLimitUrls';

let formObject;
function RunTests({
  data,
  dataName,
  implementedComponents,
  loading,
  env,
  setEnv,
  getTreeDS,
  runTests,
  getRunTestsVariables,
  filterConfigs,
  dsProviderId,
  redirectUrl,
  prevName,
  prevDescription,
  wasSmokeTest,
  filtersDefaultValues,
  executedUrls,
  region,
  isEditMode,
  editExecution,
  getEditExecutionVariables,
  duplicateExecution,
  includedTrackingValidations,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const [testExecutionName, setTestExecutionName] = useState('');
  const [testExecutionDescription, setTestExecutionDescription] = useState('');
  const [selectedAvailableData, setSelectedAvailableData] = useState([[], []]);
  const [smokeTest, setSmokeTest] = useState(false);
  const [includeTracking, setIncludeTracking] = useState(false);
  const [teRegion, setTeRegion] = useState('us-east-1');
  const [defaultSelected, setDefaultSelected] = useState([]);

  const [showEditedConfirmationModal, setShowEditedConfirmationModal] =
    useState(false);

  const [showComponentConfirmationModal, setShowComponentConfirmationModal] =
    useState(false);
  const { testExecutionId = false } = useParams();

  const treeView = useRef();
  const originalTreeDS = useRef([]);
  const treeDS = useRef([]);
  const { isLimitUrls } = useLimitUrlsForEnv(
    redirectUrl,
    env,
    selectedAvailableData
  );

  var isDuplicate = window.location.href.includes('duplicate');
  //Navigation
  const navigate = useNavigate();
  // region tree features
  const getAllCheckedNodes = () => {
    return treeView.current ? treeView.current.getAllCheckedNodes() : [];
  };

  const selectOptions = [
    { name: 'Smoke test', code: 'smoke_test' },
    { name: 'Include tracking', code: 'include_tracking' },
  ];

  const checkFields = { text: 'name', value: 'code' };
  let mulObj = MultiSelectComponent;

  const updateFilters = (newDS, filterChanged, event, extraData) => {
    // eslint-disable-next-line
    const removing = event && event.name === 'removed';
    const clear = event && event.name === 'clear';
    filters.current.forEach((f) => {
      if (clear) {
        f.clear();
      }
      if (!filterChanged) {
        f.updateDataSource(newDS, extraData);
      }
    });
  };

  const updateTreeData = (newDS, clearTree = false) => {
    if (treeView.current) {
      const currentChecked = getAllCheckedNodes();
      treeView.current.fields = {
        dataSource: newDS.map((n) => {
          return {
            ...n,
            ...(currentChecked.includes(n.id.toString()) && !clearTree && n.il
              ? { isChecked: true }
              : {}),
          };
        }),
        id: 'id',
        text: 'label',
        parentID: 'pId',
        hasChildren: 'hasChildren',
      };
    }
  };

  const updateSelectedAvailableData = (resetSelected = false) => {
    const checkedNodes = getAllCheckedNodes();

    const leaves = treeDS.current.filter((n) => n.il).filter(Boolean);

    const selectedNodes = treeDS.current
      .filter((n) => checkedNodes.includes(n.id.toString()) && n.il)
      .filter(Boolean);
    setSelectedAvailableData([
      leaves,
      resetSelected
        ? []
        : selectedNodes.sort((a, b) => {
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();

            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            return 0;
          }),
    ]);
  };

  const selectAllClick = () => {
    treeView.current?.checkAll();
    updateSelectedAvailableData();
  };

  const deselectAllClick = () => {
    treeView.current?.uncheckAll();
    updateSelectedAvailableData();
  };

  const onNodeChecked = (event) => {
    updateSelectedAvailableData();
  };

  const filters = useRef();

  const onFilterChange = (filter, event) => {
    // deselectAllClick();
    const ds = JSON.parse(JSON.stringify(originalTreeDS.current));
    treeDS.current = (filters.current || [])
      .sort((a, b) => {
        if (a.priority > b.priority) {
          return -1;
        }
        if (a.priority < b.priority) {
          return 1;
        }
        return 0;
      })
      .reduce((acc, f) => {
        return f.filterDS(acc);
      }, ds);

    updateFilters(treeDS.current, filter, event);
    updateTreeData(treeDS.current);
    updateSelectedAvailableData();
  };

  useEffect(() => {
    const filterList = filterConfigs.map((fc) => {
      return FilterFactory.create({
        filterConfig: {
          ...fc,
          onChange: onFilterChange,
        },
        originalDS: originalTreeDS.current,
        currentDS: treeDS.current,
        dsProviderId,
      });
    });
    filters.current = filterList;
  }, [filterConfigs]);

  useEffect(() => {
    if (wasSmokeTest) {
      setSmokeTest(true);
      setDefaultSelected(['smoke_test']);
    }
    if (includedTrackingValidations) {
      setIncludeTracking(true);
      setDefaultSelected((prev) => [...prev, 'include_tracking']);
    }
    if (prevName) setTestExecutionName(prevName);
    if (prevDescription) setTestExecutionDescription(prevDescription);
    if (region) setTeRegion(REGIONS_VALUES_DICTIONARY[region]);
  }, [
    prevName,
    wasSmokeTest,
    region,
    prevDescription,
    includedTrackingValidations,
  ]);

  // region functions to be used by children

  // endregion

  const getComponentsFilter = () => {
    return filters.current.find((f) => f.field === 'components');
  };

  const clearFilters = () => {
    filters.current.forEach((f) => {
      f.clear();
    });
    treeDS.current = originalTreeDS.current;
    updateFilters(treeDS.current);
    updateTreeData(treeDS.current);
    updateSelectedAvailableData();
  };

  function setFiltersDefaultValues() {
    if (!filtersDefaultValues) return;
    filters.current.forEach((f) => {
      const defaultValues = filtersDefaultValues?.find(
        (fdv) => fdv.field === f.field
      )?.values;
      if (defaultValues) {
        defaultValues.forEach((dv) => {
          f.selectValue(dv);
          f.onChange(f, dv);
        });
      }
    });
  }

  useEffect(() => {
    // update components filters
    if (!implementedComponents) return;
    const cmpsFilter = getComponentsFilter();
    if (cmpsFilter) {
      cmpsFilter.saveExtraDataForFiltering({
        toInclude: implementedComponents,
      });
      cmpsFilter.updateDataSource(treeDS.current);
    }
  }, [implementedComponents]);
  // endregion

  useEffect(() => {
    originalTreeDS.current = getTreeDS(data || []);
    treeDS.current = originalTreeDS.current;
    updateFilters(
      treeDS.current,
      null,
      { name: 'clear' },
      { toInclude: implementedComponents }
    );
    setFiltersDefaultValues();
    filters.current.map((f) => f.updateDefaultValues());
    updateTreeData(treeDS.current, true);
    updateSelectedAvailableData();
  }, [data]);

  useEffect(() => {
    if (!treeView.current || !treeView.current.treeData || !executedUrls)
      return;

    treeView.current.treeData.forEach((node) => {
      if (
        executedUrls.find((url) => url.url === node.url && url.app === node.a)
      ) {
        treeView.current.checkedNodes.push(node.id.toString());
        node.isChecked = true;
      }
    });

    updateTreeData(treeDS.current);
    updateSelectedAvailableData();
  }, [executedUrls, treeView.current]);

  // region header
  const filterBtn = (
    <span
      className='e-icons e-filter-3 rt-filters-icon'
      onClick={() => {
        updateFilters(originalTreeDS.current, null, null, {
          toInclude: implementedComponents,
        });
        filters.current.map((f) => f.updateDefaultValues());
        return setShowFilters(!showFilters);
      }}
    />
  );

  const validateForm = () => {
    const options = {
      // validation rules
      rules: {
        text: {
          required: [true, '* Please enter test execution name'],
        },
        description: {
          required: [true, '* Please enter test execution description'],
        },
      },
    };
    // Initialize the form validator
    formObject = new FormValidator('#form-element', options);
    return formObject.validate();
  };

  function onSubmit(event, allowEmptyComponents = false) {
    if (!validateForm()) return;

    if (isEmpty(selectedAvailableData[1])) {
      window.flash(`There are no ${dataName} selected to run tests`, 'info');
      return;
    }

    if (
      !allowEmptyComponents &&
      filters.current.find((f) => f.field === 'components')?.values?.length ===
        0
    ) {
      setShowComponentConfirmationModal(true);
      return;
    }
    runTests({
      variables: getRunTestsVariables(
        testExecutionName,
        testExecutionDescription,
        ATT_ENV,
        selectedAvailableData,
        filters.current,
        smokeTest,
        teRegion,
        includeTracking
      ),
    }).then(() => navigate(redirectUrl));
  }

  const saveEditedHandler = () => {
    setShowEditedConfirmationModal(false);

    if (!validateForm()) return;

    editExecution({
      variables: getEditExecutionVariables(
        testExecutionId,
        testExecutionName,
        testExecutionDescription,
        env,
        selectedAvailableData,
        filters.current,
        smokeTest,
        teRegion,
        includeTracking
      ),
    }).then(() => navigate(redirectUrl));
  };

  const saveEditedConfirmationModal = (
    <CustomModal
      show={showEditedConfirmationModal}
      title='Once the Tests Execution is saved the results and data will be deleted.
      Are you sure you want to continue the edition process?'
      onClose={() => setShowEditedConfirmationModal(false)}
      onAccept={() => saveEditedHandler()}
    />
  );
  const saveDuplicateHandler = () => {
    if (!validateForm()) return;

    duplicateExecution({
      variables: getRunTestsVariables(
        testExecutionName,
        testExecutionDescription,
        ATT_ENV,
        selectedAvailableData,
        filters.current,
        smokeTest,
        teRegion,
        includeTracking
      ),
    }).then(() => navigate(redirectUrl));
  };

  const componentsConfirmationModal = (
    <CustomModal
      show={showComponentConfirmationModal}
      title='There are no components selected for this execution. Are you sure you want to run the tests over all the components?'
      onClose={() => setShowComponentConfirmationModal(false)}
      onAccept={(event) => onSubmit(event, true)}
    />
  );

  const onSaveEdited = () => {
    const options = {
      // validation rules
      rules: {
        text: {
          required: [true, '* Please enter test execution name'],
        },
        description: {
          required: [true, '* Please enter test execution description'],
        },
      },
    };
    // Initialize the form validator
    formObject = new FormValidator('#form-element', options);
    if (!formObject.validate()) return;

    if (isEmpty(selectedAvailableData[1])) {
      window.flash(`There are no ${dataName} selected to run tests`, 'info');
      return;
    }
    setShowEditedConfirmationModal(true);
    return;
  };

  const onchangeRegion = (event) => {
    setTeRegion(event.target.value);
  };

  const selectOptionsOnchange = (event) => {
    setSmokeTest(false);
    setIncludeTracking(false);
    const options = event.value || [];
    for (const value of options) {
      if (value === 'smoke_test') {
        setSmokeTest(true);
      } else if (value === 'include_tracking') {
        setIncludeTracking(true);
      }
    }
    setDefaultSelected(options);
  };

  const invisible = dsProviderId === 'Features';
  const envClassName =
    dsProviderId !== 'Features'
      ? 'col-sm-12 col-md-2 ml-auto control-section'
      : 'col-sm-12 col-md-4 control-section';

  const head = (
    <Fragment>
      <form id='form-element' method='post'>
        <div className='row rt-header-row align-items-center'>
          <div className={envClassName}>
            <DropDownListComponent
              dataSource={ENVIRONMENTS}
              floatLabelType='Never'
              enablePersistence={true}
              fields={{ text: 'text', value: 'value' }}
              value={env}
              change={(e) => {
                setEnv(e.itemData.value);
              }}
              placeholder='Environment'
            />
          </div>
          {!invisible && (
            <div className='col-sm-12 col-md-2 ml-auto control-section'>
              <DropDownListComponent
                dataSource={REGIONS_DATASOURCE}
                fields={{ text: 'text', value: 'value' }}
                value={teRegion}
                placeholder='Region'
                onChange={onchangeRegion}
              />
            </div>
          )}
          {!invisible && (
            <div className='col-sm-12 col-md-2 ml-auto control-section'>
              <MultiSelectComponent
                id='checkbox'
                ref={(scope) => {
                  mulObj = scope;
                }}
                dataSource={selectOptions}
                fields={checkFields}
                placeholder='Select options'
                mode='CheckBox'
                showSelectAll={true}
                showDropDownIcon={true}
                onChange={selectOptionsOnchange}
                filterBarPlaceholder='Select options'
                popupHeight='350px'
                value={defaultSelected}
              >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
            </div>
          )}
          <div className='col-sm-12 col-md-5 ml-auto control-section'>
            <TextBoxComponent
              className='tests-execution-name-textbox'
              name='text'
              type='text'
              placeholder='Enter the test execution name'
              value={testExecutionName}
              floatLabelType='Never'
              input={(e) => setTestExecutionName(e.value)}
              data-msg-containerid='errroForTestExecutionName'
            />
            <label
              id='errroForTestExecutionName'
              className='e-error'
              htmlFor='text'
            />
          </div>
          <div className='col-sm-12 col-md-1 control-section'>
            {isEditMode || isDuplicate ? (
              <ButtonComponent
                id='save_edit_btn'
                iconCss='e-icons e-save'
                cssClass={
                  isLimitUrls
                    ? 'run-test-btn-disabled'
                    : 'e-custom save-edit-btn'
                }
                type='button'
                disabled={isLimitUrls}
                onClick={isEditMode ? onSaveEdited : saveDuplicateHandler}
              >
                Save
              </ButtonComponent>
            ) : (
              <ButtonComponent
                id='submit_btn'
                iconCss='e-icons e-play'
                cssClass={
                  isLimitUrls
                    ? 'run-test-btn-disabled'
                    : 'e-custom run-test-btn'
                }
                type='button'
                disabled={isLimitUrls}
                onClick={onSubmit}
              >
                Run Test
              </ButtonComponent>
            )}
          </div>
        </div>
        <div className='col w-full my-2 pb-2 container-description'>
          <TextBoxComponent
            className='tests-execution-description-textbox'
            name='description'
            type='text'
            placeholder='Enter the test execution description'
            value={testExecutionDescription}
            floatLabelType='Never'
            data-msg-containerid='errorForTestExecutionDescription'
            input={(e) => setTestExecutionDescription(e.value)}
          />
          <label
            id='errorForTestExecutionDescription'
            className='e-error'
            htmlFor='description'
          />
        </div>
      </form>
    </Fragment>
  );
  // endregion

  const filtersPanel = showFilters && (
    <div className='d-flex justify-content-center'>
      <div className='rt-filters-panel'>
        <div className='e-card'>
          <div className='e-card-header'>
            <div className='e-card-header-caption'>
              <ButtonComponent
                iconCss='e-icons e-filter-clear-2'
                onClick={clearFilters}
                cssClass='e-custom filters-reset-btn'
              >
                Reset
              </ButtonComponent>
            </div>
            <div
              className='e-card-header-image e-icons e-close filters-close-btn'
              onClick={(e) => setShowFilters(false)}
            />
          </div>
          <div className='e-card-content'>
            <div className='row'>
              {filters.current.map((f) => {
                return f.render();
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const contextsTree = (
    <>
      <div className='tree-buttons'>
        <ButtonComponent
          onClick={selectAllClick}
          iconCss='e-icons e-select-all'
        >
          Check All
        </ButtonComponent>
        <ButtonComponent onClick={deselectAllClick} iconCss='e-icons e-copy-1'>
          Uncheck All
        </ButtonComponent>
      </div>

      <TreeViewComponent
        ref={(tv) => (treeView.current = tv)}
        showCheckBox
        loadOnDemand
        animation={{
          expand: { effect: 'SlideDown', duration: 200, easing: 'linear' },
          collapse: { effect: 'SlideUp', duration: 200, easing: 'linear' },
        }}
        enablePersistence
        nodeChecked={onNodeChecked}
        style={{ height: '300px', overflow: 'auto' }}
      />
    </>
  );

  const view = (
    <div className='control-section justify-content-center'>
      {saveEditedConfirmationModal}
      {componentsConfirmationModal}
      <div id='wrapper' className='run-tests-wrapper'>
        <div id='head'>{head}</div>
        <div className='content'>
          <div className='e-card'>
            {filtersPanel}
            <div className='e-card-content'>
              <div className='row'>
                <div className='col-md-5 col-sm-12 contexts-tree-container'>
                  <div className='control-section urls-section'>
                    <span className='e-badge e-badge-dark att-badge'>
                      Available {dataName} ({selectedAvailableData[0].length})
                    </span>
                    <div>{filterBtn}</div>
                  </div>
                  {contextsTree}
                </div>
                <div className='col-md-7 col-sm-12 selected-urls-container'>
                  <SelectedData
                    data={selectedAvailableData}
                    dataName={dataName}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return <GraphQLWrapper component={view} loading={loading} />;
}

export default RunTests;
