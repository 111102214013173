import { useMutation, useQuery } from '@apollo/client';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from '@syncfusion/ej2-react-grids';
import React, { useRef, useState } from 'react';

import { IoMdClose, IoMdTrash } from 'react-icons/io';
import { FAILED } from '../../../Constants/constants';
import { DELETE_PROCESSING_QUEUE_ITEM } from '../../../GraphQL/ManageCustomScopes/Mutations';
import { GET_PROCESSING_SCOPES } from '../../../GraphQL/ManageCustomScopes/Queries';
import GraphQLWrapper from '../../UI/GraphQLWrapper';
import CustomModal from '../../UI/Modal/Modal';
import './ProcessingQueue.css';

function ProcessingQueue() {
  const [scopes, setScopes] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const selectedToDeleteId = useRef();
  const isFirstRender = useRef(true);

  const { loading, refetch } = useQuery(GET_PROCESSING_SCOPES, {
    notifyOnNetworkStatusChange: true,
    pollInterval: 15000,
    onCompleted: (data) => {
      const formattedScopes = data.trfxCodelessOperationFindMany.map(
        (scope) => {
          return {
            ...scope,
            implementedWC: scope.implementedWC?.name,
            actions: scope._id,
          };
        }
      );
      setScopes(formattedScopes);
      isFirstRender.current = false;
    },
  });

  const [deleteTest] = useMutation(DELETE_PROCESSING_QUEUE_ITEM);

  const onClickDeleteIcon = (id) => {
    setShowDeleteModal(true);
    selectedToDeleteId.current = id;
  };

  const onDelete = () => {
    setShowDeleteModal(false);
    window.showMessage('Loading changes', 'loading');

    deleteTest({ variables: { id: selectedToDeleteId.current } })
      .then(() => {
        refetch().then(() => {
          window.flash('Element was successfully deleted!', 'success');
          window.hideMessage();
          selectedToDeleteId.current = '';
        });
      })
      .catch((error) => {
        window.flash(
          'An error occurred. Test execution could not be deleted',
          'error'
        );
      });
  };

  const actions = (args) => {
    if (args.status === FAILED)
      return (
        <IoMdTrash
          onClick={() => onClickDeleteIcon(args._id)}
          style={{ color: 'var(--att-primary)' }}
          size='20'
          title='delete element'
        />
      );

    return (
      <IoMdTrash style={{ color: 'gray' }} size='20' title='delete element' />
    );
  };

  const view = (
    <div className='d-flex justify-content-center'>
      <div className='e-card' style={{ width: 'var(--page-content-width)' }}>
        <div className='ml-auto'>
          <a
            href='/view-custom-scopes'
            style={{ textDecoration: 'none', color: 'gray' }}
          >
            <IoMdClose size='20' className='mt-2 mr-2' />
          </a>
        </div>
        <div className='e-card-content'>
          <div className='d-flex justify-content-center'>
            <div>
              <GridComponent dataSource={scopes}>
                <ColumnsDirective>
                  <ColumnDirective
                    field='implementedWC'
                    headerText='Component'
                  />
                  <ColumnDirective field='operation' headerText='Operation' />
                  <ColumnDirective field='status' headerText='Status' />
                  <ColumnDirective field='name' headerText='Custom Scope' />
                  <ColumnDirective
                    field='actions'
                    headerText='Actions'
                    template={actions}
                  />
                </ColumnsDirective>
              </GridComponent>
            </div>
          </div>
          <CustomModal
            show={showDeleteModal}
            title='Are you sure you want to delete this element?'
            onClose={() => setShowDeleteModal(false)}
            onAccept={() => onDelete()}
          />
        </div>
      </div>
    </div>
  );

  return (
    <GraphQLWrapper
      loading={loading && isFirstRender.current}
      component={view}
    />
  );
}

export default ProcessingQueue;
