import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { RUNNING } from '../../../Constants/constants';
import {
  GET_ALL_URLS,
  GET_IMPLEMENTED_COMPONENTS,
} from '../../../GraphQL/ManageUrls/TRFX/Queries';
import { EDIT_TEST_EXECUTION } from '../../../GraphQL/RunTests/aTC/Mutations';
import { GET_EDITED_URLS } from '../../../GraphQL/RunTests/TRFX/Queries';
import { GET_TEST_EXECUTIONS_BY_ID } from '../../../GraphQL/TestExecutions/TRFX/Queries';
import { TEST_EXECUTION_EXECUTE_TESTS } from '../../../GraphQL/TestResults/TRFX/Mutations';
import { isEmpty } from '../../../Utils/helpers';
import { getTRFXTreeDS } from '../../../Utils/treeProcessing';
import TRFXRunTests from '../RunTests';
import { AppContext } from '../../UI/contexts';
import { DUPLICATE_TEST_EXECUTION } from '../../../GraphQL/RunTests/TRFX/Mutations';

function RunTests() {
  const { testExecutionId } = useParams();

  const { user } = useContext(AppContext);

  const components = useRef([]);

  const [env, setEnv] = useState('dev');
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [smokeTest, setSmokeTest] = useState(null);
  const [filtersDefaultValues, setFiltersDefaultValues] = useState(false);
  const [executedUrls, setExecutedUrls] = useState([]);
  const [region, setRegion] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [includeTracking, setIncludeTracking] = useState(false);

  const { loading, data } = useQuery(GET_ALL_URLS, {
    variables: { env, limit: 10000 },
    fetchPolicy: 'cache-first',
  });

  const [testById, { loading: loadingEditedExecution }] = useLazyQuery(
    GET_TEST_EXECUTIONS_BY_ID,
    {
      onCompleted: (data) => {
        let test = data.testsExecutionFindById;
        setName(test.name);
        setDescription(test.description);
        setSmokeTest(test.smokeTest);
        setEnv(test.env);
        setRegion(test.region);
        setIncludeTracking(test.includeTrackingValidations);
        if (test.filters) setFiltersDefaultValues(test.filters);
      },
    }
  );

  const [editedUrls, { loading: loadingEditedExecutionUrls }] = useLazyQuery(
    GET_EDITED_URLS,
    {
      onCompleted: (data) => {
        setExecutedUrls(
          data.urlTestsFindMany.map((u) => {
            return { url: u.url, app: u.context.application };
          })
        );
      },
    }
  );

  useEffect(() => {
    if (!testExecutionId) return;
    if (window.location.pathname.includes('edit')) setIsEditMode(true);
    testById({ variables: { id: testExecutionId } });
    editedUrls({ variables: { teId: testExecutionId, limit: 10000 } });
  }, []);

  useQuery(GET_IMPLEMENTED_COMPONENTS, {
    fetchPolicy: 'cache-first',
    onCompleted: (d) => {
      components.current = (d.implementedWCFindMany || []).map((d) => d.name);
    },
  });

  const [runTests, { loading: loadingRunTest }] = useMutation(
    TEST_EXECUTION_EXECUTE_TESTS,
    {
      onCompleted: (data) => {
        if (data.testsExecutionExecuteTest.status !== RUNNING) {
          window.flash(
            `Your execution was placed in a queue for processing`,
            'info'
          );
        }
      },
      onError: (error) => {
        const message =
          error.message || 'ERROR IN MUTATION TestExecutionExecuteTests';
        window.flash(message, 'error');
      },
    }
  );

  const [editExecution, { loading: loadingEditMutation }] = useMutation(
    EDIT_TEST_EXECUTION,
    {
      onCompleted: (data) => {
        window.flash(`Successfully edited test execution`, 'success');
      },
      onError: (error) => {
        const message = error.message || 'ERROR IN MUTATION EditTestExecution';
        window.flash(message, 'error');
      },
    }
  );

  const [duplicateExecution, { loading: loadingDuplicateMutation }] =
    useMutation(DUPLICATE_TEST_EXECUTION, {
      onCompleted: (data) => {
        window.flash(`Successfully duplicated test execution`, 'success');
      },
      onError: (error) => {
        const message = error.message || 'ERROR IN MUTATION TestExecutionCopy';
        window.flash(message, 'error');
      },
    });

  const getComponents = (su, filters) => {
    const cmpsFilter = filters.find((f) => f.field === 'components');
    const cmpsToFilter = cmpsFilter.getValues();

    const components = (su.cmps || [])
      .filter((c) => isEmpty(cmpsToFilter) || cmpsToFilter.includes(c.name))
      .reduce((acc, c) => {
        acc.add(c.name);

        return acc;
      }, new Set());

    return Array.from(components);
  };

  const getFiltersList = (filters) => {
    const filtersList = filters.map(({ field, values }) => ({ field, values }));
    return filtersList;
  };

  // return variables object to use in run tests mutation
  const getRunTestVariables = (
    name,
    description,
    attEnv,
    selectedAvailableData,
    filters,
    smokeTest = false,
    teRegion,
    includeTracking = false
  ) => {
    const filtersList = getFiltersList(filters);
    const userId = user._id || '61097bfa97ff9f0009fe3954';
    const contexts = selectedAvailableData[1]
      .filter((su) => su.il)
      .filter(Boolean)
      .map((su) => {
        return {
          contextId: su.cId,
          url: su.url,
          components: getComponents(su, filters),
        };
      });
    return {
      contexts,
      name,
      description,
      attEnv,
      smokeTest,
      filters: filtersList,
      region: teRegion,
      userId,
      includeTrackingValidations: includeTracking,
    };
  };
  const getEditExecutionVariables = (
    teId,
    name,
    description,
    env,
    selectedAvailableData,
    filters,
    smokeTest = false,
    teRegion,
    includeTracking
  ) => {
    const filtersList = getFiltersList(filters);
    const contexts = selectedAvailableData[1]
      .filter((su) => su.il)
      .filter(Boolean)
      .map((su) => {
        return {
          contextId: su.cId,
          url: su.url,
          components: getComponents(su, filters),
        };
      });
    return {
      teId,
      contexts,
      name,
      description,
      env,
      filters: filtersList,
      smokeTest,
      region: teRegion,
      includeTrackingValidations: includeTracking,
    };
  };

  const filterConfigs = [
    {
      type: 'multiSelect',
      id: 'application-filter',
      containerClassName: 'col-md-4 col-sm-12',
      placeholder: 'Select an application',
      field: 'application',
      priority: -1,
      onChange: () => {},
      layout: {
        row: 0,
        col: 0,
        sizeX: 4,
        sizeY: 1,
      },
    },
    {
      type: 'multiSelect',
      id: 'client-filter',
      containerClassName: 'col-md-8 col-sm-12',
      placeholder: 'Select clients',
      field: 'client',
      priority: -2,
      onChange: () => {},
      layout: {
        row: 0,
        col: 4,
        sizeX: 8,
        sizeY: 1,
      },
    },
    {
      type: 'multiSelect',
      id: 'template-filter',
      placeholder: 'Select templates',
      containerClassName: 'col-md-8 col-sm-12',
      field: 'template',
      priority: -3,
      onChange: () => {},
      layout: {
        row: 1,
        col: 0,
        sizeX: 6,
        sizeY: 1,
      },
    },
    {
      type: 'multiSelect',
      id: 'se-filter',
      placeholder: 'Select site editions',
      containerClassName: 'col-md-4 col-sm-12',
      field: 'siteEdition',
      priority: -4,
      onChange: () => {},
      layout: {
        row: 1,
        col: 6,
        sizeX: 6,
        sizeY: 1,
      },
    },
    {
      type: 'multiSelect',
      id: 'components-filter',
      containerClassName: 'col-md-4 col-sm-12',
      placeholder: 'Select components',
      field: 'components',
      priority: -5,
      onChange: () => {},
      layout: {
        row: 2,
        col: 0,
        sizeX: 12,
        sizeY: 1,
      },
    },
    {
      type: 'input',
      id: 'url-filter',
      containerClassName: 'col-md-8 col-sm-12',
      placeholder: 'Type an url',
      field: 'url',
      priority: -6,
      onChange: () => {},
      layout: {
        row: 2,
        col: 0,
        sizeX: 4,
        sizeY: 1,
      },
    },
  ];

  return (
    <TRFXRunTests
      data={data}
      dataName='URLs'
      implementedComponents={components.current}
      loading={
        loading ||
        loadingRunTest ||
        loadingEditMutation ||
        loadingEditedExecution ||
        loadingEditedExecutionUrls ||
        loadingDuplicateMutation
      }
      env={env}
      setEnv={setEnv}
      getTreeDS={getTRFXTreeDS}
      runTests={runTests}
      getRunTestsVariables={getRunTestVariables}
      filterConfigs={filterConfigs}
      dsProviderId='Contexts'
      redirectUrl='/trfx-test-executions'
      prevName={name}
      prevDescription={description}
      wasSmokeTest={smokeTest}
      filtersDefaultValues={filtersDefaultValues}
      executedUrls={executedUrls}
      region={region}
      isEditMode={isEditMode}
      editExecution={editExecution}
      getEditExecutionVariables={getEditExecutionVariables}
      duplicateExecution={duplicateExecution}
      includedTrackingValidations={includeTracking}
    />
  );
}
export default RunTests;
