import React from 'react';
import DataCollector from './DataCollector';
import {
  ATT_ENV,
  TEST_EXECUTIONS_PAGE_SIZE,
} from '../../../Constants/constants';
import '../TestExecutions.css';

export default function TRFXTestExecutionsPage() {
  return (
    <div>
      <DataCollector
        isScheduled={false}
        variables={{
          page: 1,
          perPage: TEST_EXECUTIONS_PAGE_SIZE,
          filter: {
            attEnv: ATT_ENV,
            isScheduled: false,
            OR: [
              {
                origin: {
                  kind: 'new',
                },
              },
              {
                origin: {
                  kind: 'rerun',
                },
              },
            ],
          },
          sort: '_ID_DESC',
        }}
      />
      <div className='mt-5'>
        <h4
          className='test-execution-container mb-2'
          style={{ color: 'var(--att-primary)' }}
        >
          Scheduled Executions
        </h4>
        <DataCollector
          isScheduled={true}
          variables={{
            page: 1,
            perPage: 12,
            filter: {
              attEnv: ATT_ENV,
              isScheduled: true,
            },
            sort: '_ID_DESC',
          }}
        />
      </div>
    </div>
  );
}
