import { gql } from '@apollo/client';

export const GET_EDITED_URLS = gql`
  query UrlTestsFindMany($teId: MongoID, $limit: Int) {
    urlTestsFindMany(filter: { testsExecution: $teId }, limit: $limit) {
      url
      context {
        application
      }
    }
  }
`;
