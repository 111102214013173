export const cmpNamesSeparator = '|||';

export function getTRFXTreeDS(contextsList) {
  // const sorted = contextsList.sort((a, b) => {
  //   if (a.client < b.client) return -1
  //   if (a.client > b.client) return 1
  //   return 0
  // })
  if (!contextsList.contextFindMany) return [];
  const idByKey = {};
  const treeDS = contextsList.contextFindMany.reduce(
    (
      acc,
      {
        client,
        template,
        siteEdition,
        urls,
        _id,
        application,
        components,
        customerName,
      }
    ) => {
      if (
        !client ||
        !template ||
        !siteEdition ||
        !application ||
        !customerName
      ) {
        return acc;
      }

      // save client
      const clientKey = `${application}|||${client}`;
      let clientId = idByKey[clientKey];
      if (clientId === undefined) {
        // add client as node
        const clientNode = {
          id: acc.length,
          label: [
            customerName,
            `(${client.toString().toUpperCase()})`,
            `_${application.toString().toUpperCase()}`,
          ]
            .filter(Boolean)
            .join(' '),
          // c: client,
          // a: application,
          hasChildren: true,
        };
        acc.push(clientNode);
        clientId = clientNode.id;
        idByKey[clientKey] = clientId;
      }

      // save template
      const templateKey = `${application}|||${client}|||${template}`;
      let templateId = idByKey[templateKey];
      if (templateId === undefined) {
        // add client as node
        const templateNode = {
          id: acc.length,
          label: template,
          pId: clientId,
          // t: template,
          // a: application,
          // c: client,
          hasChildren: true,
        };
        acc.push(templateNode);
        templateId = templateNode.id;
        idByKey[templateKey] = templateId;
      }

      // save siteEdition
      const seKey = `${application}|||${client}|||${template}|||${siteEdition}`;
      let seId = idByKey[seKey];
      if (seId === undefined) {
        // add client as node
        const seNode = {
          id: acc.length,
          label: siteEdition.toString().toLowerCase(),
          pId: templateId,
          // a: application,
          // c: client,
          // t: template,
          // se: siteEdition,
          hasChildren: !!urls,
        };
        acc.push(seNode);
        seId = seNode.id;
        idByKey[seKey] = seId;
      }

      // save each url
      (urls || []).forEach((url) => {
        const cmpNames = [
          '',
          ...(components || []).map((c) =>
            c.name ? c.name.toString().trim() : ''
          ),
          '',
        ].join(cmpNamesSeparator);

        acc.push({
          id: acc.length,
          label: url,
          pId: seId,
          parentIds: [clientId, templateId, seId],
          il: true,
          a: application,
          c: client,
          t: template,
          se: siteEdition,
          cmps: components,
          cNames: cmpNames,
          cId: _id,
          url,
          selectedLabel: url,
        });
      });

      return acc;
    },
    []
  );
  return treeDS;
}

export function getAtcTreeDS(data) {
  if (!data.atcFeaturesFindMany) return [];
  const idByKey = {};
  const treeDS = data.atcFeaturesFindMany.reduce(
    (acc, { name, menuPath, _id, application }) => {
      if (!name || !menuPath || !_id || !application) {
        return acc;
      }
      // save application
      const applicationKey = `${application}`;
      let applicationId = idByKey[applicationKey];
      if (applicationId === undefined) {
        // add application as node
        const applicationNode = {
          id: acc.length,
          label: application.toString(),
          hasChildren: true,
        };
        acc.push(applicationNode);
        applicationId = applicationNode.id;
        idByKey[applicationKey] = applicationId;
      }

      // save menuPath
      // menuPath = <v1>/<v2>/<v3>
      // -application
      // --<v1>
      // ---<v2>
      // ----<v3>
      let currentPId = applicationId;
      const v1v2v3 = menuPath.toString().split('/');
      let currentKey = `${application}`;
      let currentId;
      const ids = [];
      v1v2v3.forEach((vi) => {
        currentKey += `|||${vi}`;
        currentId = idByKey[currentKey];

        if (currentId === undefined) {
          // add menuPath's Vi as node
          const menuPathCurrentNode = {
            id: acc.length,
            label: vi,
            pId: currentPId,
            hasChildren: true,
          };
          acc.push(menuPathCurrentNode);
          currentId = menuPathCurrentNode.id;
          idByKey[currentKey] = currentId;
        }
        ids.push(currentId);
        currentPId = currentId;
      });

      // save name
      const nameKey = `${currentKey}|||${name}`;
      let nameId = idByKey[nameKey];
      if (nameId === undefined) {
        // add name as node
        const nameNode = {
          id: acc.length,
          featureId: _id,
          label: name,
          pId: currentId,
          hasChildren: false,
          il: true,
          application,
          menuPath,
          name,
          parentIds: [applicationId, ...ids],
          selectedLabel: nameKey.replaceAll(`|||`, `/`),
        };
        acc.push(nameNode);
        nameId = nameNode.id;
        idByKey[nameKey] = nameId;
      }

      return acc;
    },
    []
  );
  return treeDS;
}
