import React, { useRef, useState } from 'react';
import Logo from '../../UI/Logo/Logo';
import { MenuToggleButton } from '../Sidebar/MenuToggleButton';
import './Toolbar.css';
import { MainMenu } from '../Sidebar/MainMenu';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';

function Toolbar() {
  const [showSidebar, setShowSidebar] = useState(false);
  const sidebarRef = useRef();

  const trfxItems = [
    {
      text: 'Run tests',
      url: '/trfx-run-tests',
      iconCss: 'e-icons e-play',
    },
    {
      text: 'Test Executions',
      url: '/trfx-test-executions',
      iconCss: 'e-icons e-data-validation',
    },
  ];
  // const atcItems = [
  //   {
  //     text: 'Run tests',
  //     url: '/atc-run-tests',
  //     iconCss: 'e-icons e-play',
  //   },
  //   {
  //     text: 'Test Executions',
  //     url: '/atc-test-executions',
  //     iconCss: 'e-icons e-data-validation',
  //   },
  // ];
  const menuToggleBtnClick = (e) => {
    setShowSidebar(!showSidebar);
  };

  const closeSidebarBtnClick = (e) => {
    setShowSidebar(false);
  };

  const sidebarProps = showSidebar
    ? {
        isOpen: true,
        visible: true,
      }
    : {
        isOpen: false,
        visible: false,
      };

  return (
    <div className='toolbar-nav'>
      <a
        href='/'
        style={{
          fontWeight: 'bold',
          fontStyle: 'italic',
          textDecoration: 'none',
          fontSize: '20px',
        }}
      >
        <Logo />
      </a>
      <div className='control-section toolbar-menu-container'>
        <div className='toolbar-nav-items'>
          <DropDownButtonComponent items={trfxItems} className='mr-3'>
            TRFX
          </DropDownButtonComponent>

          {/* <DropDownButtonComponent
            items={atcItems}
            style={{ marginRight: '40px' }}
          >
            aTC
          </DropDownButtonComponent> */}
        </div>

        <div className='toolbar-menu'>
          <MenuToggleButton onClick={menuToggleBtnClick} />
          <MainMenu
            reference={sidebarRef}
            openType='Push'
            onCloseBtnClick={closeSidebarBtnClick}
            {...sidebarProps}
          />
        </div>
      </div>
    </div>
  );
}

export default Toolbar;
