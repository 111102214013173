import { gql } from '@apollo/client';

export const GET_USER_FROM_CF_TOKEN = gql`
  query UserGetUserFromCFToken($cfToken: String) {
    userGetUserFromCFToken(cfToken: $cfToken) {
      email
      favouriteIds
      roles {
        name
      }
      _id
    }
  }
`;
