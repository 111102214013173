import FilterDataHandlerFactory from './FilterDataHandlerFactory';
import DropdownFilter from './DropdownFilter';
import MultiSelectFilter from './MultiSelectFilter';
import InputFilter from './InputFilter';
import CheckboxFilter from './CheckboxFilter';

export default class FilterFactory {
  static create({ filterConfig, dsProviderId, currentDS, originalDS }) {
    // create data handler
    const dataHandler = FilterDataHandlerFactory.create({
      dsProviderId,
      field: filterConfig.field,
      originalDS,
      currentDS,
    });

    switch (filterConfig.type) {
      case 'singleSelect': {
        return new DropdownFilter({
          filterConfig,
          dataHandler,
        });
      }
      case 'input': {
        return new InputFilter({
          filterConfig,
          dataHandler,
        });
      }
      default:
      case 'multiSelect': {
        return new MultiSelectFilter({
          filterConfig,
          dataHandler,
        });
      }
      case 'checkbox': {
        return new CheckboxFilter({ filterConfig, dataHandler });
      }
    }
  }
}
