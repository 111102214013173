import { gql } from '@apollo/client';

export const EDIT_CUSTOM_SCOPE = gql`
  mutation EditCustomScope($record: CreateOneTRFXCodelessOperationInput!) {
    trfxCodelessOperationCreateRecord(record: $record) {
      _id
      scope
      name
      parameters {
        name
        value
      }
    }
  }
`;

export const CREATE_CUSTOM_SCOPE = gql`
  mutation CreateCustomScope($record: CreateOneTRFXCodelessOperationInput!) {
    trfxCodelessOperationCreateRecord(record: $record) {
      _id
      scope
      name
      parameters {
        name
        value
      }
    }
  }
`;

export const DELETE_CUSTOM_SCOPE = gql`
  mutation DeleteCustomScope($record: CreateOneTRFXCodelessOperationInput!) {
    trfxCodelessOperationCreateRecord(record: $record) {
      _id
      scope
      name
      parameters {
        name
        value
      }
    }
  }
`;

export const DELETE_PROCESSING_QUEUE_ITEM = gql`
  mutation TrfxCodelessOperationRemoveById($id: MongoID!) {
    trfxCodelessOperationRemoveById(_id: $id) {
      record {
        implementedWC {
          name
        }
        name
        status
        operation
      }
    }
  }
`;
