import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import React, { useEffect, useState } from 'react';
import { MdOutlineContentCopy } from 'react-icons/md';
import './CopyToClipboard.css';
import '../../../App.css';

export function CopyToClipboard({ text }) {
  const [toggle, setToggle] = useState(false);

  function handleToggle() {
    setToggle(true);
    navigator.clipboard.writeText(text);
  }

  useEffect(() => {
    if (toggle) {
      setTimeout(() => {
        setToggle(false);
      }, 1000);
    }
  }, [toggle]);
  return (
    <TooltipComponent
      content={toggle ? 'Copied!' : 'Copy to clipboard'}
      target='.att-copy-button'
      opensOn='Hover'
    >
      <button className={'att-copy-button'}>
        <MdOutlineContentCopy
          className='e-icons'
          size='20'
          onClick={handleToggle}
          color='white'
        />
      </button>
    </TooltipComponent>
  );
}
