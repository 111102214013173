import React, { useState } from 'react';
import { RiHeart3Line, RiHeart3Fill } from 'react-icons/ri';
import '../../TestExecutions/TestExecutions.css';

export default function Favorites({
  userOwnsTest,
  testId,
  isFavorite,
  addToFavoritesHandler,
  removeFromFavoritesHandler,
}) {
  const [toggle, setToggle] = useState(false);
  const [activatedToggle, setActivatedToggle] = useState(false);

  const toggleHandler = () => {
    setToggle((prevToggle) => !prevToggle);
    setActivatedToggle(true);
  };
  const enable = activatedToggle ? toggle : isFavorite;
  return userOwnsTest ? (
    enable ? (
      <RiHeart3Fill
        title='remove from favorites'
        className='actions-icon'
        onClick={(event) => {
          toggleHandler();
          removeFromFavoritesHandler(event, testId);
        }}
      />
    ) : (
      <RiHeart3Line
        title='add to favorites'
        className='actions-icon'
        onClick={(event) => {
          toggleHandler();
          addToFavoritesHandler(event, testId);
        }}
      />
    )
  ) : (
    <RiHeart3Line className='actions-icon-disabled' />
  );
}
