import { gql } from '@apollo/client';

export const GET_TEST_EXECUTIONS = gql`
  query GetTestExecutions(
    $limit: Int
    $skip: Int
    $filter: FilterFindManyTestsExecutionInput
  ) {
    testsExecutionFindMany(
      limit: $limit
      skip: $skip
      sort: _ID_DESC
      filter: $filter
    ) {
      name
      description
      userId
      env
      start
      passed
      failed
      total
      end
      duration
      status
      _id
      isScheduled
      smokeTest
      region
      description
      email
      isFavourite
      login
    }
  }
`;

export const GET_TEST_EXECUTIONS_PAGINATED = gql`
  query TestsExecutionPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTestsExecutionInput
    $sort: SortFindManyTestsExecutionInput
  ) {
    testsExecutionPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        name
        description
        userId
        env
        start
        passed
        failed
        executionStatus
        total
        end
        duration
        status
        _id
        isScheduled
        smokeTest
        region
        description
        email
        isFavourite
        login
        userId
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`;

export const GET_FAVORITES = gql`
  query GetUserFavoriteTestIds($id: MongoID!) {
    userFindById(_id: $id) {
      favouriteIds
    }
  }
`;

export const GET_TEST_EXECUTIONS_STATUS = gql`
  query GetTestExecutionsStatus {
    testsExecutionGetAllTestsExecutionStatus
  }
`;

export const GET_TEST_EXECUTIONS_BY_ID = gql`
  query GetTestExecutionsById($id: MongoID!) {
    testsExecutionFindById(_id: $id) {
      name
      description
      isScheduled
      frequency
      email
      filters
      smokeTest
      env
      region
      executedUrls {
        url
      }
      notificationType
      includeTrackingValidations
    }
  }
`;

export const GET_CUSTOMERS_DATA = gql`
  query GetCustomersData {
    customerFindMany {
      code
      name
    }
  }
`;
