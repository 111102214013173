import React, { useEffect, useState } from 'react';

export function useLimitUrlsForEnv(redirectUrl, env, selectedAvailableData) {
  const isTRFX = redirectUrl === '/trfx-test-executions';
  const [isLimitUrls, setIsLimitUrls] = useState(false);
  const messageAlert = (envLimit) =>
    `Sorry, you have reached the maximum number of URLs per execution. Maximum of URLs: ${envLimit}. Please try again.`;
  const limitNumberUrls = (env, data) => {
    switch (env) {
      case 'dev':
        if (data[1].length > process.env.REACT_APP_DEV_MAX_URLS_TRFX) {
          setIsLimitUrls(true);
          window.flash(
            messageAlert(process.env.REACT_APP_DEV_MAX_URLS_TRFX),
            'info'
          );
        } else {
          setIsLimitUrls(false);
        }
        break;
      case 'prepro':
        if (data[1].length > process.env.REACT_APP_PREPRO_MAX_URLS_TRFX) {
          setIsLimitUrls(true);
          window.flash(
            messageAlert(process.env.REACT_APP_PREPRO_MAX_URLS_TRFX),
            'info'
          );
        } else {
          setIsLimitUrls(false);
        }
        break;
      case 'prod':
        if (data[1].length > process.env.REACT_APP_PROD_MAX_URLS_TRFX) {
          setIsLimitUrls(true);
          window.flash(
            messageAlert(process.env.REACT_APP_PROD_MAX_URLS_TRFX),
            'info'
          );
        } else {
          setIsLimitUrls(false);
        }
        break;
      default:
        setIsLimitUrls(false);
        break;
    }
  };

  useEffect(() => {
    if (!isTRFX) return;
    if (selectedAvailableData[1].length === 0) setIsLimitUrls(false);
    limitNumberUrls(env, selectedAvailableData);
  }, [selectedAvailableData]);

  return { isLimitUrls };
}
