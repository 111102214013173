import { gql } from '@apollo/client';

export const DUPLICATE_TEST_EXECUTION = gql`
  mutation TestsExecutionCopy(
    $userId: ID!
    $attEnv: String!
    $name: String
    $contexts: [ExecuteTestsContextsInput]
    $origin: ExecuteTestsOriginInput
    $env: String
    $smokeTest: Boolean
    $filters: JSON
    $region: String
    $description: String
    $includeTrackingValidations: Boolean
  ) {
    testsExecutionCopy(
      userId: $userId
      attEnv: $attEnv
      name: $name
      contexts: $contexts
      origin: $origin
      env: $env
      smokeTest: $smokeTest
      filters: $filters
      region: $region
      description: $description
      includeTrackingValidations: $includeTrackingValidations
    ) {
      _id
      totalUrls
    }
  }
`;
