import './TextName.css';
import React from 'react';

function TextName({ label, text }) {
  return (
    <div className='d-flex justify-content-center'>
      <div
        className='text-content mx-2 mb-0 d-inline-block text-truncate'
        data-toggle='tooltip'
        data-placement='top'
        title={text}
      >
        <span className='text-name'>{label}</span>
        <span className='text-description'>{text}</span>
      </div>
    </div>
  );
}
export default TextName;
