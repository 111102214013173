import { gql } from '@apollo/client';

export const GET_URL_TESTS_IDS = gql`
  query FindTestResultsById($executionId: MongoID!, $limit: Int!) {
    urlTestsFindMany(filter: { testsExecution: $executionId }, limit: $limit) {
      _id
      url
    }
  }
`;

export const GET_RESULTS_BY_ID = gql`
  query FindTestResultsById($executionId: MongoID, $idsToInclude: [MongoID]) {
    urlTestsFindMany(
      filter: {
        testsExecution: $executionId
        _operators: { _id: { in: $idsToInclude } }
        finished: true
      }
    ) {
      _id
      url
      duration
      context {
        env
        client
        template
        siteEdition
        application
      }
      testsExecution {
        consecutiveFailedExecutions
        originFrom {
          consecutiveFailedExecutions
        }
      }
      testResults
      flattenedResults
    }
  }
`;
export const GET_DURATION = gql`
  query TestExecutionFindById($id: MongoID!) {
    testsExecutionFindById(_id: $id) {
      duration
      start
      status
      total
      userId
    }
  }
`;

export const GET_REGION_AND_NAME = gql`
  query TestExecutionFindById($id: MongoID!) {
    testsExecutionFindById(_id: $id) {
      name
      region
    }
  }
`;

export const GET_CONSECUTIVE_FAILED_EXECUTIONS = gql`
  query getConsecutiveFailedExecutions($filters: JSON, $limit: Int = 100) {
    testsExecutionGetConsecutiveFailedCount(
      record: { filters: $filters, limit: $limit }
    )
  }
`;
// Flattened Results fields
// {
//   name
//   status
//   genericType
//   type
//   version
//   screenshots{
//     url
//     fileName
//   }
//   extraInfo
//   extraFiles{
//     category
//     file{
//       url
//       fileName
//     }
//   }
//   it{
//     name
//     status
//     screenshots{
//       url
//       fileName
//     }
//     extraInfo
//     extraFiles{
//       category
//       file{
//         url
//         fileName
//       }
//     }
//     it{
//       name
//       status
//       extraFiles{
//         category
//         file{
//           url
//           fileName
//         }
//       }
//       screenshots{
//         url
//         fileName
//       }
//     }
//   }
// }
