import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import './Modal.css';

function CustomModal({
  show,
  title,
  onClose,
  onAccept,
  acceptButtonText = 'OK',
}) {
  const buttons = (isSubmitting, submitForm) => [
    {
      click: () => onClose(),
      buttonModel: {
        content: 'Cancel',
        iconCss: 'e-icons e-close',
        cssClass: 'e-control e-btn e-lib e-outline mr-1',
      },
    },
    {
      click: () => onAccept(),
      buttonModel: {
        content: acceptButtonText,
        iconCss: 'e-icons e-check',
        cssClass: 'e-control e-btn e-lib e-custom mr-4',
      },
    },
  ];

  return (
    <DialogComponent
      isModal
      visible={show}
      close={onClose}
      overlayClick={onClose}
      width='500px'
      position={{ X: 'center', Y: 'center' }}
      buttons={buttons(false, false)}
    >
      <p style={{ fontSize: '18px' }}>{title}</p>
    </DialogComponent>
  );
}
export default CustomModal;
