import React from 'react';
import {
  CANCELLED,
  FAILED,
  FINISHED,
  PASSED,
  RUNNING,
} from '../../../Constants/constants';
import { BsEye } from 'react-icons/bs';
import '../../TestExecutions/TestExecutions.css';

export default function See({ testId, testStatus, seeTestResultsHandler }) {
  const enable = [RUNNING, FINISHED, PASSED, FAILED, CANCELLED].includes(
    testStatus
  );

  return enable ? (
    <BsEye
      title='see test results'
      className='actions-icon'
      onClick={(event) => {
        seeTestResultsHandler(event, testId);
      }}
    />
  ) : (
    <BsEye className='actions-icon-disabled' title='see test results' />
  );
}
