import React from 'react';
import DataCollector from './DataCollector';
import { useParams } from 'react-router-dom';
import { ATT_ENV } from '../../../Constants/constants';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

export default function ChildExecutions() {
  const { testExecutionId } = useParams();

  return (
    <div>
      <div
        className='d-flex'
        style={{
          alignSelf: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          className='d-flex'
          style={{
            width: ' var(--page-content-width)',
          }}
        >
          <ButtonComponent
            className='ml-auto mb-2'
            style={{ backgroundColor: 'var(--att-primary)', color: 'white' }}
            onClick={() => (window.location.href = '/trfx-test-executions')}
          >
            Go Back
          </ButtonComponent>
        </div>
      </div>
      <DataCollector
        variables={{
          page: 1,
          perPage: 12,
          filter: {
            attEnv: ATT_ENV,
            origin: {
              from: testExecutionId,
            },
          },
          sort: '_ID_DESC',
        }}
      />
    </div>
  );
}
