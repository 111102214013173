import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import {
  CANCELLED,
  EDITED,
  FAILED,
  FINISHED,
  PASSED,
} from '../../../Constants/constants';

export default function Delete({
  testId,
  testStatus,
  userOwnsTest,
  deleteSingleClickHandler,
}) {
  const enable =
    userOwnsTest &&
    [CANCELLED, FINISHED, PASSED, FAILED, EDITED].includes(testStatus);
  return enable ? (
    <IoMdTrash
      title='delete test execution'
      className='actions-icon'
      onClick={(event) => {
        deleteSingleClickHandler(event, testId);
      }}
    />
  ) : (
    <IoMdTrash className='actions-icon-disabled' />
  );
}
