import { useNavigate } from 'react-router-dom';
import { React, useEffect, useRef, useState, useContext, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { getFormattedDuration } from '../../Utils/TestResultsMethods';
import CustomSpinner from '../UI/Spinner/Spinner';
import CustomModal from '../UI/Modal/Modal';
import './TestExecutions.css';
import TestExecutionsTable from './TestExecutionsTable';
import EditNameModal from '../UI/EditNameModal';
import {
  REGIONS_DICTIONARY,
  ENVIRONMENTS_DICTIONARY,
  PENDING,
  RUNNING,
  EDITED,
} from '../../Constants/constants';

import {
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  RUN_EDITED_TESTS_EXECUTION,
  STOP_RESUME_SCHEDULED_EXECUTION,
} from '../../GraphQL/TestExecutions/TRFX/Mutations';
import { isATC, isAuthRol, isSameData } from '../../Utils/helpers';
import { AppContext } from '../UI/contexts';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

function TestExecutions({
  data,
  isScheduled,
  refetch,
  perPage,
  itemCount,
  deleteTests,
  cancelExecution,
  getTestResultsUrl,
  editRoute,
  type,
  rerunTestExecutionMutation,
  getRerunTestExecutionVariables,
  filterVariables,
}) {
  const navigate = useNavigate();
  //Get auth context
  const { user } = useContext(AppContext);
  const role = isAuthRol(user.roles || []);
  const isCheckVisible = useRef(
    isScheduled && (role === 'admin' || role === 'developer')
  );

  const [testExecutions, setTestExecutions] = useState([]);
  const [showFavorites, setShowFavorites] = useState(false);
  const [showScheduled, setShowScheduled] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedToCancelIds, setSelectedToCancel] = useState([]);

  //Modal states
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSingleDeleteModal, setShowSingleDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const selectedToRerunTest = useRef();
  const [runEditedMutation] = useMutation(RUN_EDITED_TESTS_EXECUTION);

  const [removeFavorite] = useMutation(REMOVE_FAVORITE);
  const [addFavorite] = useMutation(ADD_FAVORITE);

  const [selectedToSchedule, setSelectedtoSchedule] = useState([]);
  const [stopResumeScheduledExecution] = useMutation(
    STOP_RESUME_SCHEDULED_EXECUTION
  );

  const [rerunTestExecution] = useMutation(rerunTestExecutionMutation, {
    onCompleted: () => {
      // Reset state
      window.hideMessage();
      window.flash('New execution was successfully created.', 'success');
    },
    onError: (error) => {
      window.hideMessage();
      window.flash(
        `The action could not be completed. Please review carefully the information provided or try later.`,
        'error'
      );
    },
  });

  useEffect(() => {
    if (!data) return;

    if (!showFavorites && !showScheduled) {
      updateTestExecutions(data);
      return;
    }
    let filteredTestExecutions = [...data];
    if (showFavorites) {
      filteredTestExecutions = filteredTestExecutions.filter((test) => {
        return test.isFavourite && test.userId === user._id;
      });
    }
    if (showScheduled) {
      filteredTestExecutions = filteredTestExecutions.filter(
        (test) => test.isScheduled
      );
    }
    updateTestExecutions(filteredTestExecutions);
  }, [showFavorites, data, showScheduled]); // eslint-disable-line

  const onCancelTestExecution = () => {
    cancelExecution({
      variables: { ids: selectedToCancelIds },
    })
      .then(() => {
        window.showMessage('Loading changes', 'loading');
        refetch().then(() => {
          window.hideMessage();
          window.flash('Successfully canceled test execution!', 'success');
        });
      })
      .catch((error) => {
        window.flash(
          'An error occurred. Test Execution could not be canceled',
          'error'
        );
      });
    setShowCancelModal(false);
  };

  const handleMultiSelectFilter = (args, isSelect) => {
    if (isSelect) {
      args.itemData === 'Favorites' && setShowFavorites(true);
      args.itemData === 'Scheduled' && setShowScheduled(true);
    } else if (args.itemData === 'Favorites') {
      setShowFavorites(false);
    } else {
      setShowScheduled(false);
    }
  };

  const deleteSelectedHandler = () => {
    setShowModal(false);

    window.showMessage('Loading changes', 'loading');
    deleteTests({ variables: { idList: selectedIds } })
      .then(() => {
        refetch().then(() => {
          window.flash('Successfully deleted Test Executions!', 'success');
          window.hideMessage();
          setSelectedIds([]);
        });
      })
      .catch((error) => {
        window.flash(
          'An error occurred. Test executions could not be deleted',
          'error'
        );
        window.hideMessage();
      });
  };

  const deleteSingleHandler = () => {
    setShowSingleDeleteModal(false);
    window.showMessage('Loading changes', 'loading');

    deleteTests({ variables: { idList: selectedIds } })
      .then(() => {
        refetch().then(() => {
          window.flash('Successfully deleted Test Execution!', 'success');
          window.hideMessage();
          setSelectedIds([]);
        });
      })
      .catch((error) => {
        window.flash(
          'An error occurred. Test execution could not be deleted',
          'error'
        );
      });
  };

  const rerunAcceptHandler = (newName) => {
    if (newName === '') {
      return;
    }

    rerunTestExecution(
      getRerunTestExecutionVariables(selectedToRerunTest.current.id, newName)
    );
    setShowEditModal(false);
    selectedToRerunTest.current = null;
  };

  const seeTestResultsHandler = (event, testId) => {
    event.stopPropagation();
    const url = getTestResultsUrl(testId);
    window.open(url, '_blank', 'noreferrer');
  };

  const deleteSingleClickHandler = (event, testId) => {
    event?.stopPropagation();
    setSelectedIds([testId]);
    setShowSingleDeleteModal(true);
  };

  const rerunSingleClickHandler = (event, testId, testStatus, testName) => {
    if (testStatus === EDITED) {
      runEditedMutation({
        variables: {
          teId: testId,
        },
      })
        .then((r) => {
          const response = r.data.testsExecutionRunEditedTestExecution;
          if (response === null) {
            window.hideMessage();
            window.flash('Error trying to run test execution', 'error');
          } else {
            window.hideMessage();
            window.flash(`Tests execution successfully started`, 'success');
          }
        })
        .catch((error) => {
          window.hideMessage();
          window.flash('An error occurred', 'error');
        });
      return;
    }
    event.stopPropagation();
    selectedToRerunTest.current = { id: testId, name: testName };
    setShowEditModal(true);
  };

  const scheduleSingleClickHandler = (event, testId) => {
    event?.stopPropagation();
    const url = `/schedule?te=${testId}`;
    navigate(url);
  };

  const addToFavoritesHandler = (event, testId) => {
    event.stopPropagation();
    // window.showMessage('Loading changes', 'loading');
    addFavorite({
      variables: { testId: testId, uid: user._id },
    })
      .then(() => {
        // window.hideMessage();
        // window.flash('Successfully added to favorites!', 'success');
      })
      .catch((error) => {
        // window.hideMessage();
        window.flash(
          'Failed to add test execution to favorites. Please try again later',
          'error'
        );
      });
  };

  const removeFromFavoritesHandler = (event, testId) => {
    event.stopPropagation();
    // window.showMessage('Loading changes', 'loading');
    removeFavorite({
      variables: { testId: testId, uid: user._id },
    })
      .then(() => {
        // window.hideMessage();
        // window.flash('Successfully removed from favorites!', 'success');
      })
      .catch((error) => {
        // window.hideMessage();
        window.flash(
          'Failed to remove test execution from favorites. Please try again later',
          'error'
        );
      });
  };

  const cancelExecutionHandler = (testId) => {
    setSelectedToCancel([testId]);
    setShowCancelModal(true);
  };

  const updateTestExecutions = (filteredTestExecutions) => {
    const temp = filteredTestExecutions.map((test) => {
      const startDate =
        test.start && test.status !== EDITED
          ? new Date(test.start).toLocaleString('en-US', {
              hour12: false,
            })
          : '----';
      const seconds = test.duration;
      const formattedDuration = [RUNNING, PENDING, EDITED].includes(test.status)
        ? '--:--:--'
        : getFormattedDuration(Math.abs(seconds));

      const name = test.name ? test.name : 'No name.';
      const email = test.login ? test.login : 'No user.';

      const failed_total =
        test.status !== EDITED ? `${test.failed}/${test.total}` : '----';
      const { status } = test;
      const passed = test.passed === test.total;

      const userOwnsTest = test.userId === user._id;

      return {
        id: test._id,
        env: ENVIRONMENTS_DICTIONARY[test.env],
        date: startDate,
        name,
        email,
        status,
        passed,
        failed_total,
        executionStatus: test.executionStatus,
        duration: formattedDuration,
        region: test.region && REGIONS_DICTIONARY[test.region],
        description: test.description,
        userOwnsTest,
        isFavourite: test.isFavourite,
        isScheduled: test.isScheduled,
      };
    });
    if (isSameData(temp, testExecutions)) return;

    setTestExecutions(temp);
  };

  const onStopResumeScheduledExecution = (pausing) => {
    stopResumeScheduledExecution({
      variables: { listId: selectedToSchedule, pausing: pausing },
    })
      .then((res) => {
        window.flash(
          'Scheduled executions successfully ' +
            (pausing ? 'paused' : 'resumed'),
          'success'
        );
      })
      .catch((error) => {
        window.flash('An error occured. Please try again later', 'error');
      });
  };
  const testExecutionsTable = useMemo(() => {
    return (
      <TestExecutionsTable
        data={testExecutions}
        isScheduled={isCheckVisible.current}
        perPage={perPage}
        itemCount={itemCount}
        handleMultiSelectFilter={handleMultiSelectFilter}
        isATCTable={isATC(type)}
        seeTestResultsHandler={seeTestResultsHandler}
        deleteSingleClickHandler={deleteSingleClickHandler}
        rerunSingleClickHandler={rerunSingleClickHandler}
        scheduleSingleClickHandler={scheduleSingleClickHandler}
        addToFavoritesHandler={addToFavoritesHandler}
        removeFromFavoritesHandler={removeFromFavoritesHandler}
        cancelExecutionHandler={cancelExecutionHandler}
        editRoute={editRoute}
        refetch={refetch}
        filterVariables={filterVariables}
        setSelectedToSchedule={setSelectedtoSchedule}
      />
    );
  }, [testExecutions]);

  return data ? (
    <div className='test-execution-container'>
      {isCheckVisible.current && (
        <div className='schedule-buttons-container'>
          <ButtonComponent
            className='e-control e-btn e-lib e-custom mb-2 mr-2'
            onClick={() => onStopResumeScheduledExecution(false)}
          >
            Resume Selected Executions ({selectedToSchedule.length})
          </ButtonComponent>
          <ButtonComponent
            className='e-control e-btn e-lib e-custom mb-2'
            onClick={() => onStopResumeScheduledExecution(true)}
          >
            Pause Selected Executions ({selectedToSchedule.length})
          </ButtonComponent>
        </div>
      )}
      {testExecutionsTable}
      <div id='test-executions-modals'>
        <EditNameModal
          show={showEditModal}
          initialValue={selectedToRerunTest.current?.name}
          title='Set the name for the new execution'
          onCancel={() => setShowEditModal(false)}
          onAccept={(newName) => rerunAcceptHandler(newName)}
        />
        <CustomModal
          show={showSingleDeleteModal}
          title='Are you sure you want to delete this Test execution?'
          onClose={() => setShowSingleDeleteModal(false)}
          onAccept={() => deleteSingleHandler()}
        />
        <CustomModal
          show={showCancelModal}
          title='Are you sure you want to cancel this execution?'
          onClose={() => setShowCancelModal(false)}
          onAccept={() => onCancelTestExecution()}
        />
        <CustomModal
          show={showModal}
          title='Are you sure you want to delete these Test executions?'
          onClose={() => setShowModal(false)}
          onAccept={() => deleteSelectedHandler()}
        />
      </div>
    </div>
  ) : (
    <CustomSpinner />
  );
}

export default TestExecutions;
