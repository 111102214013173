import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DropDownListComponent, Inject } from '@syncfusion/ej2-react-dropdowns';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  GridComponent,
} from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
  GET_BASE_SCOPES_PARAMETERS,
  GET_COMPONENTS,
  GET_CUSTOM_SCOPES,
  GET_CUSTOM_SCOPES_PARAMETERS,
} from '../../GraphQL/ManageCustomScopes/Queries';
import './ViewCustomScopes.css';
import {
  CREATE_CUSTOM_SCOPE,
  DELETE_CUSTOM_SCOPE,
  EDIT_CUSTOM_SCOPE,
} from '../../GraphQL/ManageCustomScopes/Mutations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import GraphQLWrapper from '../UI/GraphQLWrapper';
import CustomModal from '../UI/Modal/Modal';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import BaseScopeParametersGrid from './BaseScopeParametersGrid';

function ViewCustomScopes() {
  const [components, setComponents] = useState([]);
  const [parameters, setParameters] = useState({});
  const [customScopes, setCustomScopes] = useState([]);
  const [customScopesParameters, setCustomScopesParameters] = useState([]);
  const [scopeDefinition, setScopeDefinition] = useState(
    JSON.stringify({ include: {} }),
    null,
    2
  );
  const [newScopeName, setNewScopeName] = useState('');

  const [selectedComponent, setSelectedComponent] = useState();
  const [selectedComponentId, setSelectedComponentId] = useState();

  const [isEditMode, setEditMode] = useState(false);
  const [isCreateMode, setCreateMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRedirectModal, setshowRedirectModal] = useState(false);

  const selectedScope = useRef();
  const customScopeId = useRef();

  const { loading: loadingComponents } = useQuery(GET_COMPONENTS, {
    onCompleted: (data) => {
      const implementedComponents = data.implementedWCFindMany.map((c) => {
        return { id: c._id, value: c.name, text: c.name };
      });
      setComponents(implementedComponents);
    },
  });

  const [getCustomScopes, { refetch: refetchCustomScopes }] = useLazyQuery(
    GET_CUSTOM_SCOPES,
    {
      pollInterval: 15000,
      onCompleted: (data) => {
        const customScopes = data.testDefinitionFindMany
          .filter((c) => c.name && c.name !== '')
          .map((c) => {
            return { text: c.name, value: c._id };
          });
        setCustomScopes(customScopes);
      },
    }
  );

  const [getParameters, { refetch: refetchBaseParams }] = useLazyQuery(
    GET_BASE_SCOPES_PARAMETERS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data?.testDefinitionFindOne) {
          setParameters(data.testDefinitionFindOne.parameters);
        } else {
          setParameters([]);
        }
      },
    }
  );

  const [getCustomScopesParameters, { refetch }] = useLazyQuery(
    GET_CUSTOM_SCOPES_PARAMETERS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.testDefinitionFindOne) {
          setCustomScopesParameters(data.testDefinitionFindOne.parameters);
          setScopeDefinition(
            JSON.stringify(data.testDefinitionFindOne.scope, null, 2)
          );
          customScopeId.current = data.testDefinitionFindOne._id;
        } else {
          setCustomScopesParameters([]);
          setScopeDefinition(JSON.stringify({}));
        }
      },
      onError: (error) => {},
    }
  );

  const [editCustomScope] = useMutation(EDIT_CUSTOM_SCOPE);
  const [createCustomScope] = useMutation(CREATE_CUSTOM_SCOPE);
  const [deleteCustomScope] = useMutation(DELETE_CUSTOM_SCOPE);

  useEffect(() => {
    if (!components.length) return;

    setSelectedComponent(components[0].value);
    setSelectedComponentId(components[0].id);
  }, [components]);

  useEffect(() => {
    if (!customScopes.length || !selectedScope.current) return;

    selectedScope.current.value = customScopes[0].text;
    customScopeId.current = customScopes[0].value;

    getCustomScopesParameters({
      variables: {
        component: selectedComponent,
        customScope: customScopeId.current,
      },
    });
  }, [customScopes]);

  // Refetch custom scopes when selected component changes
  useEffect(() => {
    if (!selectedComponent) return;

    getCustomScopes({ variables: { component: selectedComponent } });
    getParameters({
      variables: {
        component: selectedComponent,
        customScope: customScopeId.current,
      },
    });
  }, [selectedComponent, getCustomScopes, getParameters]);

  useEffect(() => {
    if (!selectedScope.current) return;
    selectedScope.current.value =
      customScopes.length > 0 ? customScopes[0].text : '';
  }, [isCreateMode]);

  // Refetch custom scopes parameters when selected custom scope changes
  useEffect(() => {
    if (!selectedComponent || !selectedScope.current.value) return;
    customScopeId.current = customScopes.find(
      (cs) => cs.text === selectedScope.current.value
    ).value;
    getCustomScopesParameters({
      variables: {
        component: selectedComponent,
        customScope: customScopeId.current,
      },
    });
  }, [selectedScope, getCustomScopesParameters]);

  const changeComponentsHandler = (event) => {
    setSelectedComponent(event.value);
    setSelectedComponentId(event.target.itemData.id);
    getCustomScopes({ variables: { component: event.value } });
    getParameters({
      variables: {
        component: event.value,
      },
    });
  };
  const changeScopesHandler = (event) => {
    getCustomScopesParameters({
      variables: {
        component: selectedComponent,
        customScope: event.target.itemData.value,
      },
    });
  };

  const selectHandler = (row) => {
    if (row.data.length) {
      const customScopes = [...customScopesParameters];
      setCustomScopesParameters(
        customScopes.concat(
          row.data.filter(
            (baseScope) =>
              !customScopes.some((param) => param.name === baseScope.name)
          )
        )
      );
    } else if (
      !customScopesParameters.some((param) => param.name === row.data.name)
    ) {
      setCustomScopesParameters([...customScopesParameters, row.data]);
    }
  };
  const deselectHandler = (row) => {
    if (row.isHeaderCheckboxClicked && row.data.length < parameters.length)
      return;

    if (row.data.length) {
      setCustomScopesParameters((params) =>
        params.filter(
          (p) =>
            !row.data.some((baseParameter) => baseParameter.name === p.name)
        )
      );
    } else
      setCustomScopesParameters((params) =>
        params.filter((p) => p.name !== row.data.name)
      );
  };
  const cellSaved = (args) => {
    const index = customScopesParameters.findIndex(
      (param) =>
        param.name === args.rowData.name && param.value === args.rowData.value
    );
    const newValue = { ...args.rowData, value: args.value };

    const newParams = [...customScopesParameters];
    newParams.splice(index, 1, newValue);
    setCustomScopesParameters(newParams);
  };

  const cancelCreateHandler = () => {
    setParameters([]);
    setCreateMode(false);
    refetch();
    refetchBaseParams();
  };

  const cancelEditHandler = () => {
    setCustomScopesParameters([]);
    setParameters([]);
    refetchBaseParams();
    refetch();
    setEditMode(false);
  };
  const onSubmitEdit = () => {
    window.showMessage('Loading', 'loading');
    const parameters = customScopesParameters.map((param) => {
      return { name: param.name, value: param.value };
    });
    editCustomScope({
      variables: {
        record: {
          operation: 'edit',
          name: selectedScope.current.value,
          parameters,
          scope: scopeDefinition,
          testDefinition: customScopeId.current,
          implementedWC: selectedComponentId,
        },
      },
    })
      .then(() => {
        setshowRedirectModal(true);
        refetch();
        setParameters([]);
        refetchBaseParams();
        selectedScope.current.value =
          customScopes.length > 0 ? customScopes[0].text : '';
        setEditMode(false);
        window.hideMessage();
      })
      .catch((error) => {
        window.hideMessage();
        window.flash(
          `An error occurred. Custom scope could not be updated. ${error.message}`,
          'error'
        );
      });
  };
  const onSubmitCreate = () => {
    window.showMessage('Loading', 'loading');

    const parameters = customScopesParameters.map((param) => {
      return { name: param.name, value: param.value };
    });
    createCustomScope({
      variables: {
        record: {
          operation: 'create',
          parameters,
          scope: scopeDefinition,
          implementedWC: selectedComponentId,
          name: newScopeName,
        },
      },
    })
      .then(() => {
        setshowRedirectModal(true);
        setParameters([]);
        refetchCustomScopes();
        refetch();
        refetchBaseParams();
        setCreateMode(false);
        window.hideMessage();
      })
      .catch((error) => {
        window.hideMessage();
        window.flash(
          `An error occurred. Custom scope could not be created. ${error.message}`,
          'error'
        );
        setNewScopeName('');
      });
  };

  const onSubmitDelete = () => {
    setShowDeleteModal(false);

    window.showMessage('Saving changes', 'loading');

    deleteCustomScope({
      variables: {
        record: {
          operation: 'delete',
          name: selectedScope.current.value,
          testDefinition: customScopeId.current,
          implementedWC: selectedComponentId,
        },
      },
    })
      .then(() => {
        setshowRedirectModal(true);
        refetchCustomScopes();
        refetchBaseParams();
        window.hideMessage();
      })
      .catch((error) => {
        window.hideMessage();
        window.flash(
          `An error occurred. Custom scope could not be deleted. ${error.message}`,
          'error'
        );
      });
  };
  const initCreateModeHandler = () => {
    setCreateMode(true);
    setScopeDefinition(JSON.stringify({ include: {} }, null, 2));
    setCustomScopesParameters([]);
  };

  const view = (
    <div className='d-flex justify-content-center'>
      <div className='e-card' style={{ width: '75%' }}>
        <div className='e-card-content m-2'>
          {!(isEditMode || isCreateMode) && (
            <div className='d-flex row rt-header-row align-items-center'>
              <div className='att-buttons-container col-sm-12'>
                <ButtonComponent
                  onClick={initCreateModeHandler}
                  className='e-control e-btn e-lib e-custom btn-primary ml-2'
                  iconCss='e-icons e-save'
                >
                  Create
                </ButtonComponent>
                <ButtonComponent
                  onClick={() => setEditMode(true)}
                  className='e-control e-btn e-lib e-outline ml-2'
                  iconCss='e-icons e-edit'
                >
                  Edit
                </ButtonComponent>
                <ButtonComponent
                  onClick={() => setShowDeleteModal(true)}
                  className='e-control e-btn e-lib e-outline ml-2'
                  iconCss='e-icons e-trash'
                >
                  Delete
                </ButtonComponent>
              </div>
            </div>
          )}
          <div className='row rt-header-row align-items-center'>
            <div className='d-flex flex-column col-sm-12 col-md-6 control-section'>
              <div className='col-sm-12 control-section'>
                <DropDownListComponent
                  dataSource={components}
                  placeholder='Component'
                  value={selectedComponent}
                  onChange={changeComponentsHandler}
                  fields={{ text: 'text', value: 'value' }}
                  floatLabelType='Auto'
                  enabled={!(isEditMode || isCreateMode)}
                  sortOrder='Ascending'
                />
              </div>
              <div className='mt-3 col-sm-12 control-section'>
                {isCreateMode ? (
                  <TextBoxComponent
                    placeholder='Name'
                    floatLabelType='Auto'
                    value={newScopeName}
                    onChange={(e) => setNewScopeName(e.target.value)}
                  />
                ) : (
                  <DropDownListComponent
                    ref={selectedScope}
                    dataSource={customScopes}
                    placeholder='Scopes'
                    onChange={changeScopesHandler}
                    floatLabelType='Auto'
                    enabled={!isEditMode}
                    sortOrder='Ascending'
                    enablePersistence
                  />
                )}
              </div>
            </div>
            <div className='d-flex flex-column col-sm-12 col-md-6 control-section att-scope-definition'>
              <label htmlFor='scope_definition' style={{ color: '#124069' }}>
                Scope Definition
              </label>
              <Editor
                id='scope_definition'
                value={scopeDefinition}
                onValueChange={(code) => setScopeDefinition(code)}
                highlight={(code) => highlight(code, languages.js)}
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                }}
                disabled={!(isEditMode || isCreateMode)}
                itemType={'json'}
              />
            </div>
          </div>
          <div className='row rt-header-row align-items-center'>
            <div className='mt-5 col-sm-12 col-md-6 control-section d-flex flex-column'>
              <label htmlFor='custom_scopes_parameters'>Parameters</label>
              <GridComponent
                height={200}
                id='custom_scopes_parameters'
                dataSource={customScopesParameters}
                editSettings={{
                  mode: 'Batch',
                  allowEditing: true,
                  allowEditOnDblClick: true,
                  allowAdding: true,
                }}
                cellSaved={cellSaved}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field='name'
                    headerText='Key'
                    allowEditing={false}
                    isPrimaryKey
                  />
                  <ColumnDirective
                    field='value'
                    headerText='Value'
                    allowEditing
                  />
                </ColumnsDirective>
                <Inject services={[Edit]} />
              </GridComponent>
            </div>
            <div className='mt-5 ml-auto col-sm-12 col-md-6 control-section d-flex flex-column'>
              <BaseScopeParametersGrid
                parameters={parameters}
                customScopesParameters={customScopesParameters}
                deselectHandler={deselectHandler}
                isCreateMode={isCreateMode}
                isEditMode={isEditMode}
                selectHandler={selectHandler}
              />
              <CustomModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onAccept={onSubmitDelete}
                title='Are you sure you want to delete this custom scope?'
              />
              <CustomModal
                show={showRedirectModal}
                onClose={() => setshowRedirectModal(false)}
                onAccept={() =>
                  (window.location.href = '/custom-scopes-processing-queue')
                }
                title='The custom scope has been sent to a queue for processing. Do you want to be redirected to the processing view?'
              />
            </div>
          </div>
          {!(isEditMode || isCreateMode) && (
            <div className='d-flex mt-5'>
              <div className='ml-auto'>
                <ButtonComponent
                  onClick={() => (window.location.href = '/trfx-run-tests')}
                  className='e-control e-btn e-lib e-outline mr-1'
                  iconCss='e-icons e-close'
                >
                  Close
                </ButtonComponent>
                <ButtonComponent
                  onClick={() =>
                    (window.location.href = '/custom-scopes-processing-queue')
                  }
                  className='e-control e-btn e-lib e-custom btn-primary'
                  iconCss='e-icons e-settings'
                >
                  Processing queue
                </ButtonComponent>
              </div>
            </div>
          )}
          {isEditMode && (
            <div className='d-flex mt-5'>
              <div className='ml-auto'>
                <ButtonComponent className='mr-2' onClick={cancelEditHandler}>
                  Cancel
                </ButtonComponent>
                <ButtonComponent
                  style={{ backgroundColor: '#124069', color: 'white' }}
                  onClick={onSubmitEdit}
                >
                  Save
                </ButtonComponent>
              </div>
            </div>
          )}
          {isCreateMode && (
            <div className='d-flex mt-5'>
              <div className='ml-auto'>
                <ButtonComponent className='mr-2' onClick={cancelCreateHandler}>
                  Cancel
                </ButtonComponent>
                <ButtonComponent
                  style={{ backgroundColor: '#124069', color: 'white' }}
                  onClick={onSubmitCreate}
                >
                  Save
                </ButtonComponent>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return <GraphQLWrapper loading={loadingComponents} component={view} />;
}
export default ViewCustomScopes;
