import React from 'react';
import { ImTree } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';

export default function ChildExecutions({ id }) {
  const navigate = useNavigate();
  return (
    <ImTree
      color='var(--att-primary)'
      style={{ cursor: 'pointer' }}
      title='see child executions'
      onClick={() => {
        return navigate(`/trfx-child-executions/${id}`);
      }}
    />
  );
}
