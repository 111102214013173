import { BreadcrumbComponent } from '@syncfusion/ej2-react-navigations';
import React, { Fragment, useContext } from 'react';
import Toolbar from './Toolbar/Toolbar';
import { AppContext } from '../UI/contexts';
import PageNotFound from '../UI/PageNotFound/PageNotFound';

function Layout(props) {
  const beforeItemRenderHandler = (args) => {
    if (args.item.text === '/') return (args.item.text = '');
    if (args.item.text === 'trfx-run-tests')
      return (args.item.text = 'Create Execution (TRFX)');
    if (args.item.text === 'trfx-run-tests-edit')
      return (args.item.text = 'Edit Execution (TRFX)');
    if (args.item.text === 'atc-run-tests-edit')
      return (args.item.text = 'Edit Execution (aTC)');
    if (
      args.item.url &&
      args.item.url.includes('trfx-run-tests-edit') &&
      args.item.text
    )
      return (args.item.text = '');
    if (
      args.item.url &&
      args.item.url.includes('atc-run-tests-edit') &&
      args.item.text
    )
      return (args.item.text = '');
    if (args.item.text === 'atc-run-tests')
      return (args.item.text = 'Create Execution (aTC)');
    if (args.item.text === 'add-url')
      return (args.item.text = 'Add Url (TRFX)');
    if (args.item.text === 'edit-url')
      return (args.item.text = 'Edit Url (TRFX)');
    if (args.item.text === 'delete-url')
      return (args.item.text = 'Delete Url (TRFX)');
    if (args.item.text === 'add-feature')
      return (args.item.text = 'Add feature');
    if (args.item.text === 'delete-feature')
      return (args.item.text = 'Delete feature');
    if (args.item.text === 'custom-scopes-processing-queue')
      return (args.item.text = 'Processing queue');

    if (args.item.text === 'view-custom-scopes')
      return (args.item.text = 'View custom scopes');
    if (args.item.text === 'trfx-test-executions')
      return (args.item.text = 'Test Executions (TRFX)');
    if (args.item.text === 'trfx-show-test-results')
      return (args.item.text = 'Test Executions show result (TRFX)');
    if (args.item.text === 'atc-show-test-results')
      return (args.item.text = 'Test Executions show result (aTC)');
    if (args.item.text === 'atc-test-executions')
      return (args.item.text = 'Test Executions (aTC)');
    if (args.item.text === 'associate-component')
      return (args.item.text = 'Associate Customer');
  };
  const { user } = useContext(AppContext);
  return user._id !== 'invalidUser' ? (
    <>
      <Toolbar />

      <div className='layout-content'>
        {window.location.pathname !== '/' && (
          <div className='d-flex justify-content-center'>
            <BreadcrumbComponent
              id='att-breadcrumb'
              enableActiveItemNavigation
              beforeItemRender={(args) => beforeItemRenderHandler(args)}
              className='mb-5'
              style={{ width: 'var(--page-content-width)' }}
            />
          </div>
        )}
        {props.children}
      </div>
    </>
  ) : (
    <PageNotFound />
  );
}
export default Layout;
