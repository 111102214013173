import { ApolloClient, InMemoryCache } from '@apollo/client';

export const SYNCFUSION_LICENSE_KEY =
  process.env.REACT_APP_SYNCFUSION_LICENSE_KEY;
export const ATT_ENV = process.env.REACT_APP_ATT_ENV;
export const IS_LOCAL = process.env.REACT_APP_IS_LOCAL === 'true';

// * AUTH
export const REDIRECT_URI = 'http://localhost:3000';
// export const REDIRECT_URI = 'https://air-test-tool-gui.josejorge.workers.dev';

// get att graphql server url from env variables
export const GRAPHQL_SERVER_URI = process.env.REACT_APP_ATT_GQL_URL;
// export const GRAPHQL_SERVER_URI =
//   'https://hhyyz1nuyg.execute-api.us-east-1.amazonaws.com/graphql';

export const GRAPHQL_CLIENT = new ApolloClient({
  uri: GRAPHQL_SERVER_URI,
  cache: new InMemoryCache(),
});

export const TEST_RESULTS_REQUEST_LIMIT = 30;
export const TEST_EXECUTIONS_PAGE_SIZE = 15;

export const ENVIRONMENTS = [
  { text: 'DEV', value: 'dev' },
  { text: 'PRE PROD', value: 'prepro' },
  { text: 'PROD', value: 'prod' },
];

export const REGIONS_DATASOURCE = [
  { text: 'FR (Frankfurt)', value: 'eu-central-1' },
  { text: 'HK (Hong Kong)', value: 'ap-east-1' },
  { text: 'US East (N. Virginia)', value: 'us-east-1' },
];

export const REGIONS_DATASOURCE_UNDERSCORE = [
  { text: 'FR (Frankfurt)', value: 'eu_central_1' },
  { text: 'HK (Hong Kong)', value: 'ap_east_1' },
  { text: 'US East (N. Virginia)', value: 'us_east_1' },
];

export const REGIONS_VALUES_DICTIONARY = {
  eu_central_1: 'eu-central-1',
  ap_east_1: 'ap-east-1',
  us_east_1: 'us-east-1',
};

export const ENVIRONMENTS_DICTIONARY = {
  dev: 'DEV',
  prepro: 'PRE PROD',
  prod: 'PROD',
};

export const REGIONS_DICTIONARY = {
  eu_central_1: 'FR (Frankfurt)',
  ap_east_1: 'HK (Hong Kong)',
  us_east_1: 'US East (N. Virginia)',
};

export const REGIONS = [
  'FR (Frankfurt)',
  'HK (Hong Kong)',
  'US East (N. Virginia)',
];
// export const REGIONS_DICTIONARY = {
//   'FR (Frankfurt)': 'eu_central_1',
//   'HK (Hong Kong)': 'ap_east_1',
// };
export const TEMPLATES = [
  'home',
  'to-city',
  'from-city',
  'city-to-city',
  'to-country',
  'from-country',
  'country-to-country',
  'country-to-city',
  'city-to-country',
  'custom-page',
];

export const APPLICATIONS = ['TRFX', 'VG'];

export const STATUSES = ['PASSED', 'FAILED', 'NOT_EXECUTED'];
export const URL_STATUS = [
  'FINISHED',
  'CANCELLED',
  'RUNNING',
  'PENDING',
  'ON_HOLD',
  'EDITED',
];

export const PASSED = 'PASSED';
export const FAILED = 'FAILED';
export const NOT_EXECUTED = 'NOT_EXECUTED';
export const CANCELLED = 'CANCELLED';
export const PENDING = 'PENDING';
export const RUNNING = 'RUNNING';
export const FINISHED = 'FINISHED';
export const ON_HOLD = 'ON_HOLD';
export const PROCESSED = 'PROCESSED';
export const TO_PROCESS = 'TO_PROCESS';
export const EDITED = 'EDITED';

export const ATC_FEATURES_APP = [
  'airTRFX',
  'airSEM',
  'Settings',
  'airModules',
  'FareWire',
  'FareRefresher',
];

export const LOCAL_USER = 'defaultuser@att.com';

export const DATA = {
  generalInfo: {
    name: 'Mock test',
    start: '2021-05-17T17:25:11.117Z',
    status: 'FAILED',
  },
  results: [
    {
      url: 'https://aircanada-dev.everymundo.com/en-ca',
      context: {
        airlineCode: 'ac',
        template: 'city-to-city',
        siteEdition: 'en-ca',
      },
      components: [
        {
          name: 'Booking',
          version: '2.16.8',
          moduleId: 'em-cmp-lib-airmodules--bookings',
          it: [
            {
              type: 'testCase',
              name: 'FC should be rendered',
              description: '',
              status: 'PASSED',
              it: [
                {
                  type: 'testCaseStep',
                  name: 'Check if FC container exists',
                  description: '',
                  status: 'PASSED',
                },
              ],
            },
            {
              type: 'testCase',
              name: 'Return field should be disabled after clicking OneWay option',
              description: '',
              status: 'FAILED',
              it: [
                {
                  type: 'testCaseStep',
                  name: 'Click on OneWay option',
                  description: '',
                  status: 'PASSED',
                },
                {
                  type: 'testCaseStep',
                  name: 'Check Return field is disabled',
                  description: '',
                  status: 'FAILED',
                  error: {
                    reason: 'Return field is enabled.',
                  },
                },
              ],
            },
            {
              type: 'testCase',
              name: 'Return field should be disabled after clicking OneWay option',
              description: '',
              status: 'FAILED',
              it: [
                {
                  type: 'testCaseStep',
                  name: 'Click on OneWay option',
                  description: '',
                  status: 'PASSED',
                },
                {
                  type: 'testCaseStep',
                  name: 'Check Return field is disabled',
                  description: '',
                  status: 'FAILED',
                  error: {
                    reason: 'Return field is enabled.',
                  },
                },
                {
                  type: 'testCaseStep',
                  name: 'Test step 3...',
                  description: '',
                  status: 'NOT_EXECUTED',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
