import React from 'react';
import BaseFilter from './BaseFilter';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

export default class InputFilter extends BaseFilter {
  render() {
    const cmp = (
      <div className='e-float-input ' style={{ marginTop: '7px' }}>
        <form
          className='d-flex'
          noValidate
          onSubmit={function (event) {
            event.preventDefault();
            this.onChange(this);
          }.bind(this)}
        >
          <input
            type='text'
            className={this.className}
            value={this.defaultValues}
            readOnly={this.readonly}
            ref={(f) => {
              this.ref = f;
            }}
            placeholder={this.placeholder}
            onChange={function (event) {
              if (event.target.value === '') {
                this.onChange(this);
              }
            }.bind(this)}
          />
          <ButtonComponent
            onClick={function () {
              this.onChange(this);
            }.bind(this)}
            style={{ backgroundColor: '#124069', color: 'white' }}
            cssClass='e-small'
            className='ml-2'
          >
            Apply
          </ButtonComponent>
        </form>
      </div>
    );
    return this.hasContainer ? (
      <div key={this.id} className={this.containerClassName + ' form-group'}>
        {cmp}
      </div>
    ) : (
      cmp
    );
  }
}
