import React from 'react';
import { registerLicense } from '@syncfusion/ej2-base';

import ReactDOM from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Application from './Components/Layout/Application';
import {
  GRAPHQL_CLIENT,
  REDIRECT_URI,
  SYNCFUSION_LICENSE_KEY,
} from './Constants/constants';
import ErrorFallback from './Components/Error/ErrorFallback';

registerLicense(SYNCFUSION_LICENSE_KEY);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain='dev-443u710b.us.auth0.com'
        clientId='x2cmn1BPD8dRD4Ab0X7EWQVUq3ZSn322'
        redirectUri={`${REDIRECT_URI}/login-redirect`}
      >
        <ApolloProvider client={GRAPHQL_CLIENT}>
          <Application>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={(props) => (
                <Navigate to={'/error'} state={props.error} />
              )}
            >
              <App />
            </ErrorBoundary>
          </Application>
        </ApolloProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
