import React, { Fragment } from 'react';
import './RunTests.css';
import { isEmpty } from '../../Utils/helpers';

function SelectedData({ data, dataName }) {
  return (
    <>
      <div className='control-section urls-section'>
        <span className='e-badge e-badge-dark att-badge'>
          Selected {dataName} (
          <span data-test='nSelectedUrls'>{data[1].length}</span>)
        </span>
      </div>

      {isEmpty(data[1]) ? (
        <div className='no-selected-urls'>No {dataName} selected.</div>
      ) : (
        <ul
          data-test='selectedUrlsList'
          className='selected-urls-list'
          style={{ height: '300px', overflow: 'auto' }}
        >
          {data[1].map((i) => (
            <li key={`${i.url}-${(Math.random() * 1000).toString()}`}>
              <a
                className='selectedUrl'
                href={i.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                {i.selectedLabel}
              </a>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
export default SelectedData;
