import { isFunction } from '../../../Utils/helpers';

export default class FilterDataHandler {
  constructor({
    getDSFromExternalDS,
    getPredicate,
    field,
    filterFn,
    originalDS,
    currentDS,
    project,
    sort,
    customFilterDS,
  }) {
    this.field = field;

    if (isFunction(getDSFromExternalDS)) {
      this.getDSFromExternalDS = getDSFromExternalDS.bind(this);
    }

    if (isFunction(getPredicate)) {
      this.getPredicate = getPredicate.bind(this);
    }

    if (isFunction(filterFn)) {
      this.filterFn = filterFn.bind(this);
    }

    if (isFunction(project)) {
      this.project = project.bind(this);
    }

    if (isFunction(sort)) {
      this.sort = sort.bind(this);
    }

    if (isFunction(customFilterDS)) {
      this.customFilterDS = customFilterDS.bind(this);
    }

    this.updateDataSource(currentDS);
  }

  updateDataSource(ds, extraData) {
    if (isFunction(this.getDSFromExternalDS)) {
      const internalDS = this.getDSFromExternalDS(ds, extraData);
      this.dataSource = internalDS.map((v) => ({
        value: v,
        text: v,
      }));
    }

    // this.dataSource = internalDS
  }

  filterDS(ds, values) {
    throw new Error('Not implemented method');
  }
}
