import React, { useState } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

function EditNameModal({ show, initialValue, onHide, onAccept, onCancel }) {
  // const inputRef = useRef();
  // useEffect(() => {
  //   inputRef.current && inputRef.current.focus();
  // });
  const [value, setValue] = useState('');
  const buttons = (isSubmitting, submitForm) => [
    {
      click: () => onCancel(),
      buttonModel: {
        content: 'Cancel',
        iconCss: 'e-icons e-close',
        cssClass: 'e-control e-btn e-lib e-outline mr-1',
      },
    },
    {
      click: () => {
        onAccept(value);
      },
      buttonModel: {
        content: 'Accept',
        iconCss: 'e-icons e-check',
        cssClass: 'e-control e-btn e-lib e-custom mr-4',
      },
    },
  ];

  return (
    <DialogComponent
      isModal
      visible={show}
      close={onHide}
      overlayClick={onHide}
      width='500px'
      position={{ X: 'center', Y: 'center' }}
      buttons={buttons(true, true)}
    >
      <h4 className='d-flex justify-content-center'>
        {/*<input*/}
        {/*  className='e-control e-textbox e-lib e-input mr-2'*/}
        {/*  value={initialValue}*/}
        {/*  placeholder='Insert new name'*/}
        {/*  ref={inputRef}*/}
        {/*/>*/}
        <div className='col w-full my-2 pb-2 container-name'>
          <TextBoxComponent
            className='new-test-name'
            name='new-name'
            type='text'
            placeholder='Insert new name'
            input={(e) => setValue(e.value)}
            value={initialValue}
          />
        </div>
      </h4>
    </DialogComponent>
  );
}
export default EditNameModal;
