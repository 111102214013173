import React, { useRef } from 'react';
import './MenuToggleButton.css';

export function MenuToggleButton({ onClick }) {
  const toggleBtn = useRef();
  return (
    <span
      id='sidebar-toggle'
      ref={toggleBtn}
      className='sidebar-toggle e-icons e-menu'
      onClick={onClick}
    />
  );
}
