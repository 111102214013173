import React, { Fragment, useState } from 'react';
import './Error.css';
import { useLocation } from 'react-router-dom';

export default function Error() {
  const [showError, setShowError] = useState(false);
  const location = useLocation();
  return (
    <>
      <div className='moon' />
      <div className='moon__crater moon__crater1' />
      <div className='moon__crater moon__crater2' />
      <div className='moon__crater moon__crater3' />

      <div className='error'>
        <div className='error__title'>Oops!</div>
        <div className='error__subtitle'>Something went wrong</div>
        <div>Let&apos;s try again!</div>
        <br />
        <br />

        <div>Here are some helpful links:</div>
        <a href='\'>Home</a>
        <br />

        <button
          className='error__button--active mt-4'
          onClick={() => setShowError(!showError)}
        >
          {showError ? 'Hide Details' : 'Show Details'}
        </button>
        {showError && (
          <div className='mt-4' style={{ width: '40%' }}>
            {location.state?.message}
          </div>
        )}
      </div>

      <div className='astronaut'>
        <div className='astronaut__backpack' />
        <div className='astronaut__body' />
        <div className='astronaut__body__chest' />
        <div className='astronaut__arm-left1' />
        <div className='astronaut__arm-left2' />
        <div className='astronaut__arm-right1' />
        <div className='astronaut__arm-right2' />
        <div className='astronaut__arm-thumb-left' />
        <div className='astronaut__arm-thumb-right' />
        <div className='astronaut__leg-left' />
        <div className='astronaut__leg-right' />
        <div className='astronaut__foot-left' />
        <div className='astronaut__foot-right' />
        <div className='astronaut__wrist-left' />
        <div className='astronaut__wrist-right' />

        <div className='astronaut__cord'>
          <canvas id='cord' height='500px' width='500px' />
        </div>

        <div className='astronaut__head'>
          <canvas id='visor' width='60px' height='60px' />
          <div className='astronaut__head-visor-flare1' />
          <div className='astronaut__head-visor-flare2' />
        </div>
      </div>
    </>
  );
}
