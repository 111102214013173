import React, { useEffect, useRef, useState } from 'react';
import '../FlashMessage/Flash.css';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { createSpinner, showSpinner } from '@syncfusion/ej2-popups';
import Bus from '../../../Utils/Bus';
import LoadingSpinner from '../Spinner/LoadingSpinner';

function Message() {
  const [visibility, setVisibility] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    Bus.addListener('show', ({ message, type }) => {
      setVisibility(true);
      setMessage(message);
      setType(type);
    });
    Bus.addListener('hide', () => {
      setVisibility(false);
    });
    // createSpinner() method is used to create spinner
    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById('container'),
    });
    // showSpinner() will make the spinner visible
    showSpinner(document.getElementById('container'));
  }, []);

  const toastInstance = useRef(null);

  function toastCreated() {
    toastInstance.current.show();
  }
  const typeClass = {
    success: 'e-toast-success',
    error: 'e-toast-error',
    info: 'e-toast-info',
    loading: '',
  };

  const toast = (
    <div className='d-flex justify-content-center'>
      <ToastComponent
        className={`flash-${type}`}
        ref={toastInstance}
        created={toastCreated}
        timeOut={0}
        position={{ X: 'Center', Y: 'Top' }}
        beforeClose={() => setVisibility(false)}
        cssClass={typeClass[type]}
      >
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ columnGap: '10px' }}
        >
          {type === 'loading' && <LoadingSpinner />}
          <div style={{ alignItems: 'end' }}>{message}</div>
        </div>
      </ToastComponent>
    </div>
  );
  return visibility && toast;
}
export default Message;
