import React from 'react';
import './PassedAndFailed.css';

function PassedAndFailed({ title, passed, failed }) {
  const toShow = passed > 0 || failed > 0;
  return (
    toShow && (
      <div className='status-badges-container'>
        {title && <span>{title}:</span>}
        <span className='passed-badge e-badge e-badge-circle'>{passed}</span>
        <span className='failed-badge e-badge e-badge-circle'>{failed}</span>
      </div>
    )
  );
}

export default PassedAndFailed;
