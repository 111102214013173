import React from 'react';
import { AiOutlineClockCircle, AiFillClockCircle } from 'react-icons/ai';
import {
  FINISHED,
  PASSED,
  FAILED,
  CANCELLED,
} from '../../../Constants/constants';
import '../../TestExecutions/TestExecutions.css';

export default function Schedule({
  testId,
  testStatus,
  isATCTable,
  isScheduled,
  role,
  scheduleSingleClickHandler,
}) {
  const enable =
    [FINISHED, PASSED, FAILED, CANCELLED].includes(testStatus) &&
    !isATCTable &&
    role === 'admin';

  return enable ? (
    isScheduled ? (
      <AiFillClockCircle
        title='schedule test execution'
        className='actions-icon'
        onClick={(event) => {
          scheduleSingleClickHandler(event, testId);
        }}
      />
    ) : (
      <AiOutlineClockCircle
        title='schedule test execution'
        className='actions-icon'
        onClick={(event) => {
          scheduleSingleClickHandler(event, testId);
        }}
      />
    )
  ) : isScheduled ? (
    <AiFillClockCircle className='actions-icon-disabled' />
  ) : (
    <AiOutlineClockCircle className='actions-icon-disabled' />
  );
}
