import React from 'react';
import { FiClock } from 'react-icons/fi';

function ElapsedTime({ seconds, label }) {
  function pad(val) {
    return val > 9 ? val : `0${val}`;
  }
  seconds = seconds === -1 ? 0 : seconds;
  const time =
    seconds !== null
      ? `${pad(Math.floor(seconds / 3600))}:${pad(
          Math.floor((seconds / 60) % 60)
        )}:${pad(Math.floor(seconds % 60))}`
      : '--:--:--';
  return (
    <div
      className='d-flex justify-content-center'
      style={{
        marginBottom: '15px',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          color: 'black',
          display: 'flex',
        }}
      >
        <FiClock size='18' style={{ verticalAlign: 'top' }} />
        <h6 className='ml-1 mb-0'>
          <b>
            {label} {time}
          </b>
        </h6>
      </div>
    </div>
  );
}
export default ElapsedTime;
