import { gql } from '@apollo/client';

export const TEST_EXECUTION_EXECUTE_TESTS = gql`
  mutation TestExecutionExecuteTests(
    $contexts: [ExecuteTestsContextsInput]
    $name: String
    $description: String!
    $attEnv: String!
    $smokeTest: Boolean!
    $filters: JSON
    $region: String!
    $userId: ID!
    $includeTrackingValidations: Boolean
  ) {
    testsExecutionExecuteTest(
      contexts: $contexts
      name: $name
      description: $description
      userId: $userId
      attEnv: $attEnv
      smokeTest: $smokeTest
      filters: $filters
      region: $region
      includeTrackingValidations: $includeTrackingValidations
    ) {
      duration
      start
      status
      executionStatus
      name
      description
      attEnv
      _id
    }
  }
`;

export const TEST_EXECUTION_RERUN_TESTS = gql`
  mutation TestExecutionRerunTests($name: String, $teId: ID!, $userId: ID!) {
    testsExecutionReRunExecuteTestResolver(
      name: $name
      userId: $userId
      teId: $teId
    ) {
      duration
      start
      status
      executionStatus
      name
      attEnv
      _id
    }
  }
`;
