import React from 'react';
import './HomeCard.css';

function SampleCard(props) {
  return (
    <div className='welcomeCards e-card' style={{ width: '20rem' }}>
      <div className=' e-card-image'>
        <img
          src={props.imgSrc}
          className='img-fluid'
          alt='section ilustration'
          style={{ height: '300px' }}
        />
      </div>
      <div
        className=' home-card-links e-card-content d-flex flex-column'
        style={{ rowGap: '5px' }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default SampleCard;
