import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from '@syncfusion/ej2-react-grids';
import React, { Fragment, useRef } from 'react';

export default function BaseScopeParametersGrid({
  parameters,
  customScopesParameters,
  isEditMode,
  isCreateMode,
  selectHandler,
  deselectHandler,
}) {
  const grid = useRef();
  let cellIndex = [];

  return isEditMode || isCreateMode ? (
    <Fragment>
      <label htmlFor='base_scopes_parameters'>BaseScope Parameters</label>
      <GridComponent
        ref={grid}
        height='200'
        id='base_scopes_parameters'
        dataSource={parameters}
        rowDataBound={(args) => {
          if (
            customScopesParameters.some(
              (param) => param.name === args.data.name
            )
          ) {
            const index =
              parseInt(args.row.getAttribute('aria-rowindex'), 0) - 1;
            if (!cellIndex.includes(index)) {
              cellIndex.push(index);
            }
          }
        }}
        dataBound={() => {
          if (grid.current && cellIndex.length) {
            grid.current.selectRows(cellIndex);
            cellIndex = [];
          }
        }}
        selectionSettings={{
          type: 'Multiple',
          checkboxOnly: true,
        }}
        rowSelected={selectHandler}
        rowDeselected={deselectHandler}
      >
        <ColumnsDirective>
          <ColumnDirective field='name' headerText='Key' isPrimaryKey />
          <ColumnDirective field='value' headerText='Value' />

          <ColumnDirective type='checkbox' width='50' />
        </ColumnsDirective>
      </GridComponent>
    </Fragment>
  ) : (
    <Fragment>
      <label htmlFor='base_scopes_parameters_static'>
        BaseScope Parameters
      </label>
      <GridComponent
        height='200'
        id='base_scopes_parameters_static'
        dataSource={parameters}
      >
        <ColumnsDirective>
          <ColumnDirective field='name' headerText='Key' isPrimaryKey />
          <ColumnDirective field='value' headerText='Value' />
        </ColumnsDirective>
      </GridComponent>
    </Fragment>
  );
}
