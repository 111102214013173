import React from 'react';
import DataCollector from './DataCollector';
import {
  ATT_ENV,
  TEST_EXECUTIONS_PAGE_SIZE,
} from '../../../Constants/constants';
import '../TestExecutions.css';

export default function AllExecutions() {
  return (
    <div>
      <DataCollector
        variables={{
          page: 1,
          perPage: TEST_EXECUTIONS_PAGE_SIZE,
          filter: {
            attEnv: ATT_ENV,
            isScheduled: false,
          },
          sort: '_ID_DESC',
        }}
      />
    </div>
  );
}
