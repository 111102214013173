// import React from "react"
import './Spinner.css';

// const Spinner = () => {
//   return <div className="loader">Loading</div>
// }
// export default Spinner
import { createSpinner, showSpinner } from '@syncfusion/ej2-popups';
import { setSpinner } from '@syncfusion/ej2-react-popups';
import React from 'react';

export default class App extends React.Component {
  componentDidMount(prevProps) {
    // createSpinner() method is used to create spinner
    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById('container'),
    });
    // Specify the type of the Spinner to be displayed

    setSpinner({ type: 'Bootstrap' });
    // showSpinner() will make the spinner visible
    showSpinner(document.getElementById('container'));
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '700px',
        }}
      >
        <div className='control-pane'>
          <div
            id='container'
            className='control-section col-lg-12 spinner-target'
          />
        </div>
      </div>
    );
  }
}
